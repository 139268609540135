import React from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import Event3 from '../meneventdeatils/Event3'
import { useTranslation } from 'react-i18next';


function Main() {
    const { i18n, t } = useTranslation();

    return (
    <>
        <Header/>
        <Bredcom title="Home" subtitle={t('event details')}/>
        <Event3/>
    </>
  )
}

export default Main