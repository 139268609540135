import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';


function Form() {
  const { i18n, t } = useTranslation();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    comment: "",
  });

  const [successMessage, setSuccessMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://api.millatumidi.uz:7070/email",
        formData
      );

      console.log("API Response:", response.data);

      setFormData({
        fullName: "",
        email: "",
        phoneNumber: "",
        comment: "",
      });

      setSuccessMessage("Successfully sent");
      setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
    } catch (error) {
      if (error.response) {
        console.error("API Error:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <section
        id="contact"
        className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix"
        style={{ background: "#e7f0f8" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 order-2">
              <div className="contact-bg">
                <div className="section-title center-align text-center mb-50">
                  <h2>{t('make a contact')}</h2>
                </div>
                <form
                  action="mail.php"
                  method="post"
                  className="contact-form mt-30 text-center"
                  onSubmit={handleSubmit}
                >
                  <div className="success-message-container">
                    {successMessage && (
                      <div className="success-message">{successMessage}</div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="contact-field p-relative c-name mb-30">
                        <input
                          type="text"
                          id="fullName"
                          name="fullName"
                          placeholder={t('First Name')}
                          required
                          value={formData.fullName}
                          onChange={handleChange}
                        />
                        <i className="icon fal fa-user" />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="contact-field p-relative c-subject mb-30">
                        <input
                          type="email" 
                          id="email"
                          name="email"
                          placeholder="Email"
                          required
                          value={formData.email}
                          onChange={handleChange}
                        />
                        <i className="icon fal fa-envelope" />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="contact-field p-relative c-subject mb-30">
                        <input
                          type="text"
                          id="phoneNumber"
                          name="phoneNumber"
                          placeholder={t('Phone No.')}
                          required
                          value={formData.phoneNumber}
                          onChange={handleChange}
                        />
                        <i className="icon fal fa-phone" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="contact-field p-relative c-message mb-30">
                        <textarea
                          name="comment"
                          id="comment"
                          cols={30}
                          rows={5}
                          placeholder={t('Write comments')}
                          value={formData.comment}
                          onChange={handleChange}
                        />
                        <i className="icon fal fa-edit" />
                      </div>
                      <div className="slider-btn text-center">
                        <button
                          className="btn ss-btn"
                          data-animation="fadeInRight"
                          data-delay=".8s"
                          type="submit"
                        >
                          {t('make a request')} <i className="fal fa-long-arrow-right" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Form;
