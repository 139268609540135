import React from 'react'
/*import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'*/
import First from '../mensubject/First'
function Main() {
    return (
        <>
 {/*           <Header/>
            <Bredcom title="Home" subtitle="Subject details"/>*/}
            <First/>
        </>
    )
}

export default Main