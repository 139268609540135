import React from "react";
import Header from "../../headerone/Main";
import Bredcom from "../../Bredcom/Main";
import First from "../menscholarship/First";
import { useTranslation } from 'react-i18next';

function Main() {
    const { i18n, t } = useTranslation();
    return (
    <>
      <Header />
      <Bredcom title="Home" subtitle={t('scholarship')} />
      <First />
    </>
  );
}

export default Main;
