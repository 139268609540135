import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';



function Business_national() {
    const { i18n, t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const [courseData, setCourseData] = useState(null);

    useEffect(() => {
        fetch('https://api.millatumidi.uz:7070/courseinfo')
            .then((response) => response.json())
            .then((data) => {
                if (data.data && data.data.length > 0) {
                    setCourseData(data.data[0]);
                }
            })
            .catch((error) => {
                console.error('Error fetching course information:', error);
            });
    }, [])

    return (
        <>
            <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                <h3>{t('Business National')}</h3>
                                <div className="upper-box">
                                    <div className="embed-responsive custom-video-ratio">
                                        <iframe
                                            src="https://www.youtube.com/embed/tZ-KDxC62Lg?si=gG8Ks6_70FN1eHHO"
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                                {/*<div className="inner-column">*/}
                                {/*    <div className="course-meta2 review style2 clearfix mb-30">*/}
                                {/*        <ul className="left">*/}
                                {/*            <li>*/}
                                {/*                <div className="author">*/}
                                {/*                    <div className="thumb">*/}
                                {/*                        /!*<img className="team-img" src="assets/img/bg/testi_avatar.png" alt="image" />*!/*/}
                                {/*                    </div>*/}
                                {/*                    <div className="text">*/}
                                {/*                        <Link to="/team-single">Yuldashev Аbduxakim</Link>*/}
                                {/*                        <p>Dean, Teacher</p>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </li>*/}
                                {/*            <li className="categories">*/}
                                {/*                <div className="author">*/}
                                {/*                    <div className="text">*/}
                                {/*                        <a href="#" className="course-name">*/}
                                {/*                            Faculty*/}
                                {/*                        </a>*/}
                                {/*                        <p>Business Administration National</p>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </li>*/}
                                {/*            <li>*/}
                                {/*                <div className="author">*/}
                                {/*                    <div className="text">*/}
                                {/*                        <p>*/}
                                {/*                            <a href="mailto:m.prirnazarov@cambridge.uz">Email</a>*/}
                                {/*                        </p>*/}
                                {/*                        <p>a.yuldashev@cambridge.uz</p>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </li>*/}
                                {/*            <li>*/}
                                {/*                <div className="author">*/}
                                {/*                    <div className="text">*/}
                                {/*                        <a href="#">Room</a>*/}
                                {/*                        <p>Branch A, Second floor</p>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </li>*/}
                                {/*        </ul>*/}
                                {/*    </div>*/}
                                <h3>{t('Course Overview')}</h3>
                                <p>
                                    {t('Business National text')}
                                </p>
                                <p>
                                    {t('Business National text 2')}
                                </p>
                                <h4>Business Management (National Curriculum) TEACHING UNDERGRADUATE CURRICULUM</h4>
                                <table className="table table-bordered mb-40">
                                    <thead>
                                    <tr>
                                        <th className="text-center" colSpan="12">SEMESTER I</th>
                                    </tr>
                                    </thead>
                                    <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Course Name</th>
                                        <th>ECTS</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>APPMATHI6</td>
                                        <td><Link to={{
                                            pathname: '/subject/APPMATHI6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Amaliy matematika 1
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>ECOTHE6</td>
                                        <td><Link to={{
                                            pathname: '/subject/ECOTHE6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Iqtisodiyot nazariyasi
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>BUSENG6</td>
                                        <td><Link to={{
                                            pathname: '/subject/BUSENG6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Business English
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>MRHUZ4</td>
                                        <td><Link to={{
                                            pathname: '/subject/MRHUZ4',
                                            state: {courseInfo: courseData}
                                        }}>
                                            O'zbekistonning eng yangi tarixi
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>INTBUS6</td>
                                        <td><Link to={{
                                            pathname: '/subject/INTBUS6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Biznesga kirish
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right" colSpan="2">Total</td>
                                        <td>30</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered mb-40">
                                    <thead>
                                    <tr>
                                        <th className="text-center" colSpan="12">SEMESTER II</th>
                                    </tr>
                                    </thead>
                                    <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Course Name</th>
                                        <th>ECTS</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>ICTECO6</td>
                                        <td><Link to={{
                                            pathname: '/subject/ICTECO6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Iqtisodiyotda axborot kommunikatsion texnologiyalar va tizimlar
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>APPMAT26</td>
                                        <td><Link to={{
                                            pathname: '/subject/APPMAT26',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Amaliy matematike 2
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>BUSENG6</td>
                                        <td><Link to={{
                                            pathname: '/subject/BUSENG6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Business English
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>PHILOSO3</td>
                                        <td><Link to={{
                                            pathname: '/subject/PHILOSO3 ',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Falsafa
                                        </Link></td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <td>RELST3</td>
                                        <td><Link to={{
                                            pathname: '/subject/RELST3 ',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Dinshunoslik
                                        </Link></td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <td>CRETHI6</td>
                                        <td><Link to={{
                                            pathname: '/subject/CRETHI6 ',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Kreativ fikrlash
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right" colSpan="2">Total</td>
                                        <td>30</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered mb-40">
                                    <thead>
                                    <tr>
                                        <th className="text-center" colSpan="12">SEMESTER III</th>
                                    </tr>
                                    </thead>
                                    <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Course Name</th>
                                        <th>ECTS</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>ENTINN6</td>
                                        <td><Link to={{
                                            pathname: '/subject/ENTINN6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Tadbirkorlik va innovatsiyalar
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>STATIS6</td>
                                        <td><Link to={{
                                            pathname: '/subject/STATIS6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Statistika
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>MICROE6</td>
                                        <td><Link to={{
                                            pathname: '/subject/MICROE6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Mikroiqtisodiyot
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>FUNBM6</td>
                                        <td><Link to={{
                                            pathname: '/subject/FUNBM6 ',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Kompaniyani tashkil etish va boshqarish
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td><Link to={{
                                            state: {courseInfo: courseData}
                                        }}>
                                            Elective Course
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right" colSpan="2">Total</td>
                                        <td>30</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered mb-40">
                                    <thead>
                                    <tr>
                                        <th className="text-center" colSpan="12">SEMESTER IV</th>
                                    </tr>
                                    </thead>
                                    <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Course Name</th>
                                        <th>ECTS</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>FINMAN6</td>
                                        <td><Link to={{
                                            pathname: '/subject/FINMAN6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Moliyaviy menejment
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>MACROE6</td>
                                        <td><Link to={{
                                            pathname: '/subject/MACROE6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Makroiqtisodiyot
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>MARINF6</td>
                                        <td><Link to={{
                                            pathname: '/subject/MARINF6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Bozor infratuzilmasi
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>SUPCHM6</td>
                                        <td><Link to={{
                                            pathname: '/subject/SUPCHM6 ',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Ta'minot zanjirini boshqarish
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td><Link to={{
                                            state: {courseInfo: courseData}
                                        }}>
                                            Elective Course
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right" colSpan="2">Total</td>
                                        <td>30</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered mb-40">
                                    <thead>
                                    <tr>
                                        <th className="text-center" colSpan="12">SEMESTER V</th>
                                    </tr>
                                    </thead>
                                    <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Course Name</th>
                                        <th>ECTS</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>MARKET6</td>
                                        <td><Link to={{
                                            pathname: '/subject/MARKET6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Marketing
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>DIGBUS6</td>
                                        <td><Link to={{
                                            pathname: '/subject/DIGBUS6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Raqamli biznes
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>ECOCEN6</td>
                                        <td><Link to={{
                                            pathname: '/subject/ECOCEN6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Tijorat korxonalari iqtisodiyoti
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>LEADER6</td>
                                        <td><Link to={{
                                            pathname: '/subject/LEADER6 ',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Liderlik
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>PROMGS6</td>
                                        <td><Link to={{
                                            pathname: '/subject/PROMGS6 ',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Xaridlarni boshqarish va global manbalar
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right" colSpan="2">Total</td>
                                        <td>30</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered mb-40">
                                    <thead>
                                    <tr>
                                        <th className="text-center" colSpan="12">SEMESTER VI</th>
                                    </tr>
                                    </thead>
                                    <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Course Name</th>
                                        <th>ECTS</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>INTERN6</td>
                                        <td><Link to={{
                                            pathname: '/subject/INTERN6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Malakaviy amaliyot
                                        </Link></td>
                                        <td>12</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td><Link to={{
                                            state: {courseInfo: courseData}
                                        }}>
                                            Elective Course
                                        </Link></td>
                                        <td>18</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right" colSpan="2">Total</td>
                                        <td>30</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered mb-40">
                                    <thead>
                                    <tr>
                                        <th className="text-center" colSpan="12">SEMESTER VII</th>
                                    </tr>
                                    </thead>
                                    <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Course Name</th>
                                        <th>ECTS</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>BUSVA6</td>
                                        <td><Link to={{
                                            pathname: '/subject/BUSVA6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Biznesni baholash
                                        </Link>
                                        </td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td><Link to={{
                                            state: {courseInfo: courseData}
                                        }}>
                                            Elective Course
                                        </Link></td>
                                        <td>24</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right" colSpan="2">Total</td>
                                        <td>30</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered mb-40">
                                    <thead>
                                    <tr>
                                        <th className="text-center" colSpan="12">SEMESTER VIII</th>
                                    </tr>
                                    </thead>
                                    <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Course Name</th>
                                        <th>ECTS</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>RESMS6</td>
                                        <td><Link to={{
                                            pathname: '/subject/RESMS6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Tadqiqot usullari va ko'nikmalari
                                        </Link></td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>INTERN6</td>
                                        <td><Link to={{
                                            pathname: '/subject/INTERN6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Malakaviy amaliyot
                                        </Link></td>
                                        <td>12</td>
                                    </tr>
                                    <tr>
                                        <td>FINWOR6</td>
                                        <td><Link to={{
                                            pathname: '/subject/FINWOR6',
                                            state: {courseInfo: courseData}
                                        }}>
                                            Bitiruv malakaviy ishi
                                        </Link></td>
                                        <td>12</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right" colSpan="2">Total</td>
                                        <td>30</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-3">
                                <aside className="sidebar-widget info-column">
                                    <div className="inner-column3">
                                        <h3>{t('Course Features')}</h3>
                                        <ul className="project-info clearfix">
                                            <li>
                                                <span className="icon fal fa-home-lg-alt"/>{" "}
                                                <strong>{t('Campus')}:</strong> <span>Branch B</span>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <span className="icon fal fa-book"/>{" "}*/}
                                            {/*    <strong>{t('Lectures')}:</strong> <span>36 </span>*/}
                                            {/*</li>*/}
                                            <li>
                                                <span className="icon fal fa-clock"/>{" "}
                                                <strong>{t('Duration')}: </strong> <span>4 years</span>
                                            </li>
                                            <li>
                                                <div className="tuition-fee">
                                                    <span className="icon fal fa-money-bill"/>{" "}
                                                    <strong>{t('Tuition fee (Per Year)')}:</strong> <br/>
                                                    <div className="tuition-fees">
                                                        <div>
                                                            <p>18,000,000 UZS {t('Full time')}</p>
                                                            <p>17,000,000 UZS {t('Part time')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe"/>{" "}
                                                <strong>{t('language')}: </strong> <span>Uzbek</span>
                                            </li>
                                            <li>
                                                <div className="slider-btn">
                                                    <Link target="_blank" to="https://admission.millatumidi.uz/"
                                                          className="btn ss-btn smoth-scroll">
                                                        {t('apply')} <i className="fal fa-long-arrow-right"/>
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="disclaimer">
                                            <i>*{t('Some discount or scholarship may apply')}</i>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Business_national