import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Import all your components
import Footer from "../component/footer/Main";
import Home from "../component/home/Main";
import About from "../component/About/Main";
import Courses from "../component/Courses/Main";
import Event from "../component/Event/Main";
import Coursesdeatils from "../component/coursedetails/Main";
import Coursesdeatils2 from "../component/coursedetails/Second";
import Coursesdeatils3 from "../component/coursedetails/Business_national";
import Coursesdeatils4 from "../component/coursedetails/Applied-accounting";
import Coursesdeatils5 from "../component/coursedetails/English-language-teaching";
import Coursesdeatils6 from "../component/coursedetails/Master-of-arts-in-english-language-education";
import Event1 from "../component/Pages/eventdeatils/Event1";
import Event2 from "../component/Pages/eventdeatils/Event2";
import Event3 from "../component/Pages/eventdeatils/Event3";
import Event4 from "../component/Pages/eventdeatils/Event4";
import Event5 from "../component/Pages/eventdeatils/Event5";
import Event6 from "../component/Pages/eventdeatils/Event6";
import Event7 from "../component/Pages/eventdeatils/Event7";
import Event8 from "../component/Pages/eventdeatils/Event8";
import Event9 from "../component/Pages/eventdeatils/Event9";
import Event10 from "../component/Pages/eventdeatils/Event10";
import Faq from "../component/Pages/Faq/Main";
import Teacher from "../component/Pages/Teacher/Main";
import Teacher1 from "../component/Pages/Teacher/team-btec";
import Teacher2 from "../component/Pages/Teacher/team-acca";
import Contact from "../component/contact/Main";
import Scholarship from "../component/Pages/scholarship/Main";
import Admission from "../component/Pages/Admission/Main";
import Subject from "../component/Pages/subject/Main";

function Index() {
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const langCode = location.pathname.split('/')[1];
    if (['en', 'ru', 'uz'].includes(langCode)) {
      i18n.changeLanguage(langCode);
      localStorage.setItem('selectedLanguage', langCode);
    }
  }, [location, i18n]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const defaultLang = localStorage.getItem('selectedLanguage') || 'en';

  return (
      <>
        <Routes>
          <Route path="/" element={<Navigate to={`/${defaultLang}`} replace />} />
          <Route path="/:lng" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/event" element={<Event />} />
          <Route path="/BSc-in-Software-Engineering" element={<Coursesdeatils />} />
          <Route path="/Business-Management" element={<Coursesdeatils2 />} />
          <Route path="/Business_national" element={<Coursesdeatils3 />} />
          <Route path="/Applied-accounting" element={<Coursesdeatils4 />} />
          <Route path="/English-language-teaching" element={<Coursesdeatils5 />} />
          <Route path="/Master-of-arts-in-english-language-education" element={<Coursesdeatils6 />} />
          {/*<Route path="/event" element={<Event />} /> */}
          <Route path="/MU-University-is-signing-new-contracts-with-Japanese-companies" element={<Event1 />} />
          <Route path="/MU-University-is-launching-cooperation-with-Dongseo-University" element={<Event2 />} />
          <Route path="/ACCA-hamkorlik-memorandumi" element={<Event3 />} />
          <Route path="/StudentHunter-towards-expanding-partnerships" element={<Event4 />} />
          <Route path="/Cambridge-coding-festival" element={<Event5 />} />
          <Route path="/Millat-Umidi-University-students-visited-Artel" element={<Event6 />} />
          <Route path="/Rasul-Kusherbayev-Yaponiya-elchisi-Hatori-Takashi-bilan-uchrashdi" element={<Event7 />} />
          <Route path="/Uyushma-azolari-safi-kengaymoqda" element={<Event8 />} />
          <Route path="/collaboration-ringo" element={<Event9 />} />
          <Route path="/exciting-collaboration" element={<Event10 />} />
          {/*<Route path="/projects" element={<Gallery />} />*/}
          {/*<Route path="/single-projects" element={<Projectdeatils />} />*/}
          <Route path="/faq" element={<Faq />} />
          <Route path="/scholarship" element={<Scholarship />} />
          <Route path="/Admission" element={<Admission />} />
          <Route path="/subject/:id" element={<Subject />} />
          <Route path="/team" element={<Teacher />} />
          <Route path="/team-btec" element={<Teacher1 />} />
          <Route path="/team-acca" element={<Teacher2 />} />
          {/*<Route path="/team-software" element={<Teacher3 />} />*/}
          {/*<Route path="/team-single" element={<Teacherdeatils />} />*/}
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </>
  );
}

export default Index;