import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';


function University() {
  const { i18n, t } = useTranslation();
  const [yearsOfExperience, setYearsOfExperience] = useState(0);

  useEffect(() => {
    function calculateExperience() {
      const startDate = new Date("2021-05-31");
      const currentDate = new Date();

      const yearsDiff = currentDate.getFullYear() - startDate.getFullYear();

      setYearsOfExperience(yearsDiff);
    }

    calculateExperience();

    const timer = setInterval(calculateExperience, 1000 * 60 * 60 * 24 * 365);
    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <section className="about-area about-p pt-80 pb-70 p-relative fix">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                  className="s-about-img p-relative wow fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay=".4s"
              >
                <img className="about-us__img" src="../../assets/img/about-us-university.jpg" alt="img"/>
                <div className="about-text second-about three-about">
                  <span>
                    {yearsOfExperience} <sub>+</sub>
                  </span>
                  <p>{t('Years Market Presence')}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                  className="about-content s-about-content pl-15 wow fadeInRight  animated"
                  data-animation="fadeInRight"
                  data-delay=".4s"
              >
                <div className="about-title second-title pb-25">
                  <h5>
                    <i className="fal fa-graduation-cap"/> {t('About Our University')}
                  </h5>
                  <h2 className="about-us__title">{t('Tittle')}</h2>
                </div>
                <p className="txt-clr">
                  {t('Sub text')}
                </p>
                <p>
                  {t('Sub text2')}
                </p>
              </div>
            </div>
            <div className="col-md-8 mb-50 position-relative mt-50">
              <div className="embed-responsive embed-responsive-16by9">
                <div className="video-overlay"></div>
                <video controls className="embed-responsive-item">
                  <source src="../../assets/video/founder-video.mp4" type="video/mp4"/>
                </video>
              </div>
            </div>
            <div className="container-poem">
              <div className="poem">
                <p>Ey Dunyo! Boryapman – meni qarshi ol – <br/>Shu buyuk millatning umidiman
                  men!<br/><br/>
                  Jahon beshigini tebratib qo‘ygan, <br/>Ilmu irfon nurin taratib qo‘ygan, <br/>Olamni
                  o‘ziga qaratib qo‘ygan <br/>Shu buyuk millatning umidiman men!<br/><br/>
                  Tomirimda oqar daholar qoni, <br/>Kim bilmas Xorazmiy, Ibn Sinoni! <br/>Tog‘lardan
                  balanddir shavkatu shoni <br/>Shu buyuk millatning umidiman men!<br/><br/>
                  Nomin eshitganda tik turib dunyo, <br/>Beruniy bobomga aytar tasanno, <br/>Ko‘ksimda
                  iftixor, boshimda Humo <br/>Shu buyuk millatning umidiman men!<br/><br/>
                  Ovro‘po eslasa, titraydi hamon, <br/>Temur qilichidan qoldik deb omon, <br/>Yigirma
                  yetti taxt sohibi hoqon – <br/>Shu buyuk millatning umidiman men!<br/><br/>
                  Amerika uchar fazoga sho‘ng‘ib, <br/>Shoh Ulug‘bek yozgan kitobni o‘qib... <br/>Bas,
                  yetar, turmayman bir chetda qo‘rqib, <br/>Shu buyuk millatning umidiman
                  men!<br/><br/>
                  Jadid bobolarim, oydin zotlarim, <br/>Ochilmay yulingan iste’dodlarim, <br/>Bo‘g‘zimni
                  kuydirar ohu dodlarim, <br/>Shu buyuk millatning umidiman men!<br/><br/>
                  O‘tgan kunlar endi tarixdir beshak, <br/>Bu Vatanga yangi daholar kerak, <br/>Meni
                  chorlamoqda nurli kelajak, <br/>Shu buyuk millatning umidiman men!<br/><br/>
                  Menga yordir omad, men bilan iqbol, <br/>Hali yetti iqlim qolar hayron, lol, <br/>Ey
                  Dunyo! Boryapman – meni qarshi ol – <br/>Shu buyuk millatning umidiman men!</p>
              </div>
            </div>
            <div className="about-us">
              <h2 className="about-us__header text-center mt-1 mb-4">
                {t('Overview')}
              </h2>
              <div className="about-us__text-container">
                <p className="about-us__text">
                  {t('Overview sub')}
                </p>
                <p className="about-us__text">
                  {t('Overview sub 2')}
                </p>
                <p className="about-us__text">
                  {t('Overview sub 3')}
                </p>
              </div>
            </div>
            <h2 className="mission text-center mb-4">{t('Our purpose')}</h2>
            <h5 className="text-center">
              <i>"{t('Our Core Value')}"</i>
            </h5>
            <div className="mission-text__container">
              <p className="about-us__text">
                <b>{t('Our Core Value sub')}</b> {t('Core sub 2')}
                <span className="scholars"><a
                    href="https://en.wikipedia.org/wiki/Avicenna"> Ibn Sino (Avicenna), </a></span>
                <span className="scholars"><a
                    href="https://en.wikipedia.org/wiki/Al-Khwarizmi">Al Khorazmiy, </a></span>
                <span className="scholars"><a
                    href="https://en.wikipedia.org/wiki/Al-Biruni">Abu Rayhon Beruniy, </a></span>{t('Core sub 3')}
                <span className="scholars"><a
                    href="https://en.wikipedia.org/wiki/Transoxiana"> (Movarounnahr),</a></span> {t('Core sub 4')}
              </p>
              <p className="about-us__text">
                {t('Core sub 5')} <b>{t('Slogan')}</b>
                {t('Core sub 6')}
              </p>
              <p className="about-us__text">
                {t('Core sub 7')}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default University;
