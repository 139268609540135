import React from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { useTranslation } from 'react-i18next';


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={ `slick-next ${className}`} style={{ ...style }} onClick={onClick} ><i className="fal fa-angle-right"></i></button>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={ `slick-prev ${className}`} style={{ ...style }} onClick={onClick} ><i className="fal fa-angle-left"></i></button>

    );
}

function Courses() {
    const { i18n, t } = useTranslation();
    const courses = [
        {
            category: t('Information Technology'),
            title: t('IT'),
            link: '/BSc-in-Software-Engineering',
            img: '../../../assets/img/students/IT-bg-1.jpg',
        },
        {
            category: t('business management'),
            title: t('BTEC'),
            link: '/Business-Management',
            img: '../../../assets/img/students/BTEC-bg-1.jpg',
        },
        {
            category: t('language'),
            title: t('ELT'),
            link: '/English-language-teaching',
            img: '../../../assets/img/students/ELT-bg-1.jpg',
        },
        {
            category: t('finance'),
            title: t('ACCA'),
            link: '/Applied-accounting',
            img: '../../../assets/img/students/ACCA-bg-1.jpg',
        },
        {
            category: t('business'),
            title: t('BM'),
            link: '/Business_national',
            img: '../../../assets/img/students/ACCA-bg-2.jpg',
        },
    ];

    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        prevArrow: <SamplePrevArrow/>,
        nextArrow: <SampleNextArrow/>,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <section className="courses pt-30 pb-80 p-relative fix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 p-relative">
                            <div className="section-title center-align mb-50 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                                <h5>
                                    <i className="fal fa-graduation-cap" /> {t('Our schools')}
                                </h5>
                                <h2>{t('Undergraduate Programs')}</h2>
                            </div>
                        </div>
                    </div>
                    <Slider className="row class-active" {...settings}>
                        {courses.map((course, index) => (
                            <div key={index} className="col-lg-4 col-md-6 ">
                                <div className="courses-item mb-30 hover-zoomin">
                                    <div className="thumb fix">
                                        <Link to={course.link}>
                                            <div>
                                                <img style={{objectFit: "cover", height: "250px"}} className="course-img" src={course.img} alt="contact-bg-an-01" />
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="courses-content">
                                        <div className="cat">
                                            <i className="fal fa-graduation-cap" /> {course.category}
                                        </div>
                                        <h4>
                                            <Link to={course.link}>{course.title}</Link>
                                        </h4>
                                        <p>
                                            {course.description}
                                        </p>
                                        <Link to={course.link} className="readmore">
                                            {t('Read More')}<i className="fal fa-long-arrow-right" />
                                        </Link>
                                    </div>
                                    <div className="icon">
                                        <img src="assets/img/icon/cou-icon.png" className="course-img" alt="img" />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        </>
    )
}

export default Courses