import React from 'react'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import First from '../mencourses/First'
import { useTranslation } from 'react-i18next';


function Main() {
    const { i18n, t } = useTranslation();
    return (
    <>
        <Header/>
        <Bredcom title="Home" subtitle={t('courses')}/>
        <First/>
    </>
  )
}

export default Main