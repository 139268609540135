import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from 'react-i18next';


function About() {
    const [yearsOfExperience, setYearsOfExperience] = useState(0);
    const {i18n, t} = useTranslation();


    useEffect(() => {
        function calculateExperience() {
            const startDate = new Date("2021-05-31");
            const currentDate = new Date();

            const yearsDiff = currentDate.getFullYear() - startDate.getFullYear();

            setYearsOfExperience(yearsDiff);
        }

        calculateExperience();

        const timer = setInterval(calculateExperience, 1000 * 60 * 60 * 24 * 365);
        return () => clearInterval(timer);
    }, []);
    return (
        <>
            <section className="about-area-main about-p pt-120 pb-120 p-relative fix">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-8 mb-50 position-relative">
                            <div className="embed-responsive embed-responsive-16by9">
                                <div className="video-overlay"></div>
                                <video controls className="embed-responsive-item">
                                    <source src="../../assets/video/founder-video.mp4" type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div
                                className="s-about-img p-relative  wow fadeInLeft animated"
                                data-animation="fadeInLeft"
                                data-delay=".4s"
                            >
                                {/*<img src="assets/img/features/about_img_02.png" alt="img" />*/}
                                <img src="../../assets/img/features/university-heder-about.jpg" alt="home university"/>
                                <div className="about-text second-about">
                  <span>
                    {yearsOfExperience} <sub>+</sub>
                  </span>
                                    <p>{t('Years Market Presence')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div
                                className="about-content s-about-content pl-15 wow fadeInRight  animated"
                                data-animation="fadeInRight"
                                data-delay=".4s"
                            >
                                <div className="about-title second-title pb-25">
                                    <h2>{t('Tittle')}</h2>
                                </div>
                                <p className="txt-clr">
                                    {t('Sub text')}
                                </p>
                                <div className="slider-btn mt-50">
                                    <Link to="/about" className="btn ss-btn smoth-scroll">
                                        {t('Read More')} <i className="fal fa-long-arrow-right"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default About;
