import React from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import First from '../menteacher/First'
import { useTranslation } from 'react-i18next';

function Main() {
    const { i18n, t } = useTranslation();
    return (
    <>
        <Header/>
        <Bredcom title="Home" subtitle={t('team')}/>
        <First/>
    </>
  )
}

export default Main