import React from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import Event2 from '../meneventdeatils/Event2'
import { useTranslation } from 'react-i18next';


function Main() {
    const { i18n, t } = useTranslation();

    return (
        <>
            <Header/>
            <Bredcom title="Home" subtitle={t('event details')}/>
            <Event2/>
        </>
    )
}

export default Main