import React from 'react'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import Second from '../mencoursedetails/Applied-accounting'
import {useTranslation} from "react-i18next";

function Main() {
    const { i18n, t } = useTranslation();

    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle={t('Course Details')} />
            <Second/>
        </>
    )
}

export default Main