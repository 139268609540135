import React from 'react'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import Second from '../mencoursedetails/Master-english-language'
function Main() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="Course Details" />
            <Second/>
        </>
    )
}

export default Main