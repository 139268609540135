import React, { useState } from 'react'
import { Link } from 'react-router-dom'


function Master_english_teaching() {

    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                <h2>Master of Arts in English Language Education</h2>
                                <div className="upper-box">
                                    <div className="single-item-carousel owl-carousel owl-theme">
                                        <figure className="image">
                                            <img style={{maxWidth: "100%"}} src="../../../assets/img/bg/master-elt.png" alt="master-elt-image" />
                                        </figure>
                                    </div>
                                </div>
                                {/*<div className="inner-column">*/}
                                {/*    <div className="course-meta2 review style2 clearfix mb-30">*/}
                                {/*        <ul className="left">*/}
                                {/*            <li>*/}
                                {/*                <div className="author">*/}
                                {/*                    <div className="thumb">*/}
                                {/*                        <img className="team-img" src="assets/img/bg/testi_avatar.png" alt="image" />*/}
                                {/*                    </div>*/}
                                {/*                    <div className="text">*/}
                                {/*                        <Link to="/team-single">Muslimbek Pirnazarov</Link>*/}
                                {/*                        <p>Dean, Teacher</p>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </li>*/}
                                {/*            <li className="categories">*/}
                                {/*                <div className="author">*/}
                                {/*                    <div className="text">*/}
                                {/*                        <a href="#" className="course-name">*/}
                                {/*                            Faculty*/}
                                {/*                        </a>*/}
                                {/*                        <p>Information Technology</p>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </li>*/}
                                {/*            <li>*/}
                                {/*                <div className="author">*/}
                                {/*                    <div className="text">*/}
                                {/*                        <p>*/}
                                {/*                            <a href="mailto:m.prirnazarov@cambridge.uz">Email</a>*/}
                                {/*                        </p>*/}
                                {/*                        <p>m.prirnazarov@cambridge.uz</p>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </li>*/}
                                {/*            <li>*/}
                                {/*                <div className="author">*/}
                                {/*                    <div className="text">*/}
                                {/*                        <a href="#">Room</a>*/}
                                {/*                        <p>Branch A, Second floor</p>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </li>*/}
                                {/*        </ul>*/}
                                {/*    </div>*/}
                                {/*    <h3>Course Overview</h3>*/}
                                {/*    <p>*/}
                                {/*        Software engineers develop and maintain large-scale complex software infrastructures.*/}
                                {/*        Our programme combines theoretical computing science with the principles and practices used in the modern software industry and*/}
                                {/*        gives you real world experience. You’ll become skilled in analysing work processes, envisioning complex system architectures,*/}
                                {/*        producing system specifications, and planning and overseeing complex development projects.*/}
                                {/*    </p>*/}
                                {/*    <b>Mission</b>*/}
                                {/*    <p>*/}
                                {/*        Software engineering graduates are highly employable and can look forward to well-paid careers designing and building*/}
                                {/*        the digital technologies that underpin the global economy and, indeed, every aspect of human activity from healthcare*/}
                                {/*        through music to making the natural environment sustainable. .*/}
                                {/*    </p>*/}
                                {/*    <b>Your future career</b>*/}
                                {/*    <p>*/}
                                {/*        Software Engineering students acquire a range of valuable skills, which they can transfer to many different employment situations.*/}
                                {/*        Your skills can be used in IT technician, Database administrator,*/}
                                {/*        Web developer and the highly competitive fields of Information security analyst and Software engineering.*/}
                                {/*        You will also gain other skills such as the capacity to analyze and summarize material, to communicate, to work to a deadline, to argue a case,*/}
                                {/*        to work independently as well as collaboratively, to think logically and to be able to develop different applications.*/}
                                {/*    </p>*/}
                                {/*    <h4>Careers after graduation</h4>*/}
                                {/*    <p>*/}
                                {/*        This is excellent preparation for a wide number of professions and as such,*/}
                                {/*        our students have gone on to a variety of careers after graduation, including:*/}
                                {/*        <span>*/}
                                {/*            <ul>*/}
                                {/*                <li>- Applications developer</li>*/}
                                {/*                <li>- Cyber security analyst</li>*/}
                                {/*                <li>- Game developer</li>*/}
                                {/*                <li>- Information systems manager</li>*/}
                                {/*                <li>- IT consultant</li>*/}
                                {/*                <li>- Multimedia programmer</li>*/}
                                {/*                <li>- Web developer</li>*/}
                                {/*                <li>- Web designer</li>*/}
                                {/*                <li>- Software engineer</li>*/}
                                {/*            </ul>*/}
                                {/*        </span>*/}
                                {/*    </p>*/}
                                {/*    <h4>What you will learn</h4>*/}
                                {/*    <p>*/}
                                {/*        This is excellent preparation for a wide number of professions and as such,*/}
                                {/*        our students have gone on to a variety of careers after graduation, including:*/}
                                {/*        <span>*/}
                                {/*            <ul>*/}
                                {/*                <li>- Python programming language</li>*/}
                                {/*                <li>- Mathematical analysis</li>*/}
                                {/*                <li>- Database Concepts</li>*/}
                                {/*                <li>- Algorithms & Data Structures</li>*/}
                                {/*                <li>- Web Application Development</li>*/}
                                {/*                <li>- Big Data</li>*/}
                                {/*                <li>- Mobile Application Development</li>*/}
                                {/*                <li>- Artificial Intelligence</li>*/}
                                {/*                <li>- Game development</li>*/}
                                {/*            </ul>*/}
                                {/*        </span>*/}
                                {/*        and other subjects*/}
                                {/*    </p>*/}
                                {/*    <h4>Course Overview</h4>*/}
                                {/*    <table className="table table-bordered mb-40">*/}
                                {/*        <thead>*/}
                                {/*        <tr>*/}
                                {/*            <th>Course Name</th>*/}
                                {/*            <th>Duration</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <tbody>*/}
                                {/*        <tr>*/}
                                {/*            <td>Foundation</td>*/}
                                {/*            <td>1 year</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>BSc in Software Engineering</td>*/}
                                {/*            <td>3 years full time</td>*/}
                                {/*        </tr>*/}
                                {/*        </tbody>*/}
                                {/*    </table>*/}
                                {/*    <h4>SOFTWARE ENGINEERING TEACHING FOUNDATION CURRICULUM</h4>*/}
                                {/*    <table className="table table-bordered mb-40">*/}
                                {/*        <thead>*/}
                                {/*        <tr>*/}
                                {/*            <th className="text-center" colSpan="12">SEMESTER I</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <thead>*/}
                                {/*        <tr>*/}
                                {/*            <th>Code</th>*/}
                                {/*            <th>Course Name</th>*/}
                                {/*            <th>GLH</th>*/}
                                {/*            <th>T</th>*/}
                                {/*            <th>P</th>*/}
                                {/*            <th colSpan="2">ECTS</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <tbody>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSC1010</td>*/}
                                {/*            <td>*/}
                                {/*                <Link to="/subject">*/}
                                {/*                    Introduction to Programming - 1*/}
                                {/*                </Link>*/}
                                {/*            </td>*/}
                                {/*            <td>60</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">6</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>BA1105</td>*/}
                                {/*            <td>English for Academic Purposes (EAP)</td>*/}
                                {/*            <td>80</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">8</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSC1012</td>*/}
                                {/*            <td>Maths for Engineering, Science & Technology-1</td>*/}
                                {/*            <td>100</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">10</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSC1014</td>*/}
                                {/*            <td>Introduction to IT</td>*/}
                                {/*            <td>60</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">6</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td className="text-right" colSpan="2">Total</td>*/}
                                {/*            <td>300</td>*/}
                                {/*            <td></td>*/}
                                {/*            <td></td>*/}
                                {/*            <td>30</td>*/}
                                {/*        </tr>*/}
                                {/*        </tbody>*/}
                                {/*    </table>*/}

                                {/*    <table className="table table-bordered mb-40">*/}
                                {/*        <thead>*/}
                                {/*        <tr>*/}
                                {/*            <th className="text-center" colSpan="12">SEMESTER II</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <thead>*/}
                                {/*        <tr>*/}
                                {/*            <th>Code</th>*/}
                                {/*            <th>Course Name</th>*/}
                                {/*            <th>GLH</th>*/}
                                {/*            <th>T</th>*/}
                                {/*            <th>P</th>*/}
                                {/*            <th colSpan="2">ECTS</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <tbody>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSC1013</td>*/}
                                {/*            <td>Maths for Engineering, Science & Technology-2</td>*/}
                                {/*            <td>80</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">8</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>BA1104</td>*/}
                                {/*            <td>Personal Development (LCTI)</td>*/}
                                {/*            <td>60</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">6</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>BA1110</td>*/}
                                {/*            <td>English for Academic Purposes (EAP)</td>*/}
                                {/*            <td>100</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">12</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSC1011</td>*/}
                                {/*            <td>Introduction to Programming - 2</td>*/}
                                {/*            <td>60</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">6</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td className="text-right" colSpan="2">Total</td>*/}
                                {/*            <td>300</td>*/}
                                {/*            <td></td>*/}
                                {/*            <td></td>*/}
                                {/*            <td>30</td>*/}
                                {/*        </tr>*/}
                                {/*        </tbody>*/}
                                {/*    </table>*/}


                                {/*    <h4>SOFTWARE ENGINEERING TEACHING UNDERGRADUATE CURRICULUM</h4>*/}
                                {/*    <table className="table table-bordered mb-40">*/}
                                {/*        <thead>*/}
                                {/*        <tr>*/}
                                {/*            <th className="text-center" colSpan="12">SEMESTER I</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <thead>*/}
                                {/*        <tr>*/}
                                {/*            <th>Code</th>*/}
                                {/*            <th>Course Name</th>*/}
                                {/*            <th>GLH</th>*/}
                                {/*            <th>T</th>*/}
                                {/*            <th>P</th>*/}
                                {/*            <th colSpan="2">ECTS</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <tbody>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSC2010</td>*/}
                                {/*            <td>Computer Science I</td>*/}
                                {/*            <td>60</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">6</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSC2012</td>*/}
                                {/*            <td>Database Concepts</td>*/}
                                {/*            <td>80</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">8</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSC2013</td>*/}
                                {/*            <td>Mathematical Analysis</td>*/}
                                {/*            <td>100</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">10</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSC2014</td>*/}
                                {/*            <td>Fundamentals of IT</td>*/}
                                {/*            <td>60</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">6</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td className="text-right" colSpan="2">Total</td>*/}
                                {/*            <td>300</td>*/}
                                {/*            <td></td>*/}
                                {/*            <td></td>*/}
                                {/*            <td>30</td>*/}
                                {/*        </tr>*/}
                                {/*        </tbody>*/}
                                {/*    </table>*/}

                                {/*    <table className="table table-bordered mb-40">*/}
                                {/*        <thead>*/}
                                {/*        <tr>*/}
                                {/*            <th className="text-center" colSpan="12">SEMESTER II</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <thead>*/}
                                {/*        <tr>*/}
                                {/*            <th>Code</th>*/}
                                {/*            <th>Course Name</th>*/}
                                {/*            <th>GLH</th>*/}
                                {/*            <th>T</th>*/}
                                {/*            <th>P</th>*/}
                                {/*            <th colSpan="2">ECTS</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <tbody>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSC2015</td>*/}
                                {/*            <td>Algorithms & Data Structures</td>*/}
                                {/*            <td>80</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">8</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSC2016</td>*/}
                                {/*            <td>Object Oriented Programming</td>*/}
                                {/*            <td>60</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">6</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSC2017</td>*/}
                                {/*            <td>Computer Networks</td>*/}
                                {/*            <td>100</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">10</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSC2011</td>*/}
                                {/*            <td>Computer Science II</td>*/}
                                {/*            <td>60</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">6</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td className="text-right" colSpan="2">Total</td>*/}
                                {/*            <td>300</td>*/}
                                {/*            <td></td>*/}
                                {/*            <td></td>*/}
                                {/*            <td>30</td>*/}
                                {/*        </tr>*/}
                                {/*        </tbody>*/}
                                {/*    </table>*/}

                                {/*    <table className="table table-bordered mb-40">*/}
                                {/*        <thead>*/}
                                {/*        <tr>*/}
                                {/*            <th className="text-center" colSpan="12">SEMESTER III</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <thead>*/}
                                {/*        <tr>*/}
                                {/*            <th>Code</th>*/}
                                {/*            <th>Course Name</th>*/}
                                {/*            <th>GLH</th>*/}
                                {/*            <th>T</th>*/}
                                {/*            <th>P</th>*/}
                                {/*            <th colSpan="2">ECTS</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <tbody>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSE3010</td>*/}
                                {/*            <td>Web Application Development I</td>*/}
                                {/*            <td>100</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">10</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSE3012</td>*/}
                                {/*            <td>Big Data</td>*/}
                                {/*            <td>80</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">8</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSC3012</td>*/}
                                {/*            <td>Computer System Security</td>*/}
                                {/*            <td>80</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">6</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSE3XXX</td>*/}
                                {/*            <td>Elective course</td>*/}
                                {/*            <td>60</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">6</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td className="text-right" colSpan="2">Total</td>*/}
                                {/*            <td>300</td>*/}
                                {/*            <td></td>*/}
                                {/*            <td></td>*/}
                                {/*            <td>30</td>*/}
                                {/*        </tr>*/}
                                {/*        </tbody>*/}
                                {/*    </table>*/}

                                {/*    <table className="table table-bordered mb-40">*/}
                                {/*        <thead>*/}
                                {/*        <tr>*/}
                                {/*            <th className="text-center" colSpan="12">SEMESTER IV</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <thead>*/}
                                {/*        <tr>*/}
                                {/*            <th>Code</th>*/}
                                {/*            <th>Course Name</th>*/}
                                {/*            <th>GLH</th>*/}
                                {/*            <th>T</th>*/}
                                {/*            <th>P</th>*/}
                                {/*            <th colSpan="2">ECTS</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <tbody>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSE3011</td>*/}
                                {/*            <td>Web Application Development II</td>*/}
                                {/*            <td>100</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">10</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSE3013</td>*/}
                                {/*            <td>Mobile Application Development</td>*/}
                                {/*            <td>80</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">8</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSC3012</td>*/}
                                {/*            <td>Fundamentals of Operating Systems</td>*/}
                                {/*            <td>60</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">6</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSE3XXX</td>*/}
                                {/*            <td>Elective course</td>*/}
                                {/*            <td>60</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">6</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td className="text-right" colSpan="2">Total</td>*/}
                                {/*            <td>300</td>*/}
                                {/*            <td>8</td>*/}
                                {/*            <td>9</td>*/}
                                {/*            <td>30</td>*/}
                                {/*        </tr>*/}
                                {/*        </tbody>*/}
                                {/*    </table>*/}

                                {/*    <table className="table table-bordered mb-40">*/}
                                {/*        <thead>*/}
                                {/*        <tr>*/}
                                {/*            <th className="text-center" colSpan="12">SEMESTER V</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <thead>*/}
                                {/*        <tr>*/}
                                {/*            <th>Code</th>*/}
                                {/*            <th>Course Name</th>*/}
                                {/*            <th>T</th>*/}
                                {/*            <th>P</th>*/}
                                {/*            <th colSpan="2">ECTS</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <tbody>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSE4010</td>*/}
                                {/*            <td>Software Project 1: Requirements Engineering and Design</td>*/}
                                {/*            <td>100</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">10</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSE4012</td>*/}
                                {/*            <td>Artificial Intelligence</td>*/}
                                {/*            <td>60</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">6</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSE4013</td>*/}
                                {/*            <td>Programming Internet of Things</td>*/}
                                {/*            <td>80</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">8</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSE4XXX</td>*/}
                                {/*            <td>Elective course</td>*/}
                                {/*            <td>60</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">6</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td className="text-right" colSpan="2">Total</td>*/}
                                {/*            <td>300</td>*/}
                                {/*            <td>7</td>*/}
                                {/*            <td>11</td>*/}
                                {/*            <td>30</td>*/}
                                {/*        </tr>*/}
                                {/*        </tbody>*/}
                                {/*    </table>*/}

                                {/*    <table className="table table-bordered mb-40">*/}
                                {/*        <thead>*/}
                                {/*        <tr>*/}
                                {/*            <th className="text-center" colSpan="12">SEMESTER VI</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <thead>*/}
                                {/*        <tr>*/}
                                {/*            <th>Code</th>*/}
                                {/*            <th>Course Name</th>*/}
                                {/*            <th>GLH</th>*/}
                                {/*            <th>T</th>*/}
                                {/*            <th>P</th>*/}
                                {/*            <th colSpan="2">ECTS</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        <tbody>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSE4011</td>*/}
                                {/*            <td>Software Project 2: Requirements Engineering and Design</td>*/}
                                {/*            <td>100</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">14</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSE4014</td>*/}
                                {/*            <td>Game Development</td>*/}
                                {/*            <td>80</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">8</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSE4015</td>*/}
                                {/*            <td>AR/VR Development</td>*/}
                                {/*            <td>60</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">6</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td>CSE4XXX</td>*/}
                                {/*            <td>Elective course</td>*/}
                                {/*            <td>60</td>*/}
                                {/*            <td>4</td>*/}
                                {/*            <td>6</td>*/}
                                {/*            <td colSpan="2">6</td>*/}
                                {/*        </tr>*/}
                                {/*        <tr>*/}
                                {/*            <td className="text-right" colSpan="2">Total</td>*/}
                                {/*            <td>300</td>*/}
                                {/*            <td>10</td>*/}
                                {/*            <td>8</td>*/}
                                {/*            <td>30</td>*/}
                                {/*        </tr>*/}
                                {/*        </tbody>*/}
                                {/*    </table>*/}
                                {/*</div>*/}
                            </div>
                            {/*<div className="col-lg-3">*/}
                            {/*    <aside className="sidebar-widget info-column">*/}
                            {/*        <div className="inner-column3">*/}
                            {/*            <h3>Course Features</h3>*/}
                            {/*            <ul className="project-info clearfix">*/}
                            {/*                <li>*/}
                            {/*                    <span className="icon fal fa-home-lg-alt" />{" "}*/}
                            {/*                    <strong>Campus:</strong> <span>Branch C</span>*/}
                            {/*                </li>*/}
                            {/*                <li>*/}
                            {/*                    <span className="icon fal fa-book" />{" "}*/}
                            {/*                    <strong>Lectures:</strong> <span>36 </span>*/}
                            {/*                </li>*/}
                            {/*                <li>*/}
                            {/*                    <span className="icon fal fa-clock" />{" "}*/}
                            {/*                    <strong>Duration: </strong> <span>4 years</span>*/}
                            {/*                </li>*/}
                            {/*                <li>*/}
                            {/*                    <span className="icon fal fa-clock" />{" "}*/}
                            {/*                    <strong>Tuition fee: </strong> <span>25,000,000 UZS</span>*/}
                            {/*                </li>*/}
                            {/*                <li>*/}
                            {/*                    <span className="icon fal fa-globe" />{" "}*/}
                            {/*                    <strong>Language: </strong> <span>English</span>*/}
                            {/*                </li>*/}
                            {/*                <li>*/}
                            {/*                    <div className="slider-btn">*/}
                            {/*                        <Link target="_blank" to="https://admission.millatumidi.uz/" className="btn ss-btn smoth-scroll">*/}
                            {/*                            Apply <i className="fal fa-long-arrow-right" />*/}
                            {/*                        </Link>*/}
                            {/*                    </div>*/}
                            {/*                </li>*/}
                            {/*            </ul>*/}
                            {/*        </div>*/}
                            {/*    </aside>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Master_english_teaching