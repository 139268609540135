import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

function Event10() {
    const { i18n, t } = useTranslation();
    return (
        <>
            <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                <div className="upper-box">
                                    <div className="single-item-carousel owl-carousel owl-theme">
                                        <figure className="image">
                                            <img style={{maxWidth: "100%"}} src="assets/img/event/university-collab.jpg" alt="event-details-image"/>
                                        </figure>
                                    </div>
                                </div>
                                <div className="inner-column">
                                    <h3>{t('event-title-9')}</h3>
                                    <p>{t('event-desc-8')}</p>
                                    <h6>{t('Become a global citizen with us')}</h6>
                                    <div className="two-column mt-20">
                                        <div className="row aling-items-center">
                                            <div className="image-column col-xl-6 col-lg-12 col-md-12">
                                            </div>
                                            <div className="text-column col-xl-6 col-lg-12 col-md-12 text-right"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <aside className="sidebar-widget info-column">
                                    <div className="inner-column3">
                                        <h3>Event Details</h3>
                                        <ul className="project-info clearfix">
                                            <li>
                                                <span className="icon fal fa-calendar-alt" />
                                                <strong>16 July, 2024</strong>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-map-marker-check" />
                                                <strong> Toshkent sh., Chilonzor tumani, Bunyodkor, Navbahor 24</strong>
                                            </li>

                                        </ul>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Event10;