import React from "react";
import University from "../Menabout/University";
import Header from "../headerone/Main";
import Bredcom from "../Bredcom/Main";
import Document from "../Menabout/Document";
import Redslider from "../menhome/Redslider";
import Campus from "../Menabout/Campus";
import { useTranslation } from 'react-i18next';


function Main() {
    const { i18n, t } = useTranslation();
    return (
    <>
      <Header />
      <Bredcom title="Home" subtitle={t('about')} />
      <University />
      <Campus />
      <Document />
      <Redslider />
    </>
  );
}

export default Main;
