import React from "react";
import { Link } from "react-router-dom";

function First() {
    const teamMembers = [
        {
            name: "Muhtor Bekmuratov",
            role: "Dean of Accounting and Finance (ACCA) faculty",
            image: "assets/img/team/acca-team1.png",
        },
        {
            name: "Abdulkarim Rustamov",
            role: "MSc Teacher ",
            image: "assets/img/team/acca-team2.png",
        },
    ];

    return (
        <>
            <section
                className="team-area fix p-relative pt-80 pb-80"
                style={{ background: "#f7f9ff" }}
            >
                <div className="container">
                    <div className="row">
                        <h2 className="text-center">ACCA Department</h2>
                        {teamMembers.map((member, index) => (
                            <div key={index} className="col-xl-3 col-md-6">
                                <div className="teachers-card single-team mb-40">
                                    <div className="team-thumb">
                                        <div className="brd">
                                            <img
                                                className="team-img"
                                                src={member.image}
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                    <div className="team-info">
                                        <h4>{member.name}</h4>
                                        <p>{member.role}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default First;
