import React from "react";
import {useTranslation} from 'react-i18next';

function TeamAll() {
  const {t} = useTranslation();
  const departments = [
    {
      name: "Rectorate",
      members: [
        {
          name: "Ishmanova Dinora Nurmamad Qizi",
          role: "Rector",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Mamatov Xurshid Safaraliyevich",
          role: "Rector's Advisor for Social and Moral Environment Stability",
          image: "assets/img/team/profile.jpg",
        },
      ],
    },
    {
      name: t('IT'),
      members: [
        {
          name: "Pirnazarov Muslimbek Maksud O’g’li",
          role: "Dean",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Yusupova Zaynabxon Djumanazarovna",
          role: "Senior Lecturer",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Xusanov Ramziddin Zokir O’g’li",
          role: "Senior Lecturer",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Mardiyeva Ruxsora Istam qizi",
          role: "Senior Lecturer",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Turg'unov Aziz Uyg'un O'g'li",
          role: "Senior Lecturer",
          image: "assets/img/team/profile.jpg",
        },
      ],
    },
    {
      name: t('BM'),
      members: [
        {
          name: "Xakimov Bunyodjon Jurayevich",
          role: "Senior Lecturer",
          image: "assets/img/team/profile.jpg",
        },
      ],
    },
    {
      name: t('ACCA'),
      members: [
        {
          name: "Bekmuratov Muxtor Taxirovich",
          role: "Dean",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Rustamov Abdulkarim Kamol O'g'li",
          role: "Senior Lecturer",
          image: "assets/img/team/profile.jpg",
        },
      ],
    },
    {
      name: t('BTEC'),
      members: [
        {
          name: "Pan Galina Leonidovna",
          role: "Dean",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "G'ulomov Mirabror Akromjon o'g'li",
          role: "Exam Officer",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Ubaydullayeva Irodaxon Xusanjon qizi",
          role: "Academic Coordinator",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Tursunova Shoxida Erqul Qizi",
          role: "Academic Coordinator",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "To’rayev Jamshid Shukrulloyevich",
          role: "Senior Lecturer",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Sadikov Davronbek Ravshan o’g’li",
          role: "Assistant",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Omar Ashurbayev Abdukarim o'g'li",
          role: "Assistant",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Musayeva Nasibaxon Davron Qizi",
          role: "Lecturer in Business Subjects",
          image: "assets/img/team/profile.jpg",
        },
      ],
    },
    {
      name: t('ELT'),
      members: [
        {
          name: "Ismailov Murad Boltabayevich",
          role: "Dean",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Gulyamova Nozima Xojimurod Qizi",
          role: "Senior Lecturer",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Qurbonova Ruxsora Ergashevna",
          role: "Senior Lecturer",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Tuychiyeva Maftuna Baxramovna",
          role: "Senior Lecturer",
          image: "assets/img/team/profile.jpg",
        },
      ],
    },
    {
      name: "Academic Affairs and Registrar",
      members: [
        {
          name: "Melibayev Baxodir Kaxramonovich",
          role: "Head of Admissions and Student Affairs",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Kuzibayeva Zilola Abdusamatovna",
          role: "Academic Affairs Specialist and Registrar",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Baxramova Dilbaxor Maxmudovna",
          role: "Academic Affairs Specialist and Registrar",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Anvarjonova Nafosat Dilshodjon qizi",
          role: "Academic Affairs Specialist and Registrar",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Xanturayeva Komila Xasanovna",
          role: "Academic Affairs Specialist and Registrar",
          image: "assets/img/team/profile.jpg",
        },
      ],
    },
    {
      name: "Quality Control of Education",
      members: [
        {
          name: "Xo'jamurodova Nafisa Hayitovna",
          role: "Head of Education Quality Control",
          image: "assets/img/team/profile.jpg",
        },
      ],
    },
    {
      name: "Human Resources Management",
      members: [
        {
          name: "Atahanova Feruza Valijanovna",
          role: "Head of HR Department",
          image: "assets/img/team/profile.jpg",
        },
      ],
    },
    {
      name: "Accounting",
      members: [
        {
          name: "Mamanazarov Sanjarbek",
          role: "Chief Accountant",
          image: "assets/img/team/profile.jpg",
        },
      ],
    },
    {
      name: "AT",
      members: [
        {
          name: "Shakirova Gulrux Pulatjon Qizi",
          role: "Head of AT Department",
          image: "assets/img/team/profile.jpg",
        },
      ],
    },
    {
      name: "Department of Spirituality and Enlightenment",
      members: [
        {
          name: "Raximova Kutlibika Ergashevna",
          role: "Tutor",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Xakimova Gavxaroy Abdukayumovna",
          role: "Tutor",
          image: "assets/img/team/profile.jpg",
        },
        {
          name: "Raimova Dildora Ravshanovna",
          role: "Psychologist",
          image: "assets/img/team/profile.jpg",
        },
      ],
    }
  ];

  return (
      <>
        {departments.map((department, deptIndex) => (
            <section
                key={deptIndex}
                className="team-area fix p-relative pt-30 pb-20"
                style={{
                  background: "#f7f9ff",
                  marginBottom: "10px"
                }}
            >
              <div className="container">
                <div className="row justify-content-center">
                  <h3 className="text-center w-100 mb-20">{department.name} Department</h3>
                  {department.members.map((member, index) => (
                      <div key={index} className="col-xl-3 col-lg-4 col-md-6 d-flex justify-content-center">
                        <div className="teachers-card single-team mb-40" style={{ width: "100%", maxWidth: "300px" }}>
                          <div className="team-thumb">
                            <img
                                className="team-img"
                                src={member.image}
                                alt="profile"
                                style={{ width: "100%"}}
                            />
                          </div>
                          <div className="team-info text-center">
                            <h4>{member.name}</h4>
                            <p>{member.role}</p>
                          </div>
                        </div>
                      </div>
                  ))}
                </div>
              </div>
            </section>
        ))}
      </>
  );
}
export default TeamAll;