import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        resources: {
            en: {
                translation: {
                    // Header Section
                    "home": "Home",
                    "about": "About Us",
                    "courses": "Faculty",
                    "faq": "FAQ",
                    "contact": "Contact Us",
                    "admission": "Admission",
                    "pages": "Pages",
                    "scholarship": "Scholarship",
                    "team": "Team",
                    "event": "Event",
                    "apply": "Apply Now",
                    "timetable": "Timetable",

                    // Footer Section
                    "Our Links": "Our Links",
                    "Careers": "Careers",
                    "Mission": "Mission",
                    "Courses": "Faculties",
                    "Undergraduate": "Undergraduate",
                    "Postgraduate": "Postgraduate",


                    // Sliderone Section
                    "Welcome To MU University": "Welcome To MU University",
                    "Become a global citizen with us": "Become a global citizen with us!",
                    "Sliderone sub text": "At MU University we don’t just educate you, we prepare you to find the job you want and support you and your future! To Be The Best, Learn From The Best! Don’t Miss Out! Enroll at MU University Today!",
                    "Read More": "Read More",
                    "Sliderone sub text 2": "Dont miss chance to study at MU University and be a part of our community of students and alumni! We are waiting for you!",
                    "Bachelors Degree": "Bachelor’s Degree",
                    "Masters Degree": "Master's Degree",

                    // About Section
                    "Tittle": "A few words about the university",
                    "Sub text": "MU University is a unique educational institution, which was established in 2021 and is the recognized standard for quality education in the world. Millat Umidi University gives its students the opportunity to gain specialized knowledge and extensive possibilities for further learning and choosing their own path in life.",
                    "About Our University": "About Our University",
                    "Sub text2": "MU University offers full educational programmes for students leading to academic degrees",
                    "Rectors Greeting": "Rector's Greeting",
                    "Dear students": "Dear students,",
                    "Rectors Greeting Text": "In today's era of globalization, quality education plays a crucial role in opening international doors. " +
                        "As you join MU University, you will have the opportunity to learn from foreign professors and engage with a team of visiting practitioners from leading companies in our republic. " +
                        "We are dedicated to not only teaching you but also preparing you to secure the job you desire, providing unwavering support along the way. At MU University, we offer the chance to earn recognized certifications " +
                        "such as CELTA, DELTA, ACCA, and CERTIPORT, which hold value in developed countries. Start building the foundation for your future today and become a global citizen with us.",
                    "Welcome to the MU University family": "Welcome to the MU University family, dear students!",
                    "Sincerely": "Sincerely,",
                    "University License": "University License",
                    "License Text": "The distinctive features of MU University include its unique, career-relevant, high-quality, and inclusive educational programs, which have earned international recognition and accreditation. " +
                        "As a result, our graduates are equipped to gain admission to over 300 universities worldwide. Additionally, MU University collaborates with various national universities, " +
                        "enabling students to seamlessly transition into their chosen professions and directly enroll in the second year of their studies.",
                    "Overview": "Overview",
                    "Overview sub": "Distinctive features of MU University are the unique career-relevant, high quality and inclusive educational programs, " +
                        "which are internationally recognized and accredited. That in turn allows our graduates to enter more than 300 Universities around the world. " +
                        "In addition, MU University with a number of national universities, where students can continue their studies by immediately enrolling into the 2nd year of the chosen profession.",
                    "Overview sub 2": "The strong core of MU University is its teaching staff. All teachers have undergone specialized trainings and studied at foreign universities in abroad. " +
                        "Our teachers are ready to provide comprehensive support in the educational process for better assimilation of knowledge and development of the potential of our students.",
                    "Overview sub 3": "MU University also supports talented students by providing scholarships for education, motivating them towards further achievements.",
                    "Our purpose": "Our purpose and value",
                    "Our Core Value": "Our Core Value is to repeat Golden Age we had in the past",
                    "Our Core Value sub": "Our Core Value",
                    "Core sub 2": "is to repeat Golden Age we had in the past. Of course, it is our dignity to proud of having such scholars,",
                    "Core sub 3": "and many other scholars who have lived in Central Asia",
                    "Core sub 4": "but it should be our duty to be deserving descendant for our ancestors where we plan to start with our Millat Umidlari (Nation's Hope).",
                    "Core sub 5": "Purpose of every single student's in our University should be",
                    "Slogan": "to become global citizen",
                    "Core sub 6": ". Hence, we have established connections between each faculty and internationally recognized certification programs, " +
                        "enabling students to become eligible candidates for global acceptance upon successfully passing the required examinations. " +
                        "We are proud to facilitate our students' participation in transfer or exchange programs to prestigious universities abroad. " +
                        "This opportunity is particularly significant as many of our scholars and predecessors have traveled extensively to various parts of the world in pursuit of the finest education, " +
                        "enabling them to become distinguished scholars on a global scale.",
                    "Core sub 7": "If your purpose aligns with our purpose, we are glad to see you in our University.",

                    // Course Section
                    "Undergraduate Program": "Undergraduate Program",
                    "Our schools": "Our Schools",
                    "Undergraduate Programs": "Undergraduate Programs",
                    "Information Technology": "Information Technology",
                    "business management": "Business Management",
                    "language": "Language",
                    "finance": "Finance",
                    "business": "Business National",
                    "Course Details": "Faculty Details",
                    "Course Features": "Faculty Features",
                    "Full time": "Full Time",
                    "Part time": "Part Time",
                    "Night time": "Evening Studies",

                    // Subjects:
                    "IT": "Information Technologies: CS & SE",
                    "BTEC": "International Business (Pearson BTEC)",
                    "ELT": "English Language Teaching (CELTA)",
                    "ACCA": "Accounting & Finance (ACCA)",
                    "BM": "Business Administration (National Curriculum)",
                    "M-ELT": "Master of Arts in English Language Education",
                    "BSc in Software Engineering": "Information Technologies: CS & SE",

                    // Event Section
                    "Our events": "Our Events",
                    "Upcoming Events": "Upcoming Events",
                    "event details": "Event Details",

                    "event-title-1": "Memorandum of Exchange, Cooperation between Regional Director of ACCA",
                    "event-title-2": "The number of members of the association is expanding",
                    "event-title-3": "Rasul Kusherbayev met with Japanese Ambassador Hatori Takashi",
                    "event-title-4": "MU University is signing new contracts with Japanese companies.",
                    "event-title-5": "Millat Umidi University is launching cooperation with Dongseo University of the South Korean Republic!",
                    "event-title-6": "StudentHunter: towards expanding partnerships.",
                    "event-title-7": "Millat Umidi University students visited Artel as part of a techno-tour.",
                    "event-title-8": "Millat Umidi University in Partnership Talks with RINGO Entertainment for IT Job Opportunities",
                    "event-title-9": "Exciting Collaboration Announcement between Millat Umidi University and University of Portsmouth (UoP)",

                    "event-desc-1": "A memorandum of mutual exchange and cooperation was signed between ACCA's regional " +
                        "director for Central Asia, Armenia, Mongolia, Zhanna Iskenova, and Millat Umidi University.",
                    "event-desc-2": "Yesterday, Millat Umid University was presented with a membership certificate in connection with its admission to the membership of the \"Yosh Ziyokor\" association.\n" +
                        "At the event, the Association and the University discussed the issues of wider attraction of international educational grants to Uzbekistan.\n",
                    "event-desc-3": "Association of non-governmental educational organizations \"Yosh Ziyokor\" " +
                        "has started to establish relations with embassies and organizations of several countries" +
                        " within the framework of cooperation with foreign universities and research institutes.",
                    "event-desc-4": "Exciting news! Our university recently hosted a meeting with a Japanese company to discuss internships, 2+2 double degree programs, and other agreements.\n" +
                        "Representatives from both sides came together to explore opportunities for collaboration and exchange programs.\n" +
                        "This meeting aimed to foster international partnerships, providing students with valuable internship experiences and the chance to pursue higher education opportunities abroad.\n" +
                        "By forging these agreements, we are opening doors for cultural exchange and creating global opportunities for our students.\n",
                    "event-desc-5": "Within the framework of this partnership with Dongseo University for the 2023–2024 academic year:\n" +
                        "🔸 2+2 join-degree program;\n" +
                        "🔸 Foreign professors and students exchange program;\n" +
                        "🔸Internship opportunities in prestigious Korean business and IT companies are planned!\n",
                    "event-desc-6": "Today, a meeting was held in the building of MU University with the participation of the rector of the University, doctor of philosophy in Economic Sciences (PhD), Associate Professor Dinora Ishmanova and the commercial director of Action Group Daniel Hamidov.\n" +
                        "In the first part of the meeting, the university was closely acquainted with the conditions created for students, its material and technical base and teaching processes.\n" +
                        "During the initial segment of the meeting, the university underwent a comprehensive introduction to the circumstances established for its student body, including an examination of its physical and technical infrastructure as well as the pedagogical methodologies employed in the educational processes.\n" +
                        "The central portion of the meeting featured a presentation highlighting the prospective plans, objectives, and prospects associated with the StudentHunter platform. Within the context of this presentation, in addition to elucidating the novel educational prospects, deliberations were conducted regarding challenges and impediments that commonly manifest during the application process for academic pursuits and employment opportunities.\n" +
                        "Concluding the meeting, a formal agreement was executed, wherein both parties conveyed their commitment to engage in a sustained and mutually advantageous collaboration within the realm of education.\n",
                    "event-desc-7": "\"Millat Umidi University\" is in talks with Japan's \"RINGO Entertainment Inc.\" for a potential collaboration. RINGO Entertainment aims to offer exciting job opportunities for our IT students, connecting education with the tech industry.",
                    "event-desc-8": "We are delighted to announce that following a productive board meeting, UoP and Millat Umidi University have launched a top-up program! \n" +
                        "\n" +
                        "This collaboration has received formal approval from the board, marking the commencement of an enriching journey together. \n" +
                        "\n" +
                        "As part of this collaboration, students who have completed the 3rd-year Pearson BTEC program at our University can now pursue a double degree (they will be able to obtain diploma from UoP), leveraging their academic achievements to broaden their career prospects.\n" +
                        "\n" +
                        "Stay tuned for further updates as we embark on this exciting venture!",

                    // Testimonial Section
                    "Testimonial": "Testimonial",
                    "What our students say": "What Our Students Say",


                    // Home FAQ  Section
                    "Frequently asked questions": "Frequently asked questions",
                    "faq1": "1. What is Mission of Millat Umidi University?",
                    "faq1 sub": "Our vision is to repeat Global Age of our Nation. And our mission is to create global citizens",
                    "faq2": "2. Does Millat Umidi University offer full scholarship?",
                    "faq2 sub1": "Yes, sure. Requirements for Scholarships offered at MU University",
                    "faq2 sub2": "Academic Achievement Scholarship:",
                    "faq2 sub3": "1. IELTS 7.0 + SAT 1200 + interview = 100 % full scholarship",
                    "faq2 sub4": "2. IELTS 7.5 + interview = 50% scholarship",
                    "faq2 sub5": "3. SAT 1300 + interview = 50% scholarship",
                    "faq2 sub6": "4. IELTS 8.0 + interview = 100% scholarship (for ELT)",
                    "faq3": "3. Is there an acceptance period? Does Millat Umidi University offer winter admission??",
                    "faq3 sub": "The application deadline is August 31st. Millat Umidi University does not offer winter enrollment.",
                    "faq4": "4. What is the start and finish date for the academic year at Millat Umidi University?",
                    "faq4 sub": "The academic year at MU University usually starts early September and finishes late May.",
                    "faq5": "5. Can I pay for my studies in monthly installments?",
                    "faq5 sub": "According to the contract signed between the applicant and MU university, the payment can be carried out through any bank or" +
                        "Click/PayMe mobile payment services. MU University does not accept monthly installments.",
                    "faq6": "6. Can I transfer my study to another university after the first grade of the academic year?",
                    "faq6 sub": "If you are a student at Business Management (International Program – Pearson BTEC integrated) department, you can transfer your study to European or other universities that accept diplomas of Pearson. " +
                        "However, the transfer requirements may differ from university to university.",
                    "faq7": "7. After the registration, how long does Millat Umidi University admission process the applicant’s documents?",
                    "faq7 sub": "The documents are processed within 3-5 business days and the applicant is informed about the results through admission admission2024@millatumidi.uz",
                    "faq8": "8. What should I do after I submit all the required documents?",
                    "faq8 sub": "The applicant is advised to check their e-mails periodically and the applicants with sufficient required documents will be sent a contract.",
                    "faq9": "9. When is the payment due after the contract has been issued?",
                    "faq9 sub": "After the applicant receives the contract, the admission fee must be paid within 10 business days. On condition that 100% tuition fee is covered, the applicant may get a discount.",
                    "faq10": "10. How much does the entry placement test cost?",
                    "faq10 sub": "The entry placement test costs 300 000 UZS. Once the candidate pays the sum he/she can sit the test.",
                    "faq11": "11. How long does it take to preview the entry placement test?",
                    "faq11 sub": "Your entry placement test results will be available within 7 business days. " +
                        "Candidates will be sent the entry test results via e-mail.",
                    "faq12": "12. Does MU University provide students with dormitory?",
                    "faq12 sub": "No, it doesn’t. MU University does not provide students with the dormitory.",
                    "faq13": "13. How long is the Master’s Degree program at MU University?",
                    "faq13 sub": "The duration of MA program is one academic year.",
                    "faq14": "14. What documents are required for MA program at MU University?",
                    "faq14 sub1": "The students need to submit the following documents for MA program:",
                    "faq14 sub2": "1. Copy of passport/ ID",
                    "faq14 sub3": "2. Bachelor’s diploma (module/subject codes must match)",
                    "faq14 sub4": "3. A certificate of language proficiency. (C1 level)",
                    "faq14 sub5": "4. 1 year of teaching experience",
                    "faq15": "15. Does MU University require university dress code/compulsory uniform?",
                    "faq15 sub": "MU University does not require a compulsory uniform. Although there is no “dress code” for tutorials and lectures per se, " +
                        "smart casual dress is strongly advised/She can sit the test.",
                    "faq16": "16. Does MU University offer correspondence education?",
                    "faq16 sub": "Yes, MU University conduct correspondence education.",
                    "faq17": "17. What documents are required for admission?",
                    "faq17 sub1": "The following documents are required for candidates to enter the university:",
                    "faq17 sub2": "- Passport",
                    "faq17 sub3": "- Certificate from school, lyceum, college " +
                        "(if you are going to get a certificate, you need to upload a reference from the place of study)",
                    "faq17 sub4": "- DUOLINGO, CEFR, TOEFL certificates with IELTS 5.5 or higher or equivalent",

                    "make a contact": "Make A Contact",
                    "make a request": "Make A Request",

                    // Partners Section
                    "partners": "Partners",
                    "our partners": "Our Partners",

                    // Admission Section
                    "our Programs": "Our Programs",
                    "requirements": "Requirements",

                    // Scholarship Section
                    "and": " and",
                    "Scholar Tittle": "To support and motivate our students, we offer scholarships:",
                    "Scholar sub": "The Financial Aid Advisory and Grants Committee shall consist of a minimum of 2 faculty members, " +
                        "two academic deans or their designees, the Head of Undergraduate Admissions or a designee. The committee is responsible for developing the overarching policy " +
                        "for awarding undergraduate grants administered by Financial Aid and Grants, including the University's National and Guaranteed Grants. Additionally, Committee members recommend grant scoring formulas, review, and evaluate applications received from students seeking grant consideration.",
                    "Scholar sub 1": "\"You deserve it\" 100% scholarship",
                    "Scholar sub 2": "\"Superhero\" 50% scholarship",
                    "Scholar sub 3": "\"Be Bright\" 50% scholarship",
                    "Scholar sub 4": "Candidates for each grant must be interviewed by members of a special committee.",
                    "Scholar sub 5": "In the first academic year of MU University, it is presented to students with high attendance and academic results throughout the year, regardless of their major. " +
                        "A list of such candidates is formed and interviewed by the members of the special committee.",
                    "Scholar sub 6": "All the above grants are awarded for one academic year only!",


                    // BSC
                    "bsc text": "Software engineers develop and maintain large-scale complex software infrastructures.\n" +
                        "Our programme combines theoretical computing science with the principles and practices used in the modern software industry and\n" +
                        "gives you real world experience. You’ll become skilled in analysing work processes, envisioning complex system architectures,\n" +
                        "producing system specifications, and planning and overseeing complex development projects.",
                    "bsc text 2": "Software engineering graduates are highly employable and can look forward to well-paid careers designing and building\n" +
                        "the digital technologies that underpin the global economy and, indeed, every aspect of human activity from healthcare\n" +
                        "through music to making the natural environment sustainable.",
                    "bsc text 3" : "Software Engineering students acquire a range of valuable skills, which they can transfer to many different employment situations.\n" +
                        "Your skills can be used in IT technician, Database administrator,\n" +
                        "Web developer and the highly competitive fields of Information security analyst and Software engineering.\n" +
                        "You will also gain other skills such as the capacity to analyze and summarize material, to communicate, to work to a deadline, to argue a case,\n" +
                        "to work independently as well as collaboratively, to think logically and to be able to develop different applications.",


                    // BTEC
                    "Pearson BTEC Business": "Business Management (Pearson BTEC)",
                    "btec text" : "With a track record built over 40 years of learner success, our BTEC\n" +
                        "International Business Level 3 qualifications are recognized internationally by\n" +
                        "governments, industry and higher education. BTEC International Level 3\n" +
                        "qualifications allow learners to progress to the workplace – either directly or\n" +
                        "via study at a higher level. Over 100,000 BTEC learners apply to university\n" +
                        "every year. Career-ready education BTECs enable a learner-centered approach to\n" +
                        "education, with a flexible, unit-based structure and knowledge applied to\n" +
                        "project-based assessments. BTECs focus on the holistic development of the\n" +
                        "practical, interpersonal and thinking skills required to be successful in\n" +
                        "employment and higher education.",
                    "btec text 2" : "BTEC is an established brand of choice for the world’s learning communities,\n" +
                        "engaging students in applied, practical, interpersonal and thinking skills for\n" +
                        "more than three decades. The BTEC suite of Pearson qualifications, Higher\n" +
                        "Nationals (HNs) are widely supported by higher education and industry as the\n" +
                        "principal vocational qualifications at Levels 4 and 5. BTEC is one of the\n" +
                        "world's most successful applied learning brands, helping students develop their\n" +
                        "practical, interpersonal and thinking skills for more than 30 years.\n" +
                        "The Pearson BTEC Higher National qualifications in Business are designed to\n" +
                        "reflect the increasing need for high quality professional and technical\n" +
                        "education at levels 4 and 5. They provide students with a clear line of sight to\n" +
                        "employment and to a degree at level 6 if they choose.",
                    "btec text 3" : "Pearson BTEC Higher National qualifications take a student-centered approach to\n" +
                        "the curriculum. There is a flexible, unit-based structure that focuses on\n" +
                        "developing the practical, interpersonal and thinking skills the student will\n" +
                        "need to succeed in employment and higher education. They represent the latest in\n" +
                        "professional standards and provide opportunities for students to develop skills\n" +
                        "and behaviors for work, for example by taking part in a group project or meeting\n" +
                        "a client brief. A student may achieve exemption from professional or vendor\n" +
                        "qualifications, or membership of selected professional organizations to help\n" +
                        "students on their journey to professional recognition or membership.",
                    "btec text 4": "The Pearson BTEC International Level 3 qualifications in Business are primarily\n" +
                        "designed for learners in the 16–19 age group, who wish to pursue a career in\n" +
                        "business, primarily via higher education to access graduate entry employment\n" +
                        "with businesses, or alternatively through junior business employment.",
                    "btec text 5": "Our BTEC Higher Nationals in Business support students in developing the key\n" +
                        "skills, qualities and strengths that employers are looking for. We divide\n" +
                        "employability skills into five main categories:,",

                    // ELT
                    "elt text": "In 2023, an institution was established to provide a comprehensive education\n" +
                        "program spanning over three years, designed to equip students with essential\n" +
                        "skills and knowledge in language teaching and related disciplines. The\n" +
                        "curriculum encompasses a diverse range of subjects aimed at fostering\n" +
                        "proficiency in various facets of language education and pedagogy.",
                    "elt text 2": "The program begins with foundational courses in reading, writing, listening,\n" +
                        "speaking, and public speaking, laying the groundwork for effective\n" +
                        "communication. Students are also introduced to essential computer literacy\n" +
                        "skills, recognizing the significance of technology in modern education.",
                    "elt text 3": "As the program progresses, students delve into linguistics, language learning\n" +
                        "theories, and discourse analysis, gaining insights into the structure and\n" +
                        "function of language. Courses in intercultural competence and language teaching\n" +
                        "methodologies prepare students for the challenges of teaching diverse learners.",
                    "elt text 4": "A significant emphasis is placed on practical teaching skills, including lesson\n" +
                        "planning, classroom management, and materials design, ensuring students are\n" +
                        "well-prepared for real-world teaching environments. Translation principles and\n" +
                        "language assessment techniques are also covered, offering a holistic approach to\n" +
                        "language education.",
                    "elt text 5": "The integration of technology in learning and teaching is highlighted throughout\n" +
                        "the curriculum, acknowledging its importance in modern education practices.\n" +
                        "Additionally, students undergo teaching practice sessions, providing hands-on\n" +
                        "experience under the guidance of experienced educators.",
                    "elt text 6": "One notable aspect of the institution is its faculty, comprising professionals\n" +
                        "from various educational backgrounds, all holding international certifications\n" +
                        "and diplomas. This diverse faculty ensures students receive a well-rounded\n" +
                        "education enriched by the expertise of individuals from different spheres of\n" +
                        "education.",
                    "elt text 7": "Overall, the program aims to produce competent language educators equipped with\n" +
                        "the necessary skills, knowledge, and professionalism to excel in the field of\n" +
                        "language teaching and contribute positively to the global education community.",

                    // ACCA
                    "ACCA text": "Our faculty offers unique ACCA track courses that enable students to sit for\n" +
                        "ACCA exams and obtain nine major certificates. Unlike other universities, we\n" +
                        "provide comprehensive instruction in ACCA track courses, assisting students at\n" +
                        "every step of their journey.\n" +
                        "There is a growing demand for internationally educated specialists proficient in\n" +
                        "multiple languages. With this in mind, we offer English language and\n" +
                        "communication courses to help students enhance their professional communication\n" +
                        "skills and improve reading comprehension.",
                    "ACCA text 2": "Upon graduation, our students are equipped to work at international audit and\n" +
                        "consulting companies in roles such as auditors, financial consultants, and\n" +
                        "financial analysts. In essence, the specialists we cultivate are prepared to\n" +
                        "excel in various industries, serving as managers, bankers, financiers, and\n" +
                        "consultants.\n" +
                        "All we ask in return is your commitment to attend lectures (if you are a daytime\n" +
                        "student), keep an international perspective in mind, and maintain a positive\n" +
                        "attitude. The rest of the work is on us. We look forward to seeing you in our\n" +
                        "classrooms.",

                    //Business Management National
                    "Business National": "Business Management (National Curriculum)",
                    "Business National text": "The course \"Business Management\", operating on the basis of the Faculty of International Business, teaches students to organize, carry out state registration, " +
                        "liquidate and manage global and local corporate structures and small businesses, regardless of ownership forms. Qualified practitioners how to apply strategies, prevent risks, identify the characteristics of various business organizations and the reasons that lead to their success.",
                    "Business National text 2": "At the same time, students of this field will be able to learn how to attract investments, communicate with partners, determine the importance of innovations for business, risks and risks associated with business, be able to plan financial activities of enterprises, " +
                        "manage assets of an enterprise, manage cash, flows and manage expenses. Students are taught the skills of optimizing and maximizing profits, managing financial risks, analyzing business problems and making clear conclusions based on real company cases.",

                    "Testimonials-1": "I am grateful for the efforts put in by Millat Umidi University towards providing quality education and ensuring " +
                        "the overall development of its students. As a second-year computer science student, I have had the opportunity to learn from" +
                        " dedicated faculty members who have always been ready to support and guide us. The university's commitment to innovation and " +
                        "excellence is truly inspiring, motivating me to strive for success in my academic endeavors. The well-equipped labs and " +
                        "libraries have greatly contributed to my academic growth, while the diverse student community has allowed me to interact " +
                        "with individuals from various backgrounds, broadening my perspective extracurricular activities and events organized by" +
                        " the university have enhanced my overall skills, and I am grateful for the opportunities for personal and professional " +
                        "growth that the university offers. I am proud to be a part of such a prestigious institution and confident that the " +
                        "knowledge and skills I acquire here will serve me well in my future career. Thank you, Millat Umidi University, for " +
                        "providing a conducive learning environment and shaping me into a well-rounded and confident individual ready to take" +
                        "on the challenges of the world.",
                    "Testimonials-2": "Hello everyone! I am Rikhsiboyev Abdulaziz, a student of Millat Umidi University, and I am majoring in Applied Accounting" +
                        " and Finance. Before entering Millat Umidi University, I was accepted to many prestigious universities including Westminster," +
                        " British Management University, IMC Krems, Webster, and Presidential University in Uzbekistan on a scholarship basis. " +
                        "But in the end, I chose Millat Umidi, the university that suits my goals. During my very first classes at Millat Umidi University, " +
                        "I was once again convinced that my decision was right. Because in these classes I did not feel tired at all, although the " +
                        "topics were difficult. As well as I learned them with great motivation, and during this study process, I also aimed to " +
                        "share my knowledge with my groupmates, and started creating tutorial videos for them on topics that many people find difficult. " +
                        "The lessons are taught by qualified and open-minded teachers, and we apply the theories learned in the lessons in" +
                        " practice by analyzing the financial statements of real companies am currently a sophomore, and the two years" +
                        " I spent at the university have been very useful for me. Currently, I can analyze the financial reports of real companies " +
                        "without any difficulties, and even if I open my own business in the future, I can independently check the financial reports, " +
                        "and I think this is important for business owners. My goal is to build my career in BIG4, the world's largest audit firm," +
                        " after obtaining several ACCA papers during my university years.",
                    "Testimonials-3": "I am Shakhlo Khasanova, a sophomore at MU university. I am majoring in Business Management Pearson BTEC program which offers a number of opportunities for my future career. There are several reasons why I chose this university among other dozens of international universities in Tashkent. A friendly atmosphere within the university is one of the main reasons, all of the lecturers and professors are always ready to help when we need. This program has been quite useful for me to get not only theoritical, but also practical knowledge in business field. \n" +
                        "Also, all the lessons and courseworks are explained thoroughly and workshops are conducted by teachers so that students don’t face any difficulties. \n" +
                        "\n" +
                        "Another thing that I like about the university is that unlike other universities, we only focus on the most needed and relevant subjects to our chosen major.\n" +
                        "\n" +
                        "Apart from studies, various events like sports competitions, field trips and discussion clubs are organized in the university to make the journey of students more fun and interesting!",
                    "Testimonials-4": "Hello. I am Rukhsora Tuychiyeva. I am the hope of the nation because I am one of the promising students of " +
                        "MILLAT UMIDI UNIVERSITY. I was accepted to the universities of developed countries such as Latvia, Lithuania, " +
                        "and Russia based on a scholarship. But I chose MU University. Today I am very happy that I made the right choice. " +
                        "Today I am studying Business Administration. I also acquired a lot of knowledge in the field of business. " +
                        "Our teachers are very competent, and the teaching processes are organized professionally. " +
                        "A quality education system exists at MU University.",
                    "Testimonials-5": "Hi. I'm Marjona Mirzarakhimova, a student at Millat Umidi University. I'm going to make real comments about " +
                        "MU University. I was accepted to several universities such as UZSWLU, Sharda, British Management University in Tashkent," +
                        " and so on. One of the biggest choices is what university we choose. Being a university student is our first step towards success." +
                        " MU University was the first step in my success journey. My major is English Language Teaching. " +
                        "I love the way of teaching methods here. In my first year, the ELT faculty focused on English language generally, " +
                        "in my second year, we focused on teaching. Our university teaches how to teach our students. I learned a lot in this university, " +
                        "I made so many friends. My social life is the best thing that I can feel. I organize different speaking sessions with our " +
                        "students and for other university students too. Most interestingly, I'm learning Mandarin (Chinese) which makes me " +
                        "the happiest person to speak in foreign languages. The reason why I chose MU University was not because of its beautiful building. " +
                        "I needed a path that would guide me in my ascension, and I found it at MU University, where I could feel like my 2nd home, " +
                        "as my 2nd family. In my first year, I was among the students who won a scholarship. The university has given and continues " +
                        "to give me, the most memorable moments of my life.",

                    "Testimonials-name-1": "Nuribonu Elbekova",
                    "Testimonials-name-2": "Abdulaziz Rikhsiboyev",
                    "Testimonials-name-3": "Shakhlo Khasanova",
                    "Testimonials-name-4": "Rukhsora Tuychiyeva",
                    "Testimonials-name-5": "Marjona Mirzarakhimova",
                }
            },

            uz: {
                translation: {
                    // Header Section
                    "home": "Bosh Sahifa",
                    "about": "Biz haqimizda",
                    "courses": "Fakultetlar",
                    "faq": "Savollar",
                    "contact": "Biz bilan bog'laning",
                    "admission": "Qabul",
                    "pages": "Sahifalar",
                    "scholarship": "Grant",
                    "team": "Jamoa",
                    "event": "Tadbirlar",
                    "apply": "Ariza topshirish",
                    "timetable": "Jadval",

                    // Footer Section
                    "Our Links": "Havolalar",
                    "Careers": "Karyera",
                    "Mission": "Missiya",
                    "Courses": "Fakultetlar",
                    "Undergraduate": "Bakalavr",
                    "Postgraduate": "Magistratura",

                    // Sliderone Section
                    "Years Market Presence": "Yil Bozor Mavjudligi",
                    "Welcome To MU University": "Millat Umidi universitetiga xush kelibsiz",
                    "Become a global citizen with us": "Biz bilan dunyo fuqarosiga aylaning!",
                    "Sliderone sub text": "MUda biz sizni shunchaki o'qitibgina qolmay, o'zingiz xohlagan ishni topishga tayyorlaymiz.\n" +
                        "Eng zo'r bo'lish uchun eng yaxshilardan o'rganing! O'tkazib yubormang va hoziroq ro’yxatdan o’ting!",
                    "Read More": "Batafsil ma'lumot",
                    "Sliderone sub text 2": "MU Universitetida o'qishga va talabalar va bitiruvchilar jamoasiga a'zo bo'lishga imkoniyatni yo'qotmang! Biz sizni kutib qolamiz!",
                    "Bachelors Degree": "Bakalavr darajasi",
                    "Masters Degree": "Magistr darajasi",

                    // About Section
                    "Tittle": "Biz Haqimizda!",
                    "Sub text": "MU universiteti 2021-yilda ochilgan va jahondagi sifatli ta’lim standartlariga javob beradigan zamonaviy " +
                        "ta’lim muassasasi hisoblanadi. Millat Umidi universiteti o‘z talabalariga ixtisoslashtirilgan bilim olish va keyingi ta’lim olish va hayotda o‘z yo‘lini tanlash uchun keng imkoniyatlarni taqdim etadi.",
                    "About Our University": "Bizning Universitet Haqida",
                    "Sub text2": "MU talabalarga ilg'or daraja olishlari uchun sifatli ta'lim dasturlarini taklif etadi.",
                    "Rectors Greeting": "Rektorning salomi",
                    "Dear students": "Hurmatli talabalar,",
                    "Rectors Greeting Text": "Bugungi globallashuv davrida sifatli ta’lim xalqaro eshiklarni ochishda hal qiluvchi rol o‘ynaydi. " +
                        "MU universitetiga oʻqishga kirish orqali siz yuqori malakali professor-oʻqituvchilardan tahsil olish va " +
                        "respublikamizning yetakchi kompaniyalaridan taklif etilgan amaliyotchilar jamoasi bilan muloqot qilish imkoniyatiga ega boʻlasiz. " +
                        "Biz sizni nafaqat o'rgatish, balki yo'lda tinimsiz yordam ko'rsatib, o'zingiz xohlagan ishni olishga tayyorlashga intilamiz. " +
                        "MU’da biz rivojlangan mamlakatlarda qimmatli bo'lgan CELTA, DELTA, ACCA va CERTIPORT kabi tan olingan sertifikatlarni olish imkoniyatini taklif qilamiz. Kelajagingiz uchun poydevor qo'yishni bugundan boshlang va biz bilan global fuqaroga aylaning.",
                    "Welcome to the MU University family": "MU universiteti oilasiga xush kelibsiz, aziz talabalar!",
                    "Dinara Ishmanova" : "Dinara Ishmanova",
                    "Sincerely": "Hurmat bilan,",
                    "University License": "Universitet litsenziyasi",
                    "License Text": "MU universitetining o‘ziga xos xususiyatlari orasida xalqaro e’tirof " +
                        "va akkreditatsiyaga ega bo‘lgan noyob, martaba bilan bog‘liq, yuqori sifatli va inklyuziv ta’lim dasturlari mavjud. " +
                        "Natijada bitiruvchilarimiz dunyoning 300 dan ortiq oliy o‘quv yurtlariga o‘qishga kirish imkoniyatiga ega bo‘ldi. Bundan tashqari, MU turli milliy universitetlar bilan hamkorlik qiladi, " +
                        "bu esa talabalarga o'zlari tanlagan kursga muammosiz o'tish va ikkinchi o'quv yiliga kirish imkonini beradi.",
                    "Overview": "Ko'rib chiqish",
                    "Overview sub": "MU universitetining o'ziga xos xususiyatlari uning noyob martaba yo'nalishi, " +
                        "xalqaro miqyosda tan olingan va akkreditatsiyadan o'tgan yuqori sifatli va inklyuziv ta'lim dasturlari. " +
                        "Bu, o‘z navbatida, bitiruvchilarimizga dunyoning 300 dan ortiq universitetlariga o‘qishga kirish imkonini beradi. Bundan tashqari, " +
                        "MU bir qator milliy universitetlar bilan hamkorlik qiladi, ularda talabalar o'zlari tanlagan kasbining 2-kursida o'qishni davom ettirishlari mumkin.",
                    "Overview sub 2": "MU ning kuchi uning o'qituvchilar tarkibidir. " +
                        "Barcha o‘qituvchilar maxsus tayyorgarlikdan o‘tib, xorijdagi nufuzli universitetlarda tahsil olgan. " +
                        "O‘qituvchilarimiz o‘quvchilarimizning bilimlarini yaxshiroq o‘zlashtirish va salohiyatini rivojlantirish uchun o‘quv jarayoniga har tomonlama yordam berishga tayyor.",
                    "Overview sub 3": "MU, shuningdek, iqtidorli talabalarni o'qish uchun grantlar taqdim etish orqali qo'llab-quvvatlaydi, ularni ta'lim yutuqlarini yanada rivojlantirishga undaydi.",
                    "Our purpose": "Bizning maqsadimiz va qadrimiz",
                    "Our Core Value": "Bizning asosiy maqsadimiz o'tmishdagi Oltin asrni takrorlashdir. ",
                    "Our Core Value sub": "Bizning asosiy maqsadimiz",
                    "Core sub 2": "o'tmishdagi Oltin asrni takrorlashdir. Albatta, O‘rta Osiyoda",
                    "Core sub 3": "va boshqa ko‘plab olimlarimiz borligidan faxrlanamiz",
                    "Core sub 4": "lekin bizning burchimiz ajdodlarimizning munosib avlodi bo‘lishdir. “Millat umidlari”ni o‘zimizning “El umidlari” bilan boshlashni rejalashtirganmiz.",
                    "Core sub 5": "Universitetimizdagi har bir talabaning maqsadi",
                    "Slogan": "- dunyo fuqarosi",
                    "Core sub 6": " bo'lishi kerak. Shunday qilib, MU universitetning har bir bo'limi uchun, xususan, " +
                        "xalqaro miqyosda tan olingan dasturlar bilan hamkorlikni yo'lga qo'ydi, bu talabalarga o'zlari talab qiladigan imtihonlarni topshirgandan so'ng xalqaro darajadagi bilim " +
                        "va sertifikatlarni olish imkonini beradi. Biz talabalarimizning dunyoning nufuzli universitetlariga ko'chirish yoki almashinuv dasturlarida ishtirok etishiga yordam berishdan faxrlanamiz. " +
                        "Bu imkoniyat, ayniqsa, juda muhim, chunki bizning ko'plab o'tmishdoshlarimiz va olimlarimiz yaxshiroq ta'lim olish uchun dunyoning turli burchaklariga sayohat qilishgan va bu ularga jahon miqyosida taniqli olimlar bo'lishga imkon bergan.",
                    "Core sub 7": "Agar sizning maqsadingiz bizning maqsadimizga to'g'ri kelsa, biz sizni universitetimizda ko'rishdan xursandmiz.",


                    // Course Section
                    "Undergraduate Program": "Bakalavriat Dasturi",
                    "Our schools": "Bizning fanlar",
                    "Undergraduate Programs": "Bakalavriat Dasturlari",
                    "Information Technology": "Axborot Texnologiyalari",
                    "business management": "Biznes boshqaruvi",
                    "language": "Til",
                    "finance": "Moliya",
                    "business": "Biznes",
                    "Course Overview": "Kursning Tavsifi",
                    "View Teacher Details": "O'qituvchi ma'lumotlarini ko'rish",
                    "Course Features": "Fakultetning Xususiyatlari",
                    "Full time": "Kunduzgi ta'lim",
                    "Part time": "Sirtqi Ta'lim",
                    "Night time": "Kechki Ta'lim",
                    "Campus": "Kampus",
                    "Lectures": "Darslar",
                    "Duration": "Muddati",
                    "Tuition fee (Per Year)": "Talabalar uchun to'lovlarning yillik miqdori",
                    "Some discount or scholarship may apply": "Ba'zi chegirmalar yoki grantlar qo'llash mumkin",
                    "years": "yill",
                    "Course Details": "Fakultetning tafsilotlari",
                    "critical thinking": "tanqidiy fikrlash",
                    "using expert and creative solutions to solve non-routine problems": "odatiy bo'lmagan muammolarni hal qilish uchun ekspert va ijodiy echimlardan foydalanish",
                    "generating and communicating ideas creatively": "ijodiy g'oyalarni shakllantirish va muloqot qilish",
                    "using systems and digital technology.": "tizimlar va raqamli texnologiyalardan foydalanish.",
                    "self-management": "o'zini o'zi boshqarish",
                    "adaptability and resilience": "moslashuvchanlik va chidamlilik",
                    "self-monitoring and self-development": "o'z-o'zini nazorat qilish va o'z-o'zini rivojlantirish",
                    "reflection, planning and prioritising.": "fikrlash, rejalashtirish va ustuvorliklarni belgilash.",
                    "leadership skills": "yetakchilik qobiliyati",
                    "communicating effectively": "samarali muloqot qilish",
                    "working with others": "boshqalar bilan ishlash",
                    "negotiating and influencing": "gapirish va ta'sir qilish",
                    "presentation skills.": "taqdimot ko'nikmalari.",
                    "awareness of the business sector": "tadbirkorlik sohasi haqida xabardorlik",
                    "sales": "sotish",
                    "marketing and promotion": "marketing va reklama",
                    "managing and monitoring budgets.": "byudjetlarni boshqarish va monitoring qilish.",
                    "awareness of types of companies and legal structures": "kompaniya turlari va huquqiy tuzilmalardan xabardorlik",
                    "invoicing": "hisob-faktura",
                    "presenting financial information": "moliyaviy ma'lumotlarni taqdim etish",
                    "business management.": "Biznes boshqaruvi.",

                    // Subjects
                    "IT": "Axborot texnologiyalari (Dasturiy ta’minot)",
                    "BTEC": "Biznes menejment (Pearson BTEC)",
                    "BM": "Biznes boshqaruvi (Xalqaro biznes)",
                    "M-ELT": "Ingliz tilini o'qitish bo'yicha Magistratura",

                    // Event Section
                    "Our events": "Bizning Tadbirlarimiz",
                    "Upcoming Events": "Yaqinlashib kelayotgan tadbirlar",
                    "event details": "Tadbir Tafsilotlari",

                    "event-title-1": "ACCA ning mintaqaviy direktori o'rtasida o'zaro almashinuv, hamkorlik memorandumi",
                    "event-title-2": "Uyushma a'zolari safi kengaymoqda",
                    "event-title-3": "Rasul Kusherbayev Yaponiya elchisi Hatori Takashi bilan uchrashdi",
                    "event-title-4": "MU universiteti Yaponiya kompaniyalari bilan yangi shartnomalar imzolamoqda.",
                    "event-title-5": "Millat Umidi universiteti Janubiy Koreya Respublikasining Dongseo universiteti bilan hamkorlikni yo'lga qo'yadi!",
                    "event-title-6": "StudentHunter: hamkorlikni kengaytirmoqda.",
                    "event-title-7": "Millat Umidi Universiteti talabalari texno-tur doirasida Artel zavodiga tashrif buyurishdi.",
                    "event-title-8": "Ajoyib yangilik!",
                    "event-title-9": "Millat Umidi universiteti va Portsmut universiteti (UoP) o'rtasidagi navbatdagi hamkorlik",

                    "event-desc-1": "ACCA ning Markaziy Osiyo, Armaniston, Mongoliya mintaqaviy direktori Janna Iskenova va Millat Umidi universiteti o'rtasida o'zaro almashinuv, hamkorlik memorandumi imzolandi.",
                    "event-desc-2": "Kecha Millat umidi universitetiga \"Yosh ziyokor\" uyushmasi a'zoligiga qabul qilingani munosabati bilan a'zolik guvohnomasi topshirildi.\n" +
                        "Tadbirda Uyushma va Universitet hamkorlikda O‘zbekistonga xalqaro yo‘nalishdagi ta'lim grantlarini kengroq jalb etish masalalari muhokama qilindi.\n",
                    "event-desc-3": "“Yosh ziyokor” nodavlat taʼlim tashkilotlari uyushmasi chet el universitetlari va tadqiqot institutlari bilan hamkorlik qilish doirasida qator davlatlar elchixonalari va tashkilotlari bilan aloqalar oʻrnatishni boshladi.",
                    "event-desc-4": "Qiziqarli yangilik! Yaqinda universitetimizda Yaponiya kompaniyasi bilan stajirovka o‘tash, 2+2 qo‘sh diplom dasturlari va boshqa kelishuvlar bo‘yicha uchrashuv bo‘lib o‘tdi.\n" +
                        "Har ikki tomon vakillari hamkorlik va almashuv dasturlari imkoniyatlarini o‘rganish uchun yig‘ildilar.\n" +
                        "Ushbu uchrashuv xalqaro hamkorlikni rivojlantirish, talabalarga qimmatli amaliyot tajribasi va xorijda oliy ta'lim olish imkoniyatini taqdim etishdan iborat edi.\n" +
                        "Ushbu shartnomalarni imzolash orqali biz madaniy almashinuv uchun eshiklarni ochmoqdamiz va talabalarimiz uchun global imkoniyatlar yaratmoqdamiz.\n",
                    "event-desc-5": "2023-2024 o'quv yili uchun Dongseo universiteti bilan ushbu hamkorlik doirasida:\n" +
                        "🔸 2+2 qo‘shma dasturi;\n" +
                        "🔸 Xorijiy professor va talabalar almashinuvi dasturi;\n" +
                        "🔸Koreyaning nufuzli biznes va IT-kompaniyalarida stajirovka qilish imkoniyatlari rejalashtirilgan!\n",
                    "event-desc-6": "Bugun MU universiteti binosida universitet rektori, iqtisod fanlari falsafa doktori (PhD), dotsent Dinora Ishmanova va Action Group tijorat direktori Daniel Hamidovlar ishtirokida uchrashuv bo‘lib o‘tdi.\n" +
                        "Uchrashuvning birinchi qismida universitetda talabalar uchun yaratilgan sharoitlar, moddiy-texnik bazasi va o‘quv jarayonlari bilan yaqindan tanishtirildi.\n" +
                        "Uchrashuvning dastlabki qismida universitet talabalari uchun belgilangan shart-sharoitlar, jumladan, uning jismoniy va texnik infratuzilmasi hamda o‘quv jarayonlarida qo‘llaniladigan pedagogik metodologiyalar bilan to‘liq tanishtirildi.\n" +
                        "Uchrashuvning markaziy qismida StudentHunter platformasi bilan bog'liq istiqbolli rejalar, maqsadlar va istiqbollarni yorituvchi taqdimot bo'ldi. Ushbu taqdimot doirasida, yangi ta'lim istiqbollarini yoritish bilan bir qatorda, ilmiy izlanishlar va ishga joylashish imkoniyatlari uchun ariza berish jarayonida tez-tez namoyon bo'ladigan qiyinchiliklar va to'siqlar bo'yicha muhokamalar o'tkazildi.\n" +
                        "Uchrashuv yakunida rasmiy kelishuv imzolandi, unda har ikki tomon taʼlim sohasida barqaror va oʻzaro manfaatli hamkorlikni yoʻlga qoʻyish majburiyatini bildirishdi.\n",
                    "event-desc-7": "\"Millat Umidi universiteti\" Yaponiyaning \"RINGO Entertainment Inc\" kompaniyasi bilan hamkorlik haqida muzokaralar olib bormoqda. RINGO Entertainment kompaniyasi IT yo'nalishi talabalariga ish imkoniyatlari taqdim etishni maqsad qilmoqda, ta'lim va texnologiya sohasini bog'lamoqda. ",
                    "event-desc-8": "Samarali kengash yig'ilishidan so'ng UoP va Millat Umidi universiteti partnyorlik dasturini boshlaganini mamnuniyat bilan e'lon qilamiz! \n" +
                        "\n" +
                        "Ushbu hamkorlik kengash tomonidan rasmiy ma'qullanib, birgalikda ta'lim sohasida o'z sayohati boshladi. \n" +
                        "Ushbu hamkorlik doirasida universitetimizdagi Pearson BTEC dasturining 3-kursni tamomlagan talabalar endi o‘zlarining martaba istiqbollarini kengaytirish uchun akademik yutuqlaridan foydalangan holda ikki tomonlama diplom olishlari mumkin (ular UoP diplomini olishlari mumkin).\n" +
                        "\n" +
                        "Ushbu hayajonli tashabbusni boshlaganimizda keyingi yangilanishlar uchun bizni kuzatib boring! ",

                    // Testimonial Section
                    "Testimonial": "Guvohlik",
                    "What our students say": "Talabalarimizning fikrlari",

                    // Home FAQ  Section
                    "Frequently asked questions": "Tez-tez so'raladigan savollar",
                    "faq1": "1. Millat Umidi Universitetining maqsadi qanday?",
                    "faq1 sub": "Bizning maqsadimiz - millatimizning global asrini takrorlash. Va bizning vazifamiz global insonlarni yaratishdir",
                    "faq2": "2. Millat Umidi Universiteti to'liq stipendiya taklif qiladimi?",
                    "faq2 sub1": "Ha, shunday. Millat Umidi Universitetida taqdim etiladigan stipendiyalarga qo'yiladigan talablar",
                    "faq2 sub2": "Akademik mukammallik granti:",
                    "faq2 sub3": "1. IELTS 7.0 + SAT 1200 + suhbat = 100 % To'liq stipendiya",
                    "faq2 sub4": "2. IELTS 7.5 + suhbat = 50% stipendiya",
                    "faq2 sub5": "3. SAT 1300 + suhbat = 50% stipendiya",
                    "faq2 sub6": "4. IELTS 8.0 + suhbat = 100% stipendiya (ELT uchun)",
                    "faq3": "3. Qabul qilish muddati bormi? Millat Umidi universiteti ham qishki qabulni taklif qiladimi?",
                    "faq3 sub": "Qabul qilishning oxirgi muddati – 31-avgust. Millat Umidi Universiteti qishki qabulni taklif qilmaydi.",
                    "faq4": "4. Millat Umidi universitetida o'quv yilining boshlanish va tugash sanasi nima?",
                    "faq4 sub": "MU universitetida o'quv yili odatda sentyabr oyining boshida boshlanadi va may oyining oxirida tugaydi.",
                    "faq5": "5. O'qishim uchun kontarktni oylik bo'lib to'lay olamanmi?",
                    "faq5 sub": "Abituriyent va Millat Umidi Universiteti o‘rtasida tuzilgan shartnomaga ko‘ra, to‘lov istalgan bank yoki Click/PayMe mobil" +
                        " to‘lov xizmatlari orqali amalga oshirilishi mumkin. Millat Umidi Universiteti oylik to'lovlarni qabul qilmaydi.",
                    "faq6": "6. O'quv yilining birinchi kursidan keyin o'qishimni boshqa universitetga o'tkaza olamanmi?",
                    "faq6 sub": "Agar siz Biznesni boshqarish (Xalqaro dastur – Pearson BTEC integratsiyalashgan) bo‘limi talabasi bo‘lsangiz," +
                        " o‘qishingizni Yevropa yoki Pearson diplomlarini qabul qiluvchi boshqa universitetlarga o‘tkazishingiz mumkin." +
                        " Biroq, transfer talablari universitetdan universitetga farq qilishi mumkin.",
                    "faq7": "7. Ro‘yxatdan o‘tgandan so‘ng, Millat Umidi universitetiga kirish abituriyentning hujjatlarini qancha muddatda ko‘rib chiqadi?",
                    "faq7 sub": "Hujjatlar 3-5 ish kuni ichida ko'rib chiqiladi va admission2024@millatumidi.uz elektron pochta manzili orqali abituriyent natijalar haqida xabardor qilinadi",
                    "faq8": "8. Barcha kerakli hujjatlarni topshirgandan keyin nima qilishim kerak?",
                    "faq8 sub": "Ariza beruvchiga elektron pochtalarini vaqti-vaqti bilan tekshirib turish tavsiya etiladi va talab qilinadigan " +
                        "hujjatlar yetarli bo'lgan arizachilarga shartnoma yuboriladi.",
                    "faq9": "9. Shartnoma tuzilgandan keyin to'lov qachon amalga oshirilishi kerak?",
                    "faq9 sub": "Ariza beruvchi shartnomani olgandan so'ng, kirish to'lovi 10 ish kuni ichida to'lanishi kerak. " +
                        "100% o'quv to'lovi qoplanishi sharti bilan abituriyent chegirmaga ega bo'lishi mumkin.",
                    "faq10": "10. Ichki imtihon narxi qancha?",
                    "faq10 sub": "Kirish imtihonining narxi 300 000 so'm. Nomzod summani to‘lagandan so‘ng test sinovlarida qatnashishi mumkin.",
                    "faq11": "11. Kirish testini oldindan ko'rish uchun qancha vaqt ketadi?",
                    "faq11 sub": "Kirish imtihoningiz natijalari 7 ish kuni ichida chiqadi. " +
                        "Nomzodlarga kirish imtihoni natijalari elektron pochta orqali yuboriladi.",
                    "faq12": "12. MU universiteti talabalarni yotoqxona bilan ta'minlaydimi?",
                    "faq12 sub": "MU universiteti talabalarni yotoqxona bilan ta'minlamaydi.",
                    "faq13": "13. MU universitetida magistratura dasturi qancha davom etadi?",
                    "faq13 sub": "Talabalar magistratura dasturiga quyidagi hujjatlarni topshirishlari kerak:",
                    "faq14": "14. MU universitetining magistratura bosqichi uchun qanday hujjatlar talab qilinadi?",
                    "faq14 sub1": "Talabalar magistratura dasturiga quyidagi hujjatlarni topshirishlari kerak:",
                    "faq14 sub2": "1. Pasport",
                    "faq14 sub3": "2. Bakalavr diplomi (modul/mavzu kodlari mos kelishi kerak)",
                    "faq14 sub4": "3. Til bilish darajasini tasdiqlovchi sertifikat. (C1 darajasi)",
                    "faq14 sub5": "4. 1 yillik pedagogik tajriba",
                    "faq15": "15. MU universiteti majburiy kiyim qoidasini talab qiladimi?",
                    "faq15 sub": "MU universiteti majburiy kiyim talab qilmaydi. Darslar va ma'ruzalar uchun \"kiyinish kodi\" mavjud bo'lmasa-da, " +
                        "fuqaro kiyimi/yarim klassika ko’rinishida kiyinish tavsiya etiladi.",
                    "faq16": "16. MU universiteti sirtqi ta'limni taklif qiladimi?",
                    "faq16 sub": "Ha, MU universiteti sirtqi ta'limni taklif qiladi.",
                    "faq17": "17. O’qishga kirish uchun qanday hujjatlar talab qilinadi?",
                    "faq17 sub1": "Univeristetga kirish uchun nomzodlarda quyidagi hujjatlar talab qilinadi:",
                    "faq17 sub2": "- Pasport",
                    "faq17 sub3": "- Attestat (attestat endi oladigan bo’lsa, o’qish joyidan ma’lumotnoma yuklash kerak)",
                    "faq17 sub4": "- IELTS 5.5 va undan yuqori yoki shunga teng bo’ladigan DUOLINGO, CEFR, TOEFL sertifikatlari",


                    "First Name": "Ism",
                    "Phone No.": "Telefon raqam",
                    "Write comments": "Fikrlarizi yozing",
                    "make a contact": "Aloqaga Chiqish",
                    "make a request": "So'rovnoma",

                    // Partners Section
                    "partners": "Hamkorlar",
                    "our partners": "Bizning Hamkorlar",

                    // Admission Section
                    "our Programs": "Bizning dasturlarimiz",
                    "requirements": "Talablar",
                    "Documents to apply": "Qabul uchun hujjatlar:",
                    "A copy of passport/ ID card": "Pasport / ID karta nusxasi",
                    "School leaving certificate (with transcript)": "Maktab, letsey, kollejdan attestat (ilovalari bilan)",
                    "3.5x4.5 photo": "3.5x4.5 fotosurat",
                    "Transfer to MU University": "Boshqa oliygohdan MU Universitetiga ko’chirish uchun talab qilinadigan hujjatlar:",
                    "A certificate or diploma indicating that you have completed 1 academic year": "Oldingi Universitetdan akademik o’quv yilini tugatganligingizni tasdiqlovchi sertifikat yoki diplom, baholari bilan",
                    "Transcript and IELTS 6.0": "Transkript va IELTS 6.0",

                    // Scholarship Section
                    "and": " va",
                    "Scholar Tittle": "Talabalarimizni qo'llab-quvvatlash va rag'batlantirish uchun biz Grantlarni taklif qilamiz:",
                    "Scholar sub": "Moliyaviy yordam bo'yicha maslahat va grantlar qo'mitasi kamida 2 nafar professor-o'qituvchilardan, " +
                        "ikkita akademik dekan yoki ularning tayinlangan shaxslaridan, bakalavriatga qabul bo'limi boshlig'idan yoki tayinlangan shaxsdan iborat bo'lishi kerak. " +
                        "Qo'mita Moliyaviy yordam va grantlar, shu jumladan Universitetning milliy va kafolatlangan grantlari tomonidan boshqariladigan bakalavriat grantlarini berish bo'yicha umumiy siyosatni ishlab chiqish uchun mas'uldir. " +
                        "Bundan tashqari, Qo'mita a'zolari grantni baholash formulalarini, grantni ko'rib chiqmoqchi bo'lgan talabalardan olingan arizalarni ko'rib chiqishni va baholashni tavsiya qiladi.",
                    "Academic Excellence": "Akademik Grantlar",
                    "Top Student": "Top student – eng yahshi talaba",
                    "Scholar sub 1": "\"You deserve it\" 100% grant ",
                    "Scholar sub 2": "\"Superhero\" 50% grant ",
                    "Scholar sub 3": "\"Be bright\" 50% grant ",
                    "Scholar sub 4": "Har bir grant uchun nomzodlar maxsus komissiya a'zolari tomonidan suhbatdan o'tishi kerak.",
                    "Scholar sub 5": "MU universitetining birinchi o'quv yilida u qaysi mutaxassislikdan qat'i nazar, " +
                        "yil davomida yuqori davomat va o'quv natijalariga ega bo'lgan talabalarga taqdim etiladi. Bunday nomzodlar ro‘yxati maxsus komissiya a’zolari tomonidan tuziladi va suhbatdan o‘tkaziladi.",
                    "Scholar sub 6": "Yuqoridagi barcha grantlar faqat bir o'quv yili uchun beriladi!",

                    // Bsc
                    "BSc in Software Engineering": "Kompyuter fanlari / dasturiy ta'minot muhandisligi bo'yicha bakalavr",
                    "bsc text": "Dasturiy ta'minot muhandislari keng ko'lamli, murakkab dasturiy ta'minot infratuzilmalarini ishlab chiqadilar va ularga xizmat ko'rsatadilar. " +
                        "Bizning dasturimiz nazariy kompyuter fanini zamonaviy dasturiy ta'minot sanoatida qo'llaniladigan tamoyillar va amaliyotlar bilan birlashtirib, " +
                        "sizga haqiqiy dunyo tajribasini beradi. Siz ish oqimlarini tahlil qilish, murakkab tizim arxitekturasini loyihalash, tizim spetsifikatsiyalarini ishlab chiqish " +
                        "va murakkab rivojlanish loyihalarini rejalashtirish va nazorat qilish bo'yicha ko'nikmalarga ega bo'lasiz.",
                    "bsc text 2": "Dasturiy ta'minot muhandisligi bitiruvchilari yaxshi ish bilan ta'minlangan va global iqtisodiyotni va haqiqatan ham inson faoliyatining barcha jabhalarini, " +
                        "sog'liqni saqlashdan tortib musiqagacha, ekologik barqarorlikni ta'minlaydigan raqamli texnologiyalarni loyihalash va yaratishda yaxshi maoshli martaba kutishlari mumkin.",
                    "Your future career" : "Kelajakdagi kasbingiz",
                    "bsc text 3" : "Dasturiy ta'minot muhandisligi talabalari turli xil ish sharoitlarida qo'llashlari mumkin bo'lgan bir qator qimmatli ko'nikmalarni o'rganadilar. Sizning mahoratingiz IT-mutaxassislari, " +
                        "ma'lumotlar bazasi ma'murlari, veb-ishlab chiquvchilar sohalarida, shuningdek, axborot xavfsizligi tahlili va dasturiy ta'minotni ishlab chiqishning yuqori raqobatbardosh sohalarida qo'llanilishi mumkin. " +
                        "Shuningdek, siz materialni tahlil qilish va sintez qilish, muloqot qilish, belgilangan muddatlarda ishlash, ishni muhokama qilish, mustaqil va hamkorlikda ishlash, mantiqiy fikrlash va turli xil ilovalarni ishlab chiqish kabi boshqa ko'nikmalarga ega bo'lasiz.",


                    // BTEC
                    "Pearson BTEC Business": "Biznesni boshqarish (xalqaro dastur - Pearson BTEC jalb qilingan holda)",
                    "btec text": "40 yildan ortiq muvaffaqiyatli o'qitish tajribasiga ega bo'lgan bizning BTEC International Business " +
                        "Level 3 darajali malakalarimiz hukumatlar, sanoat va oliy ta'lim tomonidan xalqaro miqyosda tan olingan. BTEC International Level 3 darajali malakasi talabalarga to'g'ridan-to'g'ri " +
                        "birinchi kursdan yoki yuqori darajadagi o'qish orqali ish joyiga o'tish imkonini beradi. Har yili 100 000 dan ortiq BTEC talabalari universitetga hujjat topshirishadi. Bizning kasbiy tayyorgarligimiz. " +
                        "BTEC taʼlimga oʻquvchilarga yoʻnaltirilgan yondashuvni taʼminlaydi, moslashuvchan birlikka asoslangan tuzilma va loyiha asosida baholashda qoʻllaniladigan bilimlar. BTEC bandlik va oliy ta'limda muvaffaqiyatga erishish uchun zarur bo'lgan amaliy, " +
                        "shaxslararo va fikrlash qobiliyatlarini har tomonlama rivojlantirishga qaratilgan.",
                    "btec text 2": "BTEC o'ttiz yildan ortiq vaqt davomida talabalarni amaliy, amaliy, shaxslararo munosabatlar va fikrlash qobiliyatlarini rivojlantirishga jalb qiluvchi global ta'lim hamjamiyatlari uchun tan olingan tanlab olingan brenddir. " +
                        "Pearsonning BTEC malakalari toʻplami, Oliy Millatlar (HN) oliy taʼlim va sanoat tomonidan 4 va 5-darajali asosiy kasbiy malaka sifatida keng qoʻllab-quvvatlanadi. BTEC dunyodagi eng muvaffaqiyatli amaliy taʼlim brendlaridan biri boʻlib, " +
                        "talabalarga amaliy shaxslararo munosabatlarni rivojlantirishga yordam beradi. Pearson BTEC Higher National Business Qualifications 4 va 5-bosqichlarda yuqori sifatli kasbiy va texnik ta'limga bo'lgan ehtiyojni qondirish uchun ishlab chiqilgan. " +
                        "Ular talabalarga aniq ishga joylashish istiqbollarini va 6-darajali diplomni taqdim etadilar.",
                    "btec text 3": "Pearson BTEC Oliy Milliy malakalari o'quv dasturiga o'quvchilarga qaratilgan yondashuvga asoslanadi. Talabaning ishga joylashish va oliy ta'limda muvaffaqiyat qozonishi uchun zarur bo'lgan amaliy, shaxslararo va fikrlash qobiliyatlarini rivojlantirishga qaratilgan moslashuvchan, " +
                        "modulli tuzilma mavjud. Ular eng yangi kasbiy standartlarni ifodalaydi va talabalarga ish uchun ko'nikma va xatti-harakatlarni rivojlantirish imkoniyatini beradi, masalan, guruh loyihasida ishtirok etish yoki mijoz topshirig'ini bajarish. Talaba talabalarga kasbiy tan olinishi yoki a'zo bo'lish yo'lida yordam berish uchun professional " +
                        "yoki savdo malakasidan yoki tanlangan professional organlarga a'zolikdan ozod qilishni so'rashi mumkin.",
                    "btec text 4": "Pearson BTEC International Level 3 Biznes boshqaruvi malakalari, birinchi navbatda, biznes sohasida, avvalo oliy ma'lumot orqali, biznesda aspiranturada ishga joylashish yoki, " +
                        "muqobil ravishda, kichik o'quv kurslari orqali ishga kirishni xohlovchi 16-19 yosh guruhidagi talabalarga mo'ljallangan. Kichik biznesda ishlash.",
                    "Why choose Pearson BTEC Higher Nationals?": "Nega Pearson BTEC Higher Nationals’ni tanlaysiz?",
                    "Who are these qualifications for?": "Bu malakalar kimlar uchun?",
                    "What you will learn": "Siz nimani o'rganasiz",
                    "btec text 5": "Bizning BTEC Higher Nationals in Business dasturlarimiz talabalarga ish beruvchilar izlayotgan asosiy ko'nikmalar, fazilatlar va kuchli tomonlarni rivojlantirishga yordam beradi. " +
                        "Biz ish qobiliyatini beshta asosiy toifaga ajratamiz:",
                    "Problem-solving skills These include:": "Muammoni hal qilish qobiliyatlari. Bularga quyidagilar kiradi:",
                    "Independent skills:": "Mustaqil ko'nikmalar:",
                    "Interpersonal skills:" : "Shaxslararo ko'nikmalar:",
                    "Commercial skills" : "Tijorat qobiliyatlari:",
                    "Business skills" : "Biznes ko'nikmalari:",

                    // ELT
                    "ELT": "Ingliz tilini o'qitish bo'yicha bakalavr (CELTA)",
                    "elt text": "2023-yilda talabalarga tillar va turdosh fanlarni o‘rgatish sohasida zarur ko‘nikma va bilimlarni berish uchun mo‘ljallangan uch yillik kompleks ta’lim dasturini amalga oshirish uchun yo’nalish tashkil etildi. O‘quv dasturi til ta’limi va pedagogikaning turli jihatlari bo‘yicha ko‘nikmalarni oshirishga qaratilgan keng ko‘lamli fanlarni o‘z ichiga oladi.",
                    "elt text 2": "Dastur o'qish, yozish, tinglash, nutq va omma oldida nutq so'zlash bo'yicha asosiy kurslardan boshlanadi va samarali muloqot uchun asos yaratadi. " +
                        "Talabalar zamonaviy ta’limda texnologiyaning ahamiyatini anglagan holda kompyuter savodxonligining asosiy ko‘nikmalari bilan ham tanishtiriladi.",
                    "elt text 3": "Talabalar dasturni o‘zlashtirgani sari tilshunoslik, til o‘rganish nazariyalari va nutq tahlilini chuqurroq o‘rganadilar, tilning tuzilishi va funksiyasi haqida tushunchaga ega bo‘ladilar. " +
                        "Madaniyatlararo kompetentsiya va til o'rgatish metodologiyasi kurslari talabalarni turli xil o'quvchilarni o'qitish muammolariga tayyorlaydi.",
                    "elt text 4": "O‘qitishning amaliy ko‘nikmalariga, jumladan, darsni rejalashtirish, sinfni boshqarish va materiallarni loyihalash, o‘quvchilarning real ta’lim muhitiga yaxshi tayyorlanishiga katta e’tibor beriladi. " +
                        "Tarjima tamoyillari va tilni baholash usullari ham yoritilgan boʻlib, til taʼlimiga yaxlit yondashuvni taklif etadi.",
                    "elt text 5": "Texnologiyani ta'lim va o'qitishga integratsiyalashuvi butun o'quv dasturida ta'kidlangan bo'lib, uning zamonaviy ta'lim amaliyotida muhimligini tasdiqlaydi. " +
                        "Bundan tashqari, talabalar tajribali o‘qituvchilar rahbarligida amaliy tajriba orttirib, pedagogik amaliyotdan o‘tadilar.",
                    "elt text 6": "Ta’lim muassasasining e’tiborga molik jihatlaridan biri bu professor-o‘qituvchilar tarkibiga xalqaro sertifikat va diplomlarga ega mutaxassislar kiradi. " +
                        "Bu fakultet talabalarning turli ta’lim sohalaridagi kishilarning tajribalari bilan boyitilgan har tomonlama ta’lim olishlarini ta’minlaydi.",
                    "elt text 7": "Umuman olganda, dastur til o‘rgatish sohasida ustunlikka erishish " +
                        "va jahon ta’lim hamjamiyatiga ijobiy hissa qo‘shish uchun zarur ko‘nikma, bilim va professionallikka ega bo‘lgan malakali til o‘qituvchilarini rivojlantirishga qaratilgan.",

                    // ACCA
                    "ACCA": "Buxgalteriya hisobi va moliya (ACCA)",
                    "ACCA text": "Fakultetimiz talabalarga ACCA imtihonlarini topshirish va to‘qqizta asosiy sertifikat olish imkonini beruvchi noyob ACCA kurslarini taklif etadi. " +
                        "Boshqa universitetlardan farqli o'laroq, biz ACCA kurslari bo'yicha keng qamrovli treninglar o'tkazamiz va talabalarga har qadamda yordam beramiz. Xalqaro miqyosda ma’lumotga ega, bir necha tillarni biladigan mutaxassislarga talab ortib bormoqda. " +
                        "Shularni hisobga olib, biz talabalarning professional muloqot ko‘nikmalarini oshirish va o‘qishni tushunishlarini yaxshilash uchun ingliz tili va muloqot kurslarini taklif qilamiz.",
                    "ACCA text 2": "O‘qishni tugatgandan so‘ng talabalarimiz xalqaro audit va konsalting kompaniyalarida auditor, moliyaviy maslahatchi va moliyaviy tahlilchi kabi lavozimlarda ishlash imkoniyatiga ega bo‘ladilar. " +
                        "Aslida, biz ishlab chiqayotgan mutaxassislar menejerlar, bankirlar, moliyachilar va maslahatchilar bo'lib ishlaydigan turli sohalarda ustunlikka tayyor. Buning evaziga biz faqat ma'ruzalarda qatnashishga (agar siz kunduzgi talaba bo'lsangiz), " +
                        "xalqaro nuqtai nazarni saqlashga va ijobiy munosabatni saqlashga tayyorligingizni so'raymiz. Qolgan ish o'zimizda. Sizni darslarimizda ko'rishdan xursand bo'lamiz.",
                    //Business Management National
                    "Business National": "Biznes boshqaruvi bakalavri (milliy dastur)",
                    "Business National text": "“Xalqaro biznes” fakulteti negizida faoliyat yurituvchi “Biznes menejmenti” kursi talabalarga mulk shaklidan qat’i nazar, global " +
                        "va mahalliy korporativ tuzilmalarni hamda kichik biznes sub’ektlarini tashkil etish, davlat ro‘yxatidan o‘tkazish, tugatish va boshqarishni o‘rgatadi. Malakali amaliyotchilar strategiyalarni qanday qo'llash, " +
                        "risklarning oldini olish, turli biznes tashkilotlarining xususiyatlarini va ularning muvaffaqiyatiga olib keladigan sabablarni aniqlash",
                    "Business National text 2": "Shu bilan birga, ushbu yo‘nalish bo‘yicha talabalar investitsiyalarni jalb etish, hamkorlar bilan muloqot qilish, innovatsiyalarning biznes uchun ahamiyatini aniqlash, biznes bilan bog‘liq risk " +
                        "va xatarlarni aniqlash, korxonalarning moliyaviy faoliyatini rejalashtirish, boshqarish korxona aktivlari, pul oqimlarini boshqarish va xarajatlarni boshqarish. Talabalarga foydani optimallashtirish va maksimallashtirish, " +
                        "moliyaviy risklarni boshqarish, biznes muammolarini tahlil qilish va kompaniyaning haqiqiy holatlari asosida aniq xulosalar chiqarish ko'nikmalari o'rgatiladi.",

                    "Testimonials-1": "“Millat Umidi” universitetining talabalarga sifatli ta’lim berish va har tomonlama rivojlanishini ta’minlash borasidagi sa’y-harakatlari uchun minnatdorchilik " +
                        "bildiraman. Kompyuter fanining ikkinchi kurs talabasi sifatida men har doim bizni qo'llab-quvvatlashga va yo'l-yo'riq berishga tayyor bo'lgan fidoyi " +
                        "professor-o'qituvchilardan o'rganish imkoniyatiga ega bo'ldim. Universitetning innovatsiyalar va mukammallikka sodiqligi haqiqatan ham ilhomlantiradi " +
                        "va meni ilmiy faoliyatimda muvaffaqiyatga erishishga undaydi. Yaxshi jihozlangan laboratoriyalar va kutubxonalar mening ilmiy o‘sishimga katta hissa " +
                        "qo‘shdi, shu bilan birga, turli xil talabalar hamjamiyati menga turli kelib chiqishiga ega bo‘lgan shaxslar bilan muloqot qilishimga imkon berdi, " +
                        "o‘z istiqbolimni kengaytirdi, darsdan tashqari mashg‘ulotlar va universitet tomonidan tashkil etilgan tadbirlar mening umumiy ko‘nikmalarimni" +
                        " oshirdi va men Universitet taqdim etgan shaxsiy va professional o'sish imkoniyatlari uchun minnatdorman. Men shunday nufuzli muassasaning bir " +
                        "bo‘lagi ekanligimdan faxrlanaman va bu yerda olgan bilim va ko‘nikmalarim kelajakdagi faoliyatimda yaxshi xizmat qilishiga ishonaman. " +
                        "Millat Umidi universitetiga, qulay o‘quv muhitini yaratib berganingiz va meni har tomonlama barkamol va o‘ziga ishongan, dunyo qiyinchiliklariga " +
                        "dosh berishga tayyor shaxs sifatida shakllantirganingiz uchun tashakkur.",
                    "Testimonials-2": "Hammaga salom! Men Rixsiboyev Abdulaziz Millat Umidi universitetining Amaliy hisob va moliya mutaxassisligi " +
                        "talabasiman. Millat Umidi universitetiga kirishdan oldin O‘zbekistondagi WIUT, BMU, IMC Krems, Webster va Yangi O'zbekiston " +
                        "universtitetlariga stipendiya asosida qabul qilinganman. Lekin yakunda maqsadlarimga to‘g‘ri keladigan “Milat umidi”" +
                        " universitetini tanladim. “Millat umidi” universitetidagi ilk darslarimda qarorim to‘g‘ri ekanligiga yana bir bor amin bo‘ldim. " +
                        "Chunki bu darslarda mavzular qiyin bo'lsada umuman charchaganim yo'q. Men ularni katta ishtiyoq bilan o‘rganishim bilan birga, " +
                        "ushbu o‘quv jarayonida ham o‘z bilimlarimni guruhdoshlarim bilan bo‘lishishni maqsad qilganman. Men ular uchun, ko'pchilik " +
                        "uchun qiyin bo'lgan mavzularda o'quv videolarini yaratishni boshladim. Darslar malakali va ochiq fikrli o‘qituvchilar" +
                        "tomonidan olib boriladi va biz hozirda 2-kursda o‘qiyotgan real kompaniyalarning moliyaviy hisobotlarini tahlil qilib, " +
                        "darslarda o‘rganilgan nazariyalarni amalda qo‘llaymiz va universitetda o‘tkazgan ikki yilim men uchun juda foydali bo‘ldi. men." +
                        " Hozirda men hech qanday qiyinchiliksiz real kompaniyalarning moliyaviy hisobotlarini tahlil qila olaman va kelajakda o'z " +
                        "biznesimni ochsam ham moliyaviy hisobotlarni mustaqil tekshira olaman va bu biznes egalari uchun muhim deb o'ylayman. " +
                        "Maqsadim – universitet yillarim davomida bir necha ACCA hujjatlarini qo‘lga kiritganimdan so‘ng, dunyodagi eng yirik " +
                        "uditorlik kompaniyasi bo‘lmish BIG4da o‘z kareramni qurish.",
                    "Testimonials-3": "Men Shaxlo Xasanova, MU universitetining ikkinchi kurs talabasiman. Men biznesni boshqarish Pearson BTEC" +
                        " dasturi bo'yicha mutaxassis bo'lib, kelajakdagi karyeram uchun bir nechta imkoniyatlarni taklif qilaman. Toshkentdagi " +
                        "o‘nlab xalqaro universitetlar orasidan ushbu universitetni tanlaganimning bir qancha sabablari bor. Universitetdagi" +
                        " do'stona muhit asosiy sabablardan biri, barcha o'qituvchilar va professorlar bizga kerak bo'lganda yordam berishga " +
                        "doimo tayyor. Ushbu dastur men uchun biznes sohasida nafaqat nazariy, balki amaliy bilimlarni ham olishim uchun " +
                        "juda foydali bo'ldi. Shuningdek, talabalar hech qanday qiyinchilikka duch kelmasliklari uchun barcha darslar va" +
                        " kurs ishlari atroflicha tushuntiriladi, o'qituvchilar tomonidan seminarlar o'tkaziladi. Universitetning yana bir " +
                        "menga yoqadigan jihati shundaki, biz boshqa universitetlardan farqli o'laroq, biz faqat tanlagan mutaxassisligimiz " +
                        "bo'yicha eng kerakli va tegishli fanlarga e'tibor qaratamiz. Universitetda talabalarning sayohatini yanada qiziqarli " +
                        "va qiziqarli qilish uchun o'qishdan tashqari sport musobaqalari, sayohatlar va muhokama klublari kabi turli tadbirlar " +
                        "tashkil etiladi!",
                    "Testimonials-4": "Salom. Men Ruxsora Tuychiyevaman. Men millat umidiman, chunki men MILLAT UMIDI UNIVERSITETIning umidli " +
                        "talabalaridan biriman. Latviya, Litva, Rossiya kabi rivojlangan davlatlarning universitetlariga stipendiya asosida qabul " +
                        "qilindim. Lekin men MU universitetini tanladim. Bugun men to'g'ri tanlov qilganimdan juda xursandman. Bugun men " +
                        "biznes boshqaruvi bo'yicha o'qiyapman. Biznes sohasida ham ko'p bilimlarga ega bo'ldim. O‘qituvchilarimiz juda malakali, " +
                        "o‘quv jarayonlari professional tarzda tashkil etilgan. MU universitetida sifatli ta'lim tizimi mavjud.",
                    "Testimonials-5": "Salom. Men Marjona Mirzarahimova, Millat Umidi universiteti talabasiman. Men MU universiteti haqida haqiqiy " +
                        "fikr bildirmoqchiman. OʻzDJTU, Sharda, Toshkentdagi Britaniya menejment universiteti va hokazo kabi bir qancha universitetlarga " +
                        "oʻqishga qabul qilindim. Eng katta tanlovlardan biri bu qaysi universitetni tanlashimizdir. Universitet talabasi" +
                        " bo'lish muvaffaqiyat sari birinchi qadamimizdir. MU universiteti mening muvaffaqiyat yo'limdagi birinchi qadam edi. " +
                        "Mutaxassisligim ingliz tili o'qituvchisi. Menga bu erda o'qitish usullari yoqadi. Birinchi yilimda ELT fakulteti asosan " +
                        "ingliz tiliga e'tibor qaratgan bo'lsa, ikkinchi kursimda biz dars berishga e'tibor qaratdik. Universitetimiz talabalarimizga " +
                        "qanday o'qitishni o'rgatadi. Men bu universitetda ko'p narsalarni o'rgandim, juda ko'p do'stlar orttirdim." +
                        " Mening ijtimoiy hayotim men his qila oladigan eng yaxshi narsadir. Men talabalarimiz va boshqa universitet " +
                        "talabalari uchun turli xil nutq sessiyalarini tashkil qilaman. Eng qizig'i, men mandarin (xitoy) tilini o'rganyapman," +
                        " bu menga juda yoqadi. MU universitetini tanlashimga uning go'zal binosi emas edi. Menga ko'tarilishimda yo'l " +
                        "ko'rsatadigan yo'l kerak edi va men uni MU universitetida topdim, u erda o'zimni ikkinchi uyim, ikkinchi oilamdek his " +
                        "qila olaman. Birinchi kursimda stipendiya yutib olgan talabalar qatorida edim. Universitet menga hayotimdagi eng " +
                        "esda qolarli lahzalarni berdi va berishda davom etmoqda.",

                    "Testimonials-name-1": "Nuribonu Elbekova",
                    "Testimonials-name-2": "Abdulaziz Rihsiboyev",
                    "Testimonials-name-3": "Shaxlo Hasanova",
                    "Testimonials-name-4": "Ruxsora Tuychiyeva",
                    "Testimonials-name-5": "Marjona Mirzarahimova",
                }
            },

            ru: {
                translation: {
                    // Header Section
                    "home": "Главная Страница",
                    "about": "О нас",
                    "courses": "Факультеты",
                    "faq": "ЧаВо",
                    "contact": "Свяжитесь с нами",
                    "admission": "Поступление",
                    "pages": "Страницы",
                    "scholarship": "Стипендия",
                    "team": "Сотрудники",
                    "apply": "Подать заявку",
                    "timetable": "Расписание",

                    // Footer Section
                    "Our Links": "Наши ссылки",
                    "Careers": "Карьера",
                    "Mission": "Миссия",
                    "Courses": "Факультеты",
                    "Undergraduate": "Бакалавриат",
                    "Postgraduate": "Магистратура",

                    // Sliderone Section
                    "Years Market Presence": "лет на рынке",
                    "Welcome To MU University": "Добро пожаловать в Университет Миллат Умиди",
                    "Become a global citizen with us": "Станьте гражданином мира вместе с нами!",
                    "Sliderone sub text": "В Университете MU мы не просто обучаем вас, мы готовим вас к поиску работы, которую вы хотите. И поддержим вас и ваше будущее! Чтобы быть лучшим, учитесь у лучших! Не пропустите шанс и зарегистрируйтесь в университете MU сегодня!",
                    "Read More": "Узнать больше",
                    "Sliderone sub text 2": "Не упустите возможность учиться в MU University и стать частью нашего сообщества студентов и выпускников! Мы ждем вас!",
                    "Bachelors Degree": "Степень бакалавра",
                    "Masters Degree": "Cтепень магистра",

                    // About Section
                    "Rectors Greeting": "Приветствие ректора",
                    "Tittle": "Несколько слов об университете",
                    "Sub text": "MU University – современное учебное заведение, которое открылось в 2021 году " +
                        "и отвечающее стандартам качества образования в мире. Университет Миллат Умиди дает своим студентам возможность получить профильные знания " +
                        "и широкие возможности для дальнейшего обучения и выбора собственного пути в жизни.",
                    "About Our University": "О нашем университете",
                    "Sub text2": "Университет MU предлагает качественные образовательные программы студентам для получения ученых степеней.",
                    "Dear students": "Дорогие студенты,",
                    "Rectors Greeting Text":
                        "В сегодняшнюю эпоху глобализации качественное образование играет решающую роль в открытии международных дверей. " +
                        "Поступив в Университет MU, вы получите возможность учиться у высоко квалификационных профессоров и общаться с командой приглашенных практиков из ведущих компаний нашей республики. " +
                        "Мы стремимся не только научить вас, но и подготовить вас к получению желаемой работы, оказывая непоколебимую поддержку на этом пути. В университете MU мы предлагаем возможность получить признанные сертификаты, такие как CELTA, DELTA, ACCA и CERTIPORT, " +
                        "которые имеют ценность в развитых странах. Начните закладывать фундамент своего будущего уже сегодня и станьте гражданином мира вместе с нами.",
                    "Welcome to the MU University family": "Добро пожаловать в семью университета MU, дорогие студенты!",
                    "Sincerely": "Искренне,",
                    "Dinara Ishmanova": "Динара Ишманова",
                    "University License": "Лицензия университета",
                    "License Text": "Отличительными особенностями MU University являются уникальные, карьерно-релевантные, " +
                        "качественные и инклюзивные образовательные программы, получившие международное признание и аккредитацию. " +
                        "В результате наши выпускники имеют возможность поступить в более чем 300 университетов по всему миру. Кроме того, университет MU сотрудничает с различными национальными университетами, " +
                        "что позволяет студентам беспрепятственно перейти на выбранную ими направление и поступить на второй год обучения.",
                    "Overview": "Обзор",
                    "Overview sub": "Отличительными особенностями Университета MU являются уникальные профориентационные, " +
                        "качественные и инклюзивные образовательные программы, признанные и аккредитованные на международном уровне. Это, в свою очередь, " +
                        "позволяет нашим выпускникам поступать более чем в 300 университетов по всему миру. Кроме того, MU сотрудничает с рядом национальных университетов, " +
                        "где студенты могут продолжить обучение, поступив сразу на 2 курс выбранной профессии.",
                    "Overview sub 2": "Сильная сторона Университета MU – это его преподавательский состав. " +
                        "Все преподаватели прошли профильное обучение и обучались в престижных университетах за рубежом. " +
                        "Наши преподаватели готовы оказать всестороннюю поддержку в учебном процессе для лучшего усвоения знаний и развития потенциала наших студентов.",
                    "Overview sub 3": "Университет MU также поддерживает талантливых студентов, предоставляя стипендии на обучение, мотивируя их на дальнейшие достижения в образовании.",
                    "Our purpose": "Наша цель и ценность",
                    "Our Core Value": "Наша основная цель — повторить Золотой век, который был у нас в прошлом",
                    "Our Core Value sub": "Наша основная ценность",
                    "Core sub 2": "повторить Золотой век, который был у нас в прошлом. Конечно, мы гордимся тем, что у нас есть такие учёные,",
                    "Core sub 3": "и многие другие учёные, жившие в Средней Азии ",
                    "Core sub 4": "но нашим долгом является быть достойным потомком наших предков. Мы планируем начать с нашего Миллат Умидлари (Надежда нации).",
                    "Core sub 5": "Целью каждого студента нашего университета должна быть",
                    "Slogan": " - стать гражданином мира ",
                    "Core sub 6": ". Таким образом, MU установлено сотрудничество для каждого факультета университета, " +
                        "в частности, с признанными во всем мире программами, что дает студентам получить знания и сертификаты международного уровня после сдачи необходимых ими экзаменов.  " +
                        "Мы гордимся тем, что способствуем участию наших студентов в программах перевода или обмена в престижные университеты мира. Эта возможность особенно важна, поскольку многие из наших предшественников " +
                        "и ученых много путешествовали по различным частям мира в поисках лучшего образования, что позволило им стать выдающимися учеными в глобальном масштабе.",
                    "Core sub 7": "Если ваша цель совпадает с нашей целью, мы рады видеть вас в нашем университете.",

                    // Course Section
                    "Undergraduate Program": "Программа бакалавриата",
                    "Our schools": "Наши школы",
                    "Undergraduate Programs": "Программы бакалавриата",
                    "Information Technology": "Информационные Технологии",
                    "business management": "Управление Бизнесом",
                    "language": "Язык",
                    "finance": "Финанс",
                    "business": "Бизнес",
                    "Course Overview": "Обзор курса",
                    "View Teacher Details": "Просмотр сведений об учителе",
                    "Course Features": "Особенности курса",
                    "Full time": "Очное обучение",
                    "Part time": "Заочное обучение",
                    "Night time": "Вечернее обучение",
                    "Campus": "Кампус",
                    "Lectures": "Лекции",
                    "Duration": "Длительность",
                    "Tuition fee (Per Year)": "Стоимость обучения (В год)",
                    "Some discount or scholarship may apply": "Может применяться некоторая скидка или стипендия",
                    "years": "года",
                    "Course Details": "Подробности курса",
                    "critical thinking": "критическое мышление",
                    "using expert and creative solutions to solve non-routine problems": "использование экспертных и творческих решений для решения нестандартных задач",
                    "generating and communicating ideas creatively": "творчески генерировать и передавать идеи",
                    "using systems and digital technology.": " использованием систем и цифровых технологий.",
                    "self-management": "самоуправление",
                    "adaptability and resilience": "адаптивность и устойчивость",
                    "self-monitoring and self-development": "самоконтроль и саморазвитие",
                    "reflection, planning and prioritising.": "размышление, планирование и расстановка приоритетов.",
                    "leadership skills": "лидерские качества",
                    "communicating effectively": "эффективное общение",
                    "working with others": "работа с другими",
                    "negotiating and influencing": "ведение переговоров и влияние",
                    "presentation skills.": "навыки презентации.",
                    "awareness of the business sector": "осведомленность о бизнес-секторе",
                    "sales": "продажи",
                    "marketing and promotion": "маркетинг и продвижение",
                    "managing and monitoring budgets.": "управление и контроль бюджетов.",
                    "awareness of types of companies and legal structures": "осведомленность о типах компаний и юридических структурах",
                    "invoicing": "выставление счетов",
                    "presenting financial information": "представление финансовой информации",
                    "business management.": "управление бизнесом.",

                    // Subjects
                    "IT": "Компьютерные науки (программная инженерия)",
                    "BTEC": "Управление бизнесом (Pearson BTEC)",
                    "BM": "Управление бизнесом (Национальная программа)",
                    "M-ELT": "Магистр искусств в области образования по английскому языку",

                    // Event Section
                    "Our events": "Наши Мероприятия",
                    "Upcoming Events": "Предстоящие События",
                    "event details": "Подробности о событии",

                    "event": "Событие",
                    "event-title-1": "Меморандум об обмене и сотрудничестве между региональным директором ACCA",
                    "event-title-2": "Число членов ассоциации расширяется",
                    "event-title-3": "Расул Кушербаев встретился с послом Японии Хатори Такаши",
                    "event-title-4": "Университет MU подписывает новые контракты с японскими компаниями.",
                    "event-title-5": "Университет Миллат Умиди начинает сотрудничество с Университетом Dongseo Южной Кореи!",
                    "event-title-6": "StudentHunter: к расширению партнёрства.",
                    "event-title-7": "Студенты Университета Миллат Умиди посетили Артел в рамках техно-тура.",
                    "event-title-8": "Отличные новости!",
                    "event-title-9": "Захватывающее объявление о сотрудничестве между Университетом Миллат Умиди и Портсмутским университетом (UoP)",

                    "event-desc-1": "Меморандум о взаимном обмене и сотрудничестве был подписан между региональным директором АССА по Центральной Азии, Армении, Монголии Жанной Искеновой и Университетом Миллат Умиди.",
                    "event-desc-2": "Вчера Университету Миллат Умид было вручено членское удостоверение в связи с принятием его в члены ассоциации «Йош Зиякор».\n" +
                        "На мероприятии Ассоциация и университет обсудили вопросы более широкого привлечения международных образовательных грантов в Узбекистан.\n",
                    "event-desc-3": "Ассоциация неправительственных образовательных организаций «Ёш Зёкор» приступила к установлению связей с посольствами и организациями ряда стран в рамках сотрудничества с зарубежными университетами и научно-исследовательскими институтами.",
                    "event-desc-4": "Волнующие новости! Недавно в нашем университете прошла встреча с японской компанией, на которой обсуждались стажировки, программы двойных дипломов 2+2 и другие соглашения.\n" +
                        "Представители обеих сторон собрались вместе, чтобы изучить возможности сотрудничества и программ обмена.\n" +
                        "Целью этой встречи было развитие международного партнерства, предоставление студентам ценного опыта стажировок и возможности получить высшее образование за рубежом.\n" +
                        "Заключая эти соглашения, мы открываем двери для культурного обмена и создаем глобальные возможности для наших студентов.\n",
                    "event-desc-5": "В рамках данного партнерства с Университетом Тонсо на 2023–2024 учебный год:\n" +
                        "🔸Объединенная программа обучения 2+2;\n" +
                        "🔸Программа обмена иностранными профессорами и студентами;\n" +
                        "🔸Планируются возможности стажировок в престижных корейских бизнес- и IT-компаниях!\n",
                    "event-desc-6": "Сегодня в здании МУ Университета прошла встреча с участием ректора университета, доктора философии экономических наук (PhD), доцента Диноры Ишмановой и коммерческого директора Action Group Даниэля Хамидова.\n" +
                        "В первой части встречи вуз подробно ознакомили с условиями, созданными для студентов, его материально-технической базой и процессами обучения.\n" +
                        "В ходе первой части встречи университет прошел всестороннее ознакомление с условиями, созданными для его студенческого контингента, включая проверку его физической и технической инфраструктуры, а также педагогических методологий, используемых в образовательном процессе.\n" +
                        "В центральной части встречи прошла презентация, посвященная перспективным планам, целям и перспективам, связанным с платформой StudentHunter. В контексте этой презентации, помимо освещения новых перспектив образования, были проведены обсуждения проблем и препятствий, которые обычно проявляются в процессе подачи заявления на академическую деятельность и возможности трудоустройства.\n" +
                        "По итогам встречи было подписано официальное соглашение, в котором обе стороны выразили готовность участвовать в устойчивом и взаимовыгодном сотрудничестве в сфере образования.\n",
                    "event-desc-7": "«Университет Миллат Умиди» ведет переговоры с японской компанией «RINGO Entertainment Inc.». " +
                        "для потенциального сотрудничества. RINGO Entertainment стремится предложить нашим студентам-ИТ-студентам интересные" +
                        " возможности трудоустройства, соединяя образование с технологической индустрией.",
                    "event-desc-8": "Мы рады сообщить, что после продуктивного заседания правления UoP и Университет Миллат Умиди запустили программу пополнения счета! \n" +
                        "\n" +
                        "Это сотрудничество получило официальное одобрение совета директоров, что ознаменовало начало плодотворного совместного пути. \n" +
                        "\n" +
                        "В рамках этого сотрудничества студенты, завершившие 3-й курс программы Pearson BTEC в нашем университете, теперь могут получить двойную степень (они смогут получить диплом UoP), используя свои академические достижения для расширения своих карьерных перспектив.\n" +
                        "\n" +
                        "Следите за обновлениями, пока мы отправляемся в это захватывающее приключение! ",


                    // Testimonial Section
                    "Testimonial": "Свидетельство",
                    "What our students say": "Мнение наших студентов",

                    // Home FAQ  Section
                    "Frequently asked questions": "Часто задаваемые вопросы",
                    "faq1": "1. Какова миссия Университета Миллат Умиди?",
                    "faq1 sub": "Наше видение – повторить Глобальную эпоху нашей нации. И наша миссия – воспитывать граждан мира.",
                    "faq2": "2. Предлагает ли Университет Миллат Умиди полную стипендию?",
                    "faq2 sub1": "Да, конечно. Требования к стипендиям, предлагаемым в Университете Миллат Умиди:",
                    "faq2 sub2": "Стипендия за академические достижения:",
                    "faq2 sub3": "1. IELTS 7.0 + SAT 1200 + собеседование = 100% полная стипендия",
                    "faq2 sub4": "2. IELTS 7.5 + собеседование = стипендия 50%",
                    "faq2 sub5": "3. SAT 1300 + собеседование = стипендия 50%",
                    "faq2 sub6": "4. IELTS 8.0 + собеседование = 100% стипендия (для ELT)",
                    "faq3": "3. Есть ли период принятия? Предлагает ли университет Миллат Умиди зимний прием?",
                    "faq3 sub": "Крайний срок подачи заявок – 31 августа. Университет Миллат Умиди не предлагает зимний набор.",
                    "faq4": "4. Какова дата начала и окончания учебного года в Университете Миллат Умиди?",
                    "faq4 sub": "Учебный год в MU обычно начинается в начале сентября и заканчивается в конце мая.",
                    "faq5": "5. Могу ли я оплатить контракт на обучение ежемесячными платежами?",
                    "faq5 sub": "Согласно договору между абитуриентом и Университетом Миллат Умиди, оплата может быть произведена " +
                        "через любой банк или мобильные платежные сервисы Click/PayMe. Университет Миллат Умиди не принимает ежемесячные платежи.",
                    "faq6": "6. Могу ли я после первого курса учебного года перевестись в другой вуз?",
                    "faq6 sub": "Если вы учитесь на факультете делового администрирования (международная программа Pearson BTEC Integrated), " +
                        "вы можете перевести свое обучение в Европу или другие университеты, принимающие степени Pearson. " +
                        "Однако требования к переводу могут варьироваться от университета к университету.",
                    "faq7": "7. Как долго после регистрации в Университет Миллат Умиди оформляются документы абитуриента?",
                    "faq7 sub": "Документы будут рассмотрены в течение 3-5 рабочих дней, о результатах заявитель будет уведомлен по электронной почте admission2024@millatumidi.uz",
                    "faq8": "8. Что мне делать после подачи всех необходимых документов?",
                    "faq8 sub": "Кандидатам рекомендуется время от времени проверять свою электронную почту, и контракт будет отправлен заявителям, имеющим достаточное количество необходимых документов.",
                    "faq9": "9. Когда должна быть произведена оплата после заключения договора?",
                    "faq9 sub": "После получения заявителем договора вступительный взнос должен быть оплачен в течение 10 рабочих дней. Заявитель может иметь право на скидку при условии 100% возмещения стоимости обучения.",
                    "faq10": "10. Сколько стоит внутренний экзамен?",
                    "faq10 sub": "Вступительный экзамен стоит 300 000 сумов. После оплаты суммы кандидат может участвовать в тестировании.",
                    "faq11": "11. Сколько времени занимает просмотр вступительного теста?",
                    "faq11 sub": "Результаты вступительного теста будут доступны в течение 7 рабочих дней. " +
                        "Результаты вступительного теста кандидатам будут отправлены по электронной почте.",
                    "faq12": "12. Предоставляет ли Университет MU студентам общежитие?",
                    "faq12 sub": "Нет. Университет MU не предоставляет студентам общежитие.",
                    "faq13": "13. Какова продолжительность программы магистратуры в Университете MU?",
                    "faq13 sub": "Продолжительность программы магистратуры составляет один учебный год.",
                    "faq14": "14. Какие документы необходимы для поступления магистратуру в Университете МУ?",
                    "faq14 sub1": "Студентам необходимо предоставить следующие документы для программы магистратуры:",
                    "faq14 sub2": "1. Копия паспорта/удостоверения личности.",
                    "faq14 sub3": "2. Диплом бакалавра (коды модулей/предметов должны совпадать)",
                    "faq14 sub4": "3. Сертификат о знании языка. (уровень С1)",
                    "faq14 sub6": "4. Опыт преподавания 1 год.",
                    "faq15": "15. Требует ли университет MU дресс-кода/обязательной униформы?",
                    "faq15 sub": "Университет MU не требует обязательной униформы. " +
                        "Хотя на посещение занятий и лекций не существует «дресс-кода», " +
                        "но настоятельно рекомендуется одеваться в элегантном повседневном стиле.",
                    "faq16": "16. Предлагает ли Университет MU заочное обучение?",
                    "faq16 sub": "Да, Университет MU имеет заочное обучение.",
                    "faq17": "17. Какие документы необходимы для поступления?",
                    "faq17 sub1": "Кандидатам для поступления в университет необходимы следующие документы:",
                    "faq17 sub2": "- Паспорт",
                    "faq17 sub3": "- Аттестат (если вы собираетесь  только получить, необходимо загрузить справку с места учебы)",
                    "faq17 sub4": "- IELTS 5.5 или выше или сертификаты DUOLINGO, CEFR, TOEFL эквивалентные уровню IELTS 5.5 и выше.",


                    "First Name": "Имя",
                    "Phone No.": "Номер телефона",
                    "Write comments": "Написать комментарии",

                    "make a contact": "Обратная связь",
                    "make a request": "Отправить",

                    // FAQ  Section

                    // Partners Section
                    "partners": "Партнёры",
                    "our partners": "Наши Партнёры",

                    // Admission Section
                    "our Programs": "Наши программы",
                    "requirements": "Требования",
                    "Documents to apply": "Документы для подачи заявления",
                    "A copy of passport/ ID card": "Копия паспорта/удостоверения личности.",
                    "School leaving certificate (with transcript)": "Аттестат об окончании школы (с оценками)",
                    "3.5x4.5 photo": "Фотография 3,5х4,5 пикселей",
                    "Transfer to MU University": "Перевод в университет MU",
                    "A certificate or diploma indicating that you have completed 1 academic year": "Сертификат или диплом, подтверждающий, что вы окончили 1 курс в высшем учебном заведении.",
                    "Transcript and IELTS 6.0": "Транскрипт и IELTS 6.0.",

                    // Scholarship Section
                    "and": " и",
                    "Scholar Tittle": "Чтобы поддержать и мотивировать наших студентов, мы предлагаем стипендии:",
                    "Scholar sub": "Консультативный комитет по финансовой помощи и грантам должен состоять как минимум из двух преподавателей, " +
                        "двух академических деканов или их уполномоченных лиц, руководителя приемной комиссии бакалавриата или уполномоченного лица. " +
                        "Комитет отвечает за разработку всеобъемлющей политики присуждения грантов бакалавриата в рамках финансовой помощи и грантов, " +
                        "включая национальные и гарантированные университетские гранты. Кроме того, члены комитета рекомендуют формулы оценки грантов, рассматривают и оценивают заявки, " +
                        "полученные от студентов, претендующих на получение гранта.",
                    "Academic Excellence": "Академическое мастерство",
                    "Scholar sub 1": "100% стипендия «Вы это заслужили» ",
                    "Scholar sub 2": "Стипендия «Супергерой» 50%",
                    "Scholar sub 3": "Стипендия «Будь ярким» 50%",
                    "Scholar sub 4": "Кандидаты на каждый грант должны пройти собеседование с членами специального комитета.",
                    "Scholar sub 5": "В первый учебный год MU University вручает студентам с высокой посещаемостью и академическими результатами в течение года, " +
                        "независимо от специальности. Список таких кандидатов формируется и опрашивается членами специальной комиссии.",
                    "Scholar sub 6": "Все вышеуказанные гранты присуждаются только на один учебный год!",

                    //BSc in Software Engineering
                    "BSc in Software Engineering": "Бакалавр в области компьютерных наук (программной инженерии)",
                    "bsc text": "Инженеры-программисты разрабатывают и поддерживают крупномасштабные сложные программные инфраструктуры. " +
                        "Наша программа сочетает в себе теоретическую информатику с принципами и практиками, используемыми в современной индустрии программного обеспечения, " +
                        "и дает вам реальный опыт. Вы приобретете навыки анализа рабочих процессов, проектирования сложных системных архитектур, разработки системных спецификаций, " +
                        "а также планирования и контроля сложных проектов разработки.",
                    "bsc text 2": "Выпускники программной инженерии имеют хорошие возможности трудоустройства и могут рассчитывать на хорошо оплачиваемую карьеру в области проектирования и создания цифровых технологий, " +
                        "которые лежат в основе мировой экономики и, по сути, всех аспектов человеческой деятельности, от здравоохранения до музыки и обеспечения устойчивости окружающей среды. ",
                    "Your future career": "Ваша будущая карьера",
                    "bsc text 3": "Студенты-разработчики программного обеспечения приобретают ряд ценных навыков, которые они могут применять в самых разных ситуациях на работе. Ваши навыки могут быть использованы в сфере ИТ-специалистов, " +
                        "администраторов баз данных, веб-разработчиков, а также в высококонкурентных областях аналитико- информационной безопасности и разработки программного обеспечения. Вы также приобретете другие навыки, такие как способность анализировать " +
                        "и обобщать материал, общаться, работать в установленные сроки, аргументировать дело, работать независимо и совместно, мыслить логически и уметь разрабатывать различные приложения.",


                    // Pearson BTEC Business
                    "Pearson BTEC Business": "Управление бизнесом (международная программа – интегрированная программа Pearson BTEC)",
                    "btec text": "Благодаря более чем 40-летнему опыту успешного обучения наши квалификации BTEC International Business Level 3 признаны на международном уровне правительствами, " +
                        "промышленностью и высшим образованием. Квалификация BTEC International Level 3 позволяет учащимся перейти на рабочее место – либо напрямую с первого курса, " +
                        "либо через обучение на более высоком уровне. Ежегодно более 100 000 студентов BTEC подают документы в университет. Наше обучение, готовое к карьере. BTEC обеспечивает подход к образованию, " +
                        "ориентированный на учащихся, с гибкой структурой, основанной на единицах, и знаниями, применяемыми для оценки на основе проектов. BTEC сосредоточены на комплексном развитии практических, межличностных и мыслительных навыков, необходимых для достижения успеха в трудоустройстве и получении высшего образования.",
                    "btec text 2": "BTEC — признанный бренд, который выбирают мировые учебные сообщества, уже более трех десятилетий привлекая студентов к развитию прикладных, практических, межличностных и мыслительных навыков. Набор квалификаций Пирсона BTEC, Higher Nationals (HN), " +
                        "широко поддерживается высшим образованием и промышленностью в качестве основных профессиональных квалификаций на уровнях 4 и 5. BTEC — один из самых успешных в мире брендов прикладного обучения, помогающий студентам развивать свои практические навыки межличностного общения. и навыки мышления более 30 лет. " +
                        "Высшие национальные бизнес-квалификации Pearson BTEC разработаны с учетом растущей потребности в высококачественном профессиональном и техническом образовании на уровнях 4 и 5. Они предоставляют студентам четкую перспективу трудоустройства и получения степени на уровне 6, если они того пожелают.",
                    "btec text 3": "Высшие национальные квалификации Pearson BTEC основаны на подходе к учебной программе, ориентированном на учащихся. Существует гибкая модульная структура, ориентированная на развитие практических, межличностных и мыслительных навыков, необходимых студенту для достижения успеха при трудоустройстве и получении высшего образования. " +
                        "Они представляют собой новейшие профессиональные стандарты и предоставляют студентам возможность развивать навыки и поведение для работы, например, принимая участие в групповом проекте или выполняя задание клиента. Студент может добиться освобождения от профессиональной квалификации или квалификации продавца или членства в выбранных профессиональных организациях, " +
                        "чтобы помочь студентам на пути к профессиональному признанию или членству.",
                    "btec text 4": "Квалификации Pearson BTEC International Level 3 в области бизнеса в первую очередь предназначены для учащихся в возрастной группе 16–19 лет, " +
                        "которые хотят продолжить карьеру в бизнесе, в первую очередь через высшее образование, чтобы получить доступ к трудоустройству после окончания аспирантуры на предприятиях, или, альтернативно, через младшую (мелкую) работу в бизнесе.",
                    "Why choose Pearson BTEC Higher Nationals?": "Почему стоит выбрать Pearson BTEC Higher Nationals?",
                    "Who are these qualifications for?": "Для кого предназначены эти квалификации?",
                    "What you will learn": "Что вы узнаете",
                    "btec text 5": "Наши программы BTEC Higher Nationals in Business помогают студентам развивать ключевые навыки, качества и сильные стороны, которые ищут работодатели. Мы делим навыки трудоустройства на пять основных категорий:",
                    "Problem-solving skills These include:": "Решение Навики - это проблема. К ним относятся:",
                    "Independent skills:": "Независимые навыки:",
                    "Interpersonal skills:" : "Межличностные навыки:",
                    "Commercial skills" : "Коммерческие навыки:",
                    "Business skills" : "Бизнес навыки:",

                    // ELT
                    "ELT": "Бакалавр искусств  в области преподавания английского языка (CELTA)",
                    "elt text": "В 2023 году было создано учреждение для реализации комплексной образовательной программы, " +
                        "рассчитанной на три года и призванной дать учащимся необходимые навыки и знания в области преподавания языков и смежных дисциплин. Учебная программа включает в себя широкий спектр предметов, " +
                        "направленных на повышение квалификации в различных аспектах языкового образования и педагогики.",
                    "elt text 2": "Программа начинается с базовых курсов по чтению, письму, аудированию, устной речи и публичным выступлениям, " +
                        "закладывающим основу для эффективного общения. Студенты также знакомятся с основными навыками компьютерной грамотности, признавая важность технологий в современном образовании.",
                    "elt text 3": "По мере прохождения программы студенты углубляются в лингвистику, теории изучения языка и анализ дискурса, получая представление о структуре и функциях языка. " +
                        "Курсы межкультурной компетентности и методологии преподавания языков готовят студентов к задачам обучения разнообразных учащихся.",
                    "elt text 4": "Значительный упор делается на практические навыки преподавания, включая планирование уроков, управление классом и дизайн материалов, " +
                        "что обеспечивает хорошую подготовку учащихся к реальной учебной среде. Также рассматриваются принципы перевода и методы оценки языка, предлагая целостный подход к языковому образованию.",
                    "elt text 5": "Интеграция технологий в обучение и преподавание подчеркивается во всей учебной программе, что подтверждает ее важность в современной образовательной практике. " +
                        "Кроме того, студенты проходят педагогическую практику, получая практический опыт под руководством опытных преподавателей.",
                    "elt text 6": "Одним из примечательных аспектов учебного заведения является его профессорско-преподавательский состав, в состав которого входят профессионалы, имеющие международные сертификаты и дипломы. " +
                        "Этот факультет гарантирует, что студенты получают всестороннее образование, обогащенное опытом людей из разных сфер образования.",
                    "elt text 7": "В целом, программа направлена на подготовку компетентных преподавателей языка, обладающих необходимыми навыками, знаниями и профессионализмом, " +
                        "которое дает возможность преуспеть в области преподавания языков и внести позитивный вклад в мировое образовательное сообщество.",

                    // ACCA
                    "ACCA": "Бухгалтерский учет и финансы (ACCA)",
                    "ACCA text": "Наш факультет предлагает уникальные курсы ACCA, которые позволяют студентам сдать экзамены ACCA и получить девять основных сертификатов. " +
                        "В отличие от других университетов, мы обеспечиваем комплексное обучение на курсах ACCA, помогая студентам на каждом этапе их пути. Растет спрос на специалистов с международным образованием, владеющих несколькими языками. " +
                        "Учитывая это, мы предлагаем курсы английского языка и общения, чтобы помочь студентам улучшить свои навыки профессионального общения и улучшить понимание прочитанного.",
                    "ACCA text 2": "По окончании обучения наши студенты имеют возможность работать в международных аудиторских и консалтинговых компаниях на таких позициях, как аудиторы, финансовые консультанты и финансовые аналитики. " +
                        "По сути, специалисты, которых мы воспитываем, готовы преуспеть в различных отраслях, работая менеджерами, банкирами, финансистами и консультантами. Все, что мы просим взамен, — это ваше желание посещать лекции (если вы учитесь на дневном отделении), " +
                        "учитывать международную перспективу и сохранять позитивный настрой. Остальная работа на нас. Мы будем рады видеть вас в наших классах.",
                    //Business Management National
                    "Business National": "Бакалавр в области делового администрирования (национальный)",
                    "Business National text": "Курс «Бизнес-менеджмент», действующий на базе факультета международного бизнеса, учит студентов организовывать, осуществлять государственную регистрацию, ликвидировать и управлять глобальными и локальными корпоративными структурами и субъектами малого предпринимательства независимо от форм собственности. " +
                        "Квалифицированные практики, как применять стратегии, предотвращать риски, определять характеристики различных бизнес-организаций и причины, которые приводят к их успеху.",
                    "Business National text 2": "Вместе с этим студенты данного направления смогут научиться привлекать инвестиции, общаться с партнерами, определять важность инноваций для бизнеса, риски и риски, связанные с бизнесом, уметь планировать финансовую деятельность предприятий, управлять активами предприятия, распоряжаться денежными средствами, потоки и управлять расходами. " +
                        "Студентов учат навыкам оптимизации и максимизации прибыли, управления финансовыми рисками, анализа бизнес-проблем и принятия четких выводов на основе реальных кейсов компаний.",

                    "Testimonials-1": "Я благодарен университету \"Миллат Умиди\" за усилия, направленные на предоставление качественного образования и " +
                        "обеспечение всестороннего развития своих студентов. Как студент второго курса по компьютерным наукам, у меня была возможность" +
                        " учиться у преданных преподавателей, которые всегда были готовы поддержать и направить нас. Обязательства университета к " +
                        "инновациям и превосходству истинно вдохновляют меня на стремление к успеху в моих академических усилиях. Хорошо оборудованные " +
                        "лаборатории и библиотеки значительно способствовали моему академическому росту, а разнообразное студенческое сообщество " +
                        "позволило мне взаимодействовать с людьми различных культур, расширяя мою перспективу. Внеклассные мероприятия и мероприятия," +
                        " организованные университетом, значительно улучшили мои общие навыки, и я благодарен за возможности личностного и " +
                        "профессионального роста, которые предоставляет университет. Я горжусь тем, что являюсь частью такого престижного учебного " +
                        "заведения, и уверен, что знания и навыки, которые я приобретаю здесь, будут полезны для моей будущей карьеры." +
                        " Спасибо, университет \"Миллат Умиди\", за создание благоприятной обучающей среды и формирование меня как комплексного и " +
                        "уверенного в себе человека, готового принимать вызовы мира.",

                    "Testimonials-2": "Всем привет! Я Рихсибоев Абдулазиз, студент Университета Миллат Умиди по специальности" +
                        " «Прикладной учет и финансы». Прежде чем поступить в Университет Миллат Умиди, меня приняли во многие престижные " +
                        "университеты, включая Вестминстер, Британский университет менеджмента, IMC Кремс, Вебстер и Президентский университет" +
                        " в Узбекистане на стипендиальной основе. Но в итоге я выбрал Миллат Умиди, университет, который соответствует моим целям. " +
                        "Во время первых занятий в университете Миллат Умиди я еще раз убедился в правильности своего решения. " +
                        "Потому что на этих занятиях я совершенно не чувствовал усталости, хотя темы были сложные. Я изучал их с большой мотивацией," +
                        " и во время этого учебного процесса я также стремился поделиться своими знаниями с одногруппниками." +
                        " Я начал создавать для них обучающие видеоролики на темы, которые многим кажутся трудными. Уроки преподают " +
                        "квалифицированные и непредвзятые преподаватели, и мы применяем теории, полученные на уроках, на практике, анализируя " +
                        "финансовую отчетность реальных компаний. Сейчас я учусь на втором курсе, и два года, которые я провел в университете," +
                        " были очень полезны для меня. мне. В настоящее время я могу без каких-либо затруднений анализировать финансовую отчетность" +
                        " реальных компаний, и даже если в будущем открою собственное дело, я смогу самостоятельно проверить финансовую отчетность," +
                        " и я считаю, что это важно для владельцев бизнеса. Моя цель — построить карьеру в BIG4, крупнейшей в мире аудиторской фирме, " +
                        "после получения нескольких документов АССА во время учебы в университете.",
                    "Testimonials-3": "Я Шахло Хасанова, второкурсница МУ Университета. Я учусь по программе Business Management Pearson BTEC," +
                        " которая предлагает несколько возможностей для моей будущей карьеры. Есть несколько причин, по которым я выбрал этот " +
                        "университет среди десятков международных университетов Ташкента. Дружеская атмосфера внутри университета является одной из " +
                        "главных причин: все преподаватели и профессора всегда готовы помочь, когда нам это нужно. Эта программа оказалась" +
                        " для меня весьма полезной для получения не только теоретических, но и практических знаний в сфере бизнеса. Кроме того, все " +
                        "уроки и курсовые работы подробно объясняются, а преподаватели проводят мастер-классы, чтобы у студентов не возникло никаких" +
                        " трудностей. Еще одна вещь, которая мне нравится в университете, это то, что, в отличие от других университетов," +
                        "мы уделяем внимание только самым необходимым и актуальным предметам для выбранной нами специальности. Помимо учебы в " +
                        "университете организуются различные мероприятия: спортивные соревнования, экскурсии, дискуссионные клубы, чтобы сделать " +
                        "путешествие студентов веселее и интереснее!",
                    "Testimonials-4": "Привет. Я Рухсора Туйчиева. Я надежда нации, потому что я один из многообещающих студентов УНИВЕРСИТЕТА МИЛЛАТ УМИДИ. " +
                        "Меня приняли на стипендию в университеты развитых стран, таких как Латвия, Литва и Россия. Но я выбрал Университет МЮ. Сегодня я очень рад," +
                        " что сделал правильный выбор. Сегодня я изучаю деловое администрирование. Я также приобрел много знаний в области бизнеса." +
                        " Наши преподаватели очень компетентны, а учебный процесс организован профессионально. В университете MU существует качественная система" +
                        " образования.",
                    "Testimonials-5": "Привет. Я Марджона Мирзарахимова, студентка Университета Миллат Умиди. Я собираюсь сделать реальные" +
                        " комментарии об Университете MU. Меня приняли в несколько вузов, таких как УЗММЛУ, Шарда, Британский университет " +
                        "менеджмента в Ташкенте и так далее. Один из самых важных вопросов – какой университет мы выбираем." +
                        "Быть студентом университета – это наш первый шаг к успеху. Университет MU стал первым шагом на моем пути к успеху. " +
                        "Моя специальность – преподавание английского языка. Мне нравится, как здесь преподают методы. На первом курсе преподаватели " +
                        "ELT сосредоточились на английском языке в целом, на втором курсе мы сосредоточились на преподавании. Наш университет учит," +
                        "как учить наших студентов. Я многому научился в этом университете, у меня появилось много друзей." +
                        " Моя общественная жизнь — лучшее, что я могу чувствовать. Я организую различные беседы с нашими студентами, а " +
                        "также для студентов других университетов. Самое интересное, что я изучаю китайский (китайский), что делает меня " +
                        "самым счастливым человеком, говорящим на иностранных языках. Причина, по которой я выбрал Университет MU, " +
                        "заключалась не в его красивом здании. Мне нужен был путь, который повел бы меня в моем вознесении, и я " +
                        "нашел его в Университете Мью, где я мог чувствовать себя своим вторым домом, своей второй семьей. На первом курсе я " +
                        "был среди студентов, получивших стипендию. Университет подарил и продолжает дарить мне самые запоминающиеся моменты.",

                    "Testimonials-name-1": "Нурибону Элбекова",
                    "Testimonials-name-2": "Абдулазиз Рихсибоев",
                    "Testimonials-name-3": "Шахло Хасанова",
                    "Testimonials-name-4": "Рухсора Туйчиева",
                    "Testimonials-name-5": "Маржона Мирзарахимова",
                }
            }
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;