import React from "react";
import { Link } from "react-router-dom";

function teamBtec() {
    const teamMembers = [
        {
            name: "Galina Pan",
            role: "Dean of International Business faculty",
            // image: "assets/img/team/team10.png",
            image: "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
        },
        {
            name: "Bunyodjon Hakimov",
            role: "PhD Teacher",
            // image: "assets/img/team/team11.png",
            image: "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
        },
        {
            name: "Omar Ashurbaev",
            role: "MSc Teacher",
            image: "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
        },
        {
            name: "Davron Sadikov",
            role: "MSc Teacher",
            image: "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
        },
    ];

    return (
        <>
            <section
                className="team-area fix p-relative pt-80 pb-80"
                style={{ background: "#f7f9ff" }}
            >
                <div className="container">
                    <div className="row">
                        <h2 className="text-center">International Business</h2>
                        {teamMembers.map((member, index) => (
                            <div key={index} className="col-xl-3 col-md-6">
                                <div className="teachers-card single-team mb-40">
                                    <div className="team-thumb">
                                        <div className="brd">
                                            <img
                                                className="team-img"
                                                src={member.image}
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                    <div className="team-info">
                                        <h4>{member.name}</h4>
                                        <p>{member.role}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default teamBtec;
