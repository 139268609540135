import React, { useState } from "react";
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function Frequently() {
  const { i18n, t } = useTranslation();
  const faqItems = [
  {
    question: t('faq1'),
    answer: [
      t('faq1 sub')
    ]
  },
  {
    question: t('faq2'),
    answer: [
      t('faq2 sub1'),
      <br/>,
      t('faq2 sub2'),
      <br/>,
      t('faq2 sub3'),
      <br/>,
      t('faq2 sub4'),
      <br/>,
      t('faq2 sub5'),
      <br/>,
      t('faq2 sub6')
    ]
  },
  {
    question: t('faq3'),
    answer: [
      t('faq3 sub'),
    ]
  },
  {
    question: t('faq4'),
    answer: [
      t('faq4 sub'),
    ]
  },
  {
    question: t('faq5'),
    answer: [
      t('faq5 sub'),
    ]
  },
  {
    question: t('faq6'),
    answer: [
      t('faq6 sub'),
    ]
  },
  {
    question: t('faq7'),
    answer: [
      t('faq7 sub'),
    ]
  },
  {
    question: t('faq8'),
    answer: [
      t('faq8 sub'),
    ]
  },
  {
    question: t('faq9'),
    answer:
        t('faq9 sub'),
  },
  {
    question: t('faq10'),
    answer:
        t('faq10 sub')
  },
];

  const [activeIndex, setActiveIndex] = useState(null);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    comment: '',
  });
  const [successMessage, setSuccessMessage] = useState(null);
  const [modalMessage, setModalMessage] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://api.millatumidi.uz:7070/email', formData);

      console.log('API Response:', response.data);

      setFormData({
        fullName: '',
        email: '',
        phoneNumber: '',
        comment: '',
      });

      setModalMessage(t('Successfully sent'));
      setModalVisible(true);
    } catch (error) {
      let errorMessage = t('Failed to send. Please try again.');
      if (error.response) {
        console.error('API Error:', error.response.data);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up the request:', error.message);
      }
      setModalMessage(errorMessage);
      setModalVisible(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
      <>
        <section className="faq-area pt-50 pb-120 p-relative fix">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="section-title wow fadeInLeft animated" data-animation="fadeInDown animated" data-delay=".2s">
                  <h2>{t('Frequently asked questions')}</h2>
                </div>
                <div className="faq-wrap mt-30 pr-30">
                  <div className="accordion" id="accordionExample">
                    {faqItems.map((item, index) => (
                        <div className="card" key={index}>
                          <div className="card-header" id={`heading${index}`}>
                            <h2 className="mb-0">
                              <button
                                  className={`faq-btn${activeIndex === index ? "" : " collapsed"}`}
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse${index}`}
                                  onClick={() => handleToggle(index)}
                              >
                                {item.question}
                              </button>
                            </h2>
                          </div>
                          <div
                              id={`collapse${index}`}
                              className={`collapse${activeIndex === index ? " show" : ""}`}
                              data-bs-parent="#accordionExample"
                          >
                            <div className="card-body">{item.answer}</div>
                          </div>
                        </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="contact-bg02">
                  <div className="section-title wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                    <h2>{t('make a contact')}</h2>
                  </div>
                  <form
                      action="mail.php"
                      method="post"
                      className="contact-form mt-30 wow fadeInUp animated"
                      data-animation="fadeInUp"
                      data-delay=".4s"
                      onSubmit={handleSubmit}
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="contact-field p-relative c-name mb-20">
                          <input
                              type="text"
                              id="fullName"
                              name="fullName"
                              placeholder={t('First Name')}
                              required
                              value={formData.fullName}
                              onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="contact-field p-relative c-subject mb-20">
                          <input
                              type="email"
                              id="email"
                              name="email"
                              placeholder="Email"
                              required
                              value={formData.email}
                              onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="contact-field p-relative c-subject mb-20">
                          <input
                              type="text"
                              id="phoneNumber"
                              name="phoneNumber"
                              placeholder={t('Phone No.')}
                              required
                              value={formData.phoneNumber}
                              onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="contact-field p-relative c-message mb-30">
                        <textarea
                            name="comment"
                            id="comment"
                            cols={30}
                            rows={10}
                            placeholder={t('Write comments')}
                            value={formData.comment}
                            onChange={handleChange}
                        />
                        </div>
                        <div className="slider-btn text-center">
                          <button className="btn ss-btn" data-animation="fadeInRight" data-delay=".8s" type="submit">
                            {t('make a request')} <i className="fal fa-long-arrow-right" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
            className={`modal fade${modalVisible ? " show" : ""}`}
            tabIndex="-1"
            role="dialog"
            style={{ display: modalVisible ? "block" : "none" }}
            aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t('Notification')}</h5>
              </div>
              <div className="modal-body">
                <p>{modalMessage}</p>
              </div>
              <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setModalVisible(false)}
                >
                  {t('Close')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default Frequently;