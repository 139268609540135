import React from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import Background from '../../assets/img/gallery/university-new.jpg'
import Backgroundtwo from '../../assets/img/gallery/University2.jpg'
import { useTranslation } from 'react-i18next';

function Sliderone() {

    const { t } = useTranslation();


    const settings = {
        autoplay: true,
		autoplaySpeed: 5000,
		dots:false ,
		fade: true,
		arrows: false,
		responsive: [
			{ breakpoint: 1200, settings: { dots: false, arrows: false } }
		]
      };

    // 141b22
  return (
    <>
        <section id="home" className="slider-area fix p-relative">
        <Slider className="slider-active" style={{ background: "#141b22" }} {...settings}>
                <div>
                    <div className="single-slider slider-bg filter-img"  style={{ backgroundImage: `url(${Background})` , backgroundSize: "cover" }} >
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-7 col-md-7">
                            <div className="slider-content s-slider-content mt-130">
                            <h5 data-animation="fadeInUp" data-delay=".4s">
                                {t('Welcome To MU University')}
                            </h5>
                            <h2 data-animation="fadeInUp" data-delay=".4s">
                                {t('Become a global citizen with us')}
                            </h2>
                            <p data-animation="fadeInUp" data-delay=".6s">
                                {t('Sliderone sub text')}
                            </p>
                            <div className="slider-btn mt-30">
                                <Link target="_blank" to="https://admission.millatumidi.uz/" className="btn-first btn ss-btn mr-15" data-animation="fadeInLeft" data-delay=".4s" >
                                    {t('apply')}<i className="fal fa-long-arrow-right" />
                                </Link>
                                <Link to="/contact" className="btn ss-btn active" data-animation="fadeInLeft" data-delay=".4s" >
                                    {t('contact')}<i className="fal fa-long-arrow-right" />
                                </Link>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 p-relative"></div>
                        </div>
                    </div>
                    </div>
                </div>
                <div>


                    
                <div className="single-slider slider-bg" style={{ backgroundImage: `url(${Backgroundtwo})`, backgroundSize: "cover" }} >
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-7 col-md-7">
                            <div className="slider-content s-slider-content mt-130">
                            <h5 data-animation="fadeInUp" data-delay=".4s">
                                {t('Welcome To MU University')}

                            </h5>
                            <h2 data-animation="fadeInUp" data-delay=".4s">
                                {t('apply')}
                            </h2>
                            <p data-animation="fadeInUp" data-delay=".6s">
                                {t('Sliderone sub text 2')}
                            </p>
                            <div className="slider-btn mt-30">
                                <Link to="/admission" className="btn ss-btn mr-15" data-animation="fadeInLeft" data-delay=".4s" >
                                    {t('admission')}<i className="fal fa-long-arrow-right" />
                                </Link>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 p-relative"></div>
                        </div>
                    </div>
                    </div>
                </div>
            </Slider>
        </section>
        <section className="service-details-two p-relative">
            <div className="container">
                <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="services-box07">
                    <div className="sr-contner">
                        <div className="icon">
                            <img className="icon-images" src="assets/img/icon/sve-icon4.png" alt="icon01" />
                        </div>
                        <div className="text">
                        <h5 className="degree-words">
                            <Link to="/courses">{t('Bachelors Degree')}</Link>
                        </h5>
                        <Link to="/courses">
                            {t('Read More')} <i className="fal fa-long-arrow-right" />
                        </Link>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="services-box07 active">
                    <div className="sr-contner">
                        <div className="icon">
                            <img className="icon-images" src="assets/img/icon/sve-icon5.png" alt="icon01" />
                        </div>
                        <div className="text">
                        <h5 className="degree-words">
                            <Link to="/courses">{t('Masters Degree')}</Link>
                        </h5>
                        <Link to="/Courses">
                            {t('Read More')} <i className="fal fa-long-arrow-right" />
                        </Link>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="services-box07">
                    <div className="sr-contner">
                        <div className="icon">
                            <img className="icon-images" src="assets/img/icon/sve-icon6.png" alt="icon01" />
                        </div>
                        <div className="text">
                        <h5 className="degree-words">
                            <Link to="/scholarship">{t('scholarship')}</Link>
                        </h5>
                        <Link to="/scholarship">
                            {t('Read More')} <i className="fal fa-long-arrow-right" />
                        </Link>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Sliderone