import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Carousel } from "react-bootstrap";

function Testimonial() {
    const { t } = useTranslation();

    const testimonials = [
        {
            name: t('Testimonials-name-1'),
            content: t('Testimonials-1'),
            avatar: "assets/img/testimonial/testimonial-6.png",
        },
        {
            name: t('Testimonials-name-2'),
            content: t('Testimonials-2'),
            avatar: "assets/img/testimonial/testimonial-2.png"
        },
        {
            name: t('Testimonials-name-3'),
            content: t('Testimonials-3'),
            avatar: "assets/img/testimonial/testimonial-5.png"
        },
        {
            name: t('Testimonials-name-4'),
            content: t('Testimonials-4'),
            avatar: "assets/img/testimonial/testimonial-3.png"
        },
        {
            name: t('Testimonials-name-5'),
            content: t('Testimonials-5'),
            avatar: "assets/img/testimonial/testimonial-4.png"
        }
    ];

    const [expandedIndex, setExpandedIndex] = useState(null); // Tracks expanded testimonial index

    const toggleText = index => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const shouldShowReadMore = (text, index) => {
        return text.length > 350 && expandedIndex !== index;
    };

    const shouldShowReadLess = (index) => {
        return expandedIndex === index;
    };

    const displayText = (text, index) => {
        if (expandedIndex === index) {
            return text;
        } else {
            return `${text.substring(0, 350)}... `;
        }
    };

    return (
        <section className="testimonial-area py-4" style={{ backgroundColor: "#f8f9fa" }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="bg-white shadow-lg p-4">
                            <div className="text-center mb-3">
                                <h2 className="section-title text-dark">{t('What our students say')}</h2>
                            </div>
                            <div className="testimonial-slider">
                                <Carousel controls indicators={false}>
                                    {testimonials.map((testimonial, index) => (
                                        <Carousel.Item key={index}>
                                            <div className="testimonial-content mx-auto text-center"
                                                 style={{maxWidth: "750px", overflow: 'hidden' }}>
                                                <p className="text-dark">
                                                    {displayText(testimonial.content, index)}
                                                    {shouldShowReadMore(testimonial.content, index) && (
                                                        <span
                                                            onClick={() => toggleText(index)}
                                                            style={{color: '#007bff', cursor: 'pointer'}}>
                                                            Read more
                                                        </span>
                                                    )}
                                                    {shouldShowReadLess(index) && (
                                                        <span
                                                            onClick={() => toggleText(index)}
                                                            style={{marginLeft: "10px", textDecoration: "underline", color: '#007bff', cursor: 'pointer'}}>
                                                            Show less
                                                        </span>
                                                    )}
                                                </p>
                                            </div>
                                            <div className="testimonial-avatar testi-author text-center mt-2 mb-3">
                                                <img src={testimonial.avatar} alt={testimonial.name}
                                                     className="rounded-circle"
                                                     style={{width: "150px", height: "150px", borderRadius: "50%", objectFit: "cover"}}/>
                                            </div>
                                            <h5 style={{textAlign: "center"}} className="text-dark">{testimonial.name}</h5>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-12">
                        <Carousel controls className="custom-video-slider">
                            <Carousel.Item>
                                <div className="embed-responsive custom-video-ratio">
                                    <iframe
                                        src="https://www.youtube.com/embed/6ZvnreLgPYQ?si=-2EI_-Xy_8kDlYpW"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="embed-responsive custom-video-ratio">
                                    <iframe
                                        src="https://www.youtube.com/embed/xLfhq51A5aw?si=XPiyPuSyXaRwpYTs"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="embed-responsive custom-video-ratio">
                                    <iframe
                                        src="https://www.youtube.com/embed/STvsdAwNQ-4?si=eJpqvXI7W1Pzj4UT"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;
