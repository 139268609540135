import React from 'react'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import First from '../menEvent/First'
import { useTranslation } from 'react-i18next';


function Main() {
    const { i18n, t } = useTranslation();
    return (
        <>
            <Header/>
            <Bredcom title="Home" subtitle={t('Our events')}/>
            <First/>
        </>
    )
}

export default Main