import React from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

function Redslider() {
    const { i18n, t } = useTranslation();
    const images = [
        { src: "assets/img/brand/idp_ielts_logo.svg", url: "https://www.exams.uz/" },
        { src: 'assets/img/brand/b-logo2.png', url: "https://edu-master.uz/" },
        { src: 'assets/img/brand/b-logo3.png', url: "https://www.instagram.com/isystem.uz/" },
        { src: 'assets/img/brand/b-logo4.png', url: "https://uz.khanacademy.org/" },
        { src: 'assets/img/brand/b-logo8.png', url: "https://www.instagram.com/result_school_uz/" },
        { src: "assets/img/brand/airports-logo.svg", url: "https://uzairports.com/" },
        { src: "assets/img/brand/beeline.svg", url: "https://beeline.uz/" },
        { src: "assets/img/brand/mobiuz.svg", url: "https://mobi.uz/uz/" },
        { src: "assets/img/brand/GW_logo.png", url: "https://www.greenwhite.uz/" },
        { src: "assets/img/brand/epam-logo.svg", url: "https://www.epam.com/" },
        { src: "assets/img/brand/huawei_logo.png", url: "https://www.huawei.com/uz/" },
        { src: "assets/img/brand/vronica_logo.png", url: "https://www.linkedin.com/company/vronica/?originalSubdomain=uz" },
        { src: "assets/img/brand/inson.png", url: "https://e-inson.uz/en" },
        { src: "assets/img/brand/innotechno.png", url: "https://innotechnopark.com/en/" },
        { src: "assets/img/brand/deloitte.svg", url: "https://www2.deloitte.com/kz/en/pages/uzbekistan/topics/uzbekistan.html?icid=site_selector_uz" },
        { src: "assets/img/brand/pwc-logo.svg", url: "https://www.pwc.com/uz/en.html" },
        { src: "assets/img/brand/kpmg-logo.png", url: "https://kpmg.com/uz/en/home.html" },
        { src: "assets/img/brand/ey-logo.png", url: "https://www.ey.com/en_uz" },
        { src: "assets/img/brand/ECE.png", url: "https://www.ece.org/" }
    ];

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1500,
        arrows: false,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 3,
                    infinite: true
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 3,
                    infinite: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div className="col-lg-12">
                <div className="section-title text-center mb-50 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                    <h5>
                        <i className="fal fa-handshake" /> {t('partners')}
                    </h5>
                    <h2>{t('our partners')}</h2>
                </div>
            </div>
            <div className="brand-area pt-60 pb-60" style={{backgroundColor: '#dbdedf'}}>
                <div className="container">
                    <Slider className="row brand-active" {...settings}>
                        {images.map((image, index) => (
                            <div key={index} className="col-xl-2">
                                <div className="single-brand">
                                    <a href={image.url} target="_blank" rel="noopener noreferrer">
                                        <img className="partner-images" src={image.src} alt="partner logo" />
                                    </a>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </>
    );
}

export default Redslider;
