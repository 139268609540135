import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


function First() {
  const { i18n, t } = useTranslation();
  const courses = [
    {
      imgSrc: '../../../assets/img/students/IT-bg-1.jpg',
      category: t('Information Technology'),
      program: 'Bachelor',
      title: t('IT'),
      description: t('Undergraduate Program'),
      url: '/BSc-in-Software-Engineering',
    },
    {
      imgSrc: '../../../assets/img/students/BTEC-bg-1.jpg',
      category: t('business management'),
      program: 'Bachelor',
      title: t('BTEC'),
      description: t('Undergraduate Program'),
      url: '/Business-Management',
    },
    {
      imgSrc: '../../../assets/img/students/ELT-bg-1.jpg',
      category: t('language'),
      program: 'Bachelor',
      title: t('ELT'),
      description: t('Undergraduate Program'),
      url: '/English-language-teaching',
    },
    {
      imgSrc: '../../../assets/img/students/ACCA-bg-1.jpg',
      category: t('finance'),
      program: 'Bachelor',
      title: t('ACCA'),
      description: t('Undergraduate Program'),
      url: '/Applied-accounting',
    },
    {
      imgSrc: '../../../assets/img/students/ACCA-bg-2.jpg',
      category: t('business'),
      program: 'Bachelor',
      title: t('BM'),
      description: t('Undergraduate Program'),
      url: '/Business_national',
    },
    // {
    //   imgSrc: '../../../assets/img/bg/master-elt.png',
    //   category: 'Language',
    //   program: 'Master',
    //   title: 'Master of Arts in English Language Education',
    //   description: 'Postgraduate Program',
    //   url: '/Master-of-arts-in-english-language-education',
    // },
    // {
    //   // imgSrc: 'assets/img/bg/couress-img-2.jpg',
    //   imgSrc: 'https://images.unsplash.com/photo-1431540015161-0bf868a2d407?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
    //   category: 'Business',
    //   program: 'Master',
    //   title: 'Master of Business Administration(MBA)',
    //   description: 'Postgraduate Program',
    //   url: '/w-courses',
    // },
    // {
    //   // imgSrc: 'assets/img/bg/couress-img-5.jpg',
    //   imgSrc: 'https://images.unsplash.com/photo-1529119368496-2dfda6ec2804?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
    //   category: 'Marketing',
    //   program: 'Master',
    //   title: 'Master of Digital Marketing and Business Innovation',
    //   description: 'Postgraduate Program',
    //   url: '/r-courses',
    // },

  ];

  const [filter, setFilter] = useState('All');
  const filteredCourses = filter === 'All' ? courses : courses.filter(course => course.program === filter);

  return (
      <>
        <section className="shop-area pt-90 pb-120 p-relative " data-animation="fadeInUp animated" data-delay=".2s">
          <div className="container">
            {/*<div className="row mb-4">*/}
            {/*  <div className="col text-center">*/}

            {/*        <button*/}
            {/*            type="button"*/}
            {/*            className={`btn ${filter === 'All' ? 'btn-primary' : 'btn-outline-primary'} filter-button`}*/}
            {/*            onClick={() => setFilter('All')}*/}
            {/*            style={{ backgroundColor: filter === 'All' ? '#125875' : '', borderColor: '#125875' }}*/}
            {/*        >*/}
            {/*          All*/}
            {/*        </button>*/}
            {/*        <button*/}
            {/*            type="button"*/}
            {/*            className={`btn ${filter === 'Bachelor' ? 'btn-primary' : 'btn-outline-primary'} filter-button`}*/}
            {/*            onClick={() => setFilter('Bachelor')}*/}
            {/*            style={{ backgroundColor: filter === 'Bachelor' ? '#125875' : '', borderColor: '#125875' }}*/}
            {/*        >*/}
            {/*          Undergraduate*/}
            {/*        </button>*/}
            {/*        <button*/}
            {/*            type="button"*/}
            {/*            className={`btn ${filter === 'Master' ? 'btn-primary' : 'btn-outline-primary'} filter-button`}*/}
            {/*            onClick={() => setFilter('Master')}*/}
            {/*            style={{ backgroundColor: filter === 'Master' ? '#125875' : '', borderColor: '#125875' }}*/}
            {/*        >*/}
            {/*          Postgraduate*/}
            {/*        </button>*/}

            {/*  </div>*/}
            {/*</div>*/}
            <div className="row align-items-center">
              {filteredCourses.map((course, index) => (
                  <div className="col-lg-4 col-md-6" key={index}>
                    <div className="courses-item mb-30 hover-zoomin">
                      <div className="thumb fix">
                        <Link to={course.url}>
                          <img className="course-img" src={course.imgSrc} alt="course-img" />
                        </Link>
                      </div>
                      <div className="courses-content">
                        <div className="cat">
                          <i className="fal fa-graduation-cap" /> {course.category}
                        </div>
                        <h3>
                          <Link to={course.url}>{course.title}</Link>
                        </h3>
                        <p>{course.description}</p>
                        <Link to={course.url} className="readmore">
                          {t('Read More')} <i className="fal fa-long-arrow-right" />
                        </Link>
                      </div>
                      <div className="icon">
                        <img className="course-img" src="assets/img/icon/cou-icon.png" alt="icon" />
                      </div>
                    </div>
                  </div>
              ))}
            </div>
          </div>
        </section>
      </>
  );
}

export default First;