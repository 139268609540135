import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

function Event5() {
    const { i18n, t } = useTranslation();
    return (
        <>
            <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                <div className="upper-box">
                                    <div className="col-md-8 position-relative">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <video controls className="embed-responsive-item"
                                                   style={{height: 'auto', maxHeight: '500px'}}>
                                                <source src="../../assets/video/coding-festival.mp4" type="video/mp4"/>
                                            </video>
                                        </div>
                                    </div>

                                </div>
                                <div className="inner-column">
                                    <h3>Cambridge Xalqaro Universiteti, "Millat Umidi" fondi va IT Park hamkorligida
                                        o'tkazilayotgan
                                        "Cambridge Coding Festival" ning birinchi kunidan lavhalar.
                                    </h3>
                                    <p style={{fontSize: "18px"}}>
                                        Ma'lumot o'rnida, 350 dan ortiq yoshlar o'zlarining 3-5 kishidan iborat
                                        guruhlari bilan ro'yxatdan o'tgan bo'lib, hozirda 60 ta jamoa o'z g'oyalari bilan ishtirok etmoqda.
                                    </p>
                                    <p style={{fontSize: "18px"}}>
                                        💰Ushbu hakaton uchun umumiy miqdorda 100,000,000 so'm mukofot jamg'armasi ajratilgan.
                                    </p>
                                    <div className="two-column mt-20">
                                        <div className="row aling-items-center">
                                            <div className="image-column col-xl-6 col-lg-12 col-md-12">
                                            </div>
                                            <div className="text-column col-xl-6 col-lg-12 col-md-12 text-right"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <aside className="sidebar-widget info-column">
                                    <div className="inner-column3">
                                        <h3>Event Details</h3>
                                        <ul className="project-info clearfix">
                                            <li>
                                                <span className="icon fal fa-calendar-alt" />
                                                <strong>20 May, 2022</strong>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-map-marker-check" />
                                                <strong>Toshkent sh., Chilonzor tumani, Bunyodkor, Navbahor 24</strong>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Event5