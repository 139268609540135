import React from "react";
import { useTranslation } from 'react-i18next';

function First() {
  const { i18n, t } = useTranslation();
  return (
    <>
      <section className="scholarship pt-90 pb-30 fix p-relative">
        <div className="text-center mb-20">
          <h2 className="scholar-title">
            {t('Scholar Tittle')}
          </h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="mb-25">
              <p>
                {t('Scholar sub')}
              </p>
            </div>
            <div className="col-md-6 mb-20">
              <div className="card h-100">
                <div className="card-body d-flex flex-column justify-content-between">
                  <div>
                    <h5 className="card-title">{t('Academic Excellence')}</h5>
                    <ul className="card-text">
                      <li>
                        1. <b>{t('Scholar sub 1')}</b> - IELTS 7.0+
                         {t('and')} SAT 1200+
                      </li>
                      <li>
                        2. <b>{t('Scholar sub 2')}</b> - SAT 1300+
                      </li>
                      <li>
                        3. <b>{t('Scholar sub 3')}</b> - IELTS 7.5+
                      </li>
                    </ul>
                    <p><b>{t('Scholar sub 4')}</b></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-20">
              <div className="card h-100">
                <div className="card-body d-flex flex-column justify-content-between">
                  <div>
                    <h5 className="card-title">{t('Top Student')}</h5>
                    <p className="card-text">
                      {t('Scholar sub 5')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-25">
          <h4>{t('Scholar sub 6')}</h4>
        </div>
      </section>
    </>
  );
}

export default First;
