import React from 'react'
import Headerone from '../headerone/Main'
import About from '../menhome/About'
import Courses from '../menhome/Courses'
import Event from '../menhome/Event'
import Slider from '../menhome/Sliderone'
import Testimonial from '../menhome/Testimonial'
import Frequently from '../menhome/Frequently'
import Redslider from '../menhome/Redslider'

function Main() {
  return (
    <>  
        <Headerone/>
        <Slider/>
        <About/>
        <Courses/>
        <Event/>
        <Testimonial/>
        <Frequently/>
        <Redslider/>
    </>
  )
}

export default Main