import React from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import Second from '../menteacher/team-acca'

function Main() {
    return (
        <>
            <Header/>
            <Bredcom title="Home" subtitle="Our Teacher"/>
            <Second />
        </>
    )
}

export default Main