import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


function First() {
    const { i18n, t } = useTranslation();

    const [activeIndex, setActiveIndex] = useState(null);
    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    const [courseData, setCourseData] = useState(null);

    useEffect(() => {
        fetch('https://api.millatumidi.uz:7070/courseinfo')
            .then((response) => response.json())
            .then((data) => {
                if (data.data && data.data.length > 0) {
                    setCourseData(data.data[0]);
                }
            })
            .catch((error) => {
                console.error('Error fetching course information:', error);
            });
    }, []);

    return (
        <>
            <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                <h3>{t('BSc in Software Engineering')}</h3>
                                <div className="upper-box">
                                    <div className="embed-responsive custom-video-ratio">
                                        <iframe
                                            src="https://www.youtube.com/embed/tXHYhDcyPkY?si=TYsQA_K7q625QgdX"
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                                <div className="inner-column">
                                    <div className="course-meta2 review style2 clearfix mb-30">
                                        <ul className="left">
                                            <li>
                                                <div className="author">
                                                    <div className="thumb">
                                                        {/*<img className="team-img" src="assets/img/bg/testi_avatar.png" alt="image" />*/}
                                                    </div>
                                                    <div className="text">
                                                        <Link to="/team-single">Muslimbek Pirnazarov</Link>
                                                        <p>Dean, Teacher</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="categories">
                                                <div className="author">
                                                    <div className="text">
                                                        <a href="#" className="course-name">
                                                            Faculty
                                                        </a>
                                                        <p>Information Technology</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="author">
                                                    <div className="text">
                                                        <p>
                                                            <a href="mailto:m.prirnazarov@cambridge.uz">Email</a>
                                                        </p>
                                                        <p>m.prirnazarov@cambridge.uz</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="author">
                                                    <div className="text">
                                                        <a href="#">Room</a>
                                                        <p>Branch C, Third floor</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {/*<div className="text-center">*/}
                                    {/*    <Link className="btn" to="/team">*/}
                                    {/*        View Teacher Details*/}
                                    {/*    </Link>*/}
                                    {/*</div>*/}
                                    <h3>Course Overview</h3>
                                    <p>
                                        {t('bsc text')}
                                    </p>
                                    <b>{t('Mission')}</b>
                                    <p>
                                        {t('bsc text 2')}
                                    </p>
                                    <b>{t('Your future career')}</b>
                                    <p>
                                        {t('bsc text 3')}
                                    </p>
                                    <h4>Course Overview</h4>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER I</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>INTPRO16</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/CSC1010',
                                                    state: {courseInfo: courseData}
                                                }}>
                                                    Introduction to Programming - 1
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>EAP6</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/subject/BA1105',
                                                    state: {courseInfo: courseData}
                                                }}>English for Academic Purposes (EAP)
                                                </Link>
                                            </td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>APPMAT16</td>
                                            <td><Link to={{
                                                pathname: '/subject/CSC1012',
                                                state: {courseInfo: courseData}
                                            }}>Applied mathematics 1
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>MRHUZ4</td>
                                            <td><Link to={{
                                                pathname: '/subject/MRHUZ4',
                                                state: {courseInfo: courseData}
                                            }}>Most recent history of Uzbekistan
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>BUSCOMS6</td>
                                            <td><Link to={{
                                                pathname: '/subject/BUSCOMS6',
                                                state: {courseInfo: courseData}
                                            }}>Business Communication Skills
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER II</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>APPMAT26</td>
                                            <td><Link to={{
                                                pathname: '/subject/CSC1013',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Applied mathematics 2
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>INTIT6</td>
                                            <td><Link to={{
                                                pathname: '/subject/CSC1014',
                                                state: {courseInfo: courseData}
                                            }}>Introduction to IT
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>INTPRO26</td>
                                            <td><Link to={{
                                                pathname: '/subject/CSC1011',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Introduction to Programming - 2
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>PHILOS3</td>
                                            <td><Link to={{
                                                pathname: '/subject/PHILOS3',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Philosophy
                                            </Link></td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>RELST3</td>
                                            <td><Link to={{
                                                pathname: '/subject/RELST3',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Religious Studies
                                            </Link></td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>ESP6</td>
                                            <td><Link to={{
                                                pathname: '/subject/ESP6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                English for specific purpose
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER III</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>COMPSC16</td>
                                            <td><Link to={{
                                                pathname: '/subject/CSC2010',
                                                state: { courseInfo: courseData}
                                            }}>
                                                Computer Science I
                                            </Link></td>
                                            <td colSpan="2">6</td>
                                        </tr>
                                        <tr>
                                            <td>DATABAC8</td>
                                            <td><Link to={{
                                                pathname: '/subject/CSC2012',
                                                state: { courseInfo: courseData}
                                            }}>
                                                Database Concepts
                                            </Link></td>
                                            <td colSpan="2">8</td>
                                        </tr>
                                        <tr>
                                            <td>STAT6</td>
                                            <td><Link to={{
                                                pathname: '/subject/STAT6',
                                                state: { courseInfo: courseData}
                                            }}>
                                                Statistics
                                            </Link></td>
                                            <td>10</td>
                                        </tr>
                                        <tr>
                                            <td>FIT6</td>
                                            <td><Link to={{
                                                pathname: '/subject/FIT6',
                                                state: { courseInfo: courseData}
                                            }}>
                                                Fundamentals of IT
                                            </Link></td>
                                            <td colSpan="2">6</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER IV</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th colSpan="2">ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>ALDSTR8</td>
                                            <td><Link to={{
                                                pathname: '/subject/CSC2015',
                                                state: { courseInfo: courseData}
                                            }}>
                                                Algorithms & Data Structures
                                            </Link></td>
                                            <td colSpan="2">8</td>
                                        </tr>
                                        <tr>
                                            <td>OBOPR6</td>
                                            <td><Link to={{
                                                pathname: '/subject/CSC2016',
                                                state: { courseInfo: courseData}
                                            }}>
                                                Object Oriented Programming
                                            </Link></td>
                                            <td colSpan="2">6</td>
                                        </tr>
                                        <tr>
                                            <td>COMNET10</td>
                                            <td><Link to={{
                                                pathname: '/subject/CSC2017',
                                                state: { courseInfo: courseData}
                                            }}>
                                                Computer Networks
                                            </Link></td>
                                            <td colSpan="2">10</td>
                                        </tr>
                                        <tr>
                                            <td>COMPSC26</td>
                                            <td><Link to={{
                                                pathname: '/subject/CSC2011 ',
                                                state: { courseInfo: courseData}
                                            }}>
                                                Computer Science II
                                            </Link></td>
                                            <td colSpan="2">6</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER V</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>WEBDEV6</td>
                                            <td><Link to={{
                                                pathname: '/subject/CSE3010 ',
                                                state: { courseInfo: courseData}
                                            }}>
                                                Web Application Development I
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>GDEV6</td>
                                            <td><Link to={{
                                                pathname: '/subject/GDEV6',
                                                state: { courseInfo: courseData}
                                            }}>
                                                Game development
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>MOBADEV6</td>
                                            <td><Link to={{
                                                pathname: '/subject/MOBADEV6',
                                                state: { courseInfo: courseData}
                                            }}>
                                                Mobile application development
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Course
                                            </Link></td>
                                            <td>12</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER VI</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>WEBDEV26</td>
                                            <td><Link to={{
                                                pathname: '/subject/CSC3011 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Web Application Development II
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>INFSEC6</td>
                                            <td><Link to={{
                                                pathname: '/subject/INFSEC6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Computer system security
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>INTERN6</td>
                                            <td><Link to={{
                                                pathname: '/subject/INTERN6 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Internship
                                            </Link></td>
                                            <td>12</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Course
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER VII</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>AI6</td>
                                            <td><Link to={{
                                                pathname: '/subject/CSE4012 ',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Artificial Intelligence
                                            </Link></td>
                                            <td colSpan="2">6</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Course
                                            </Link></td>
                                            <td>24</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                        <tr>
                                            <th className="text-center" colSpan="12">SEMESTER VIII</th>
                                        </tr>
                                        </thead>
                                        <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Course Name</th>
                                            <th>ECTS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>INTERN6</td>
                                            <td><Link to={{
                                                pathname: '/subject/INTERN6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Internship
                                            </Link></td>
                                            <td>12</td>
                                        </tr>
                                        <tr>
                                            <td>FINWOR6</td>
                                            <td><Link to={{
                                                pathname: '/subject/FINWOR6',
                                                state: {courseInfo: courseData}
                                            }}>
                                                Graduation qualification work
                                            </Link></td>
                                            <td colSpan="2">12</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><Link to={{
                                                state: {courseInfo: courseData}
                                            }}>
                                                Elective Course
                                            </Link></td>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right" colSpan="2">Total</td>
                                            <td>30</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div className="col-lg-3">
                                <aside className="sidebar-widget info-column">
                                    <div className="inner-column3">
                                        <h3>Course Features</h3>
                                        <ul className="project-info clearfix">
                                        <li>
                                                <span className="icon fal fa-home-lg-alt" />{" "}
                                                <strong>Campus:</strong> <span>Branch C</span>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <span className="icon fal fa-book" />{" "}*/}
                                            {/*    <strong>Lectures:</strong> <span>36 </span>*/}
                                            {/*</li>*/}
                                            <li>
                                                <span className="icon fal fa-clock" />{" "}
                                                <strong>Duration: </strong> <span>4 years</span>
                                            </li>
                                            <li>
                                                <div className="tuition-fee">
                                                    <span className="icon fal fa-clock"/>{" "}
                                                    <strong>Tuition fee (Per Year):</strong> <br/>
                                                    <div className="tuition-fees">
                                                        <div>
                                                            <p>25,000,000 UZS {t('Full time')}</p>
                                                            <p>15,000,000 UZS {t('Part time')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe"/>{" "}
                                                <strong>Language: </strong> <span>English</span>
                                            </li>
                                            <li>
                                                <div className="slider-btn">
                                                    <Link target="_blank" to="https://admission.millatumidi.uz/"
                                                          className="btn ss-btn smoth-scroll">
                                                        Apply <i className="fal fa-long-arrow-right" />
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="disclaimer">
                                            <i>*Some discount or scholarship may apply</i>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First