import React from "react";
import { useTranslation } from 'react-i18next';


function Campus() {
  const { i18n, t } = useTranslation();
  return (
    <>
      {/*<section*/}
      {/*  className="steps-area2 p-relative fix"*/}
      {/*  style={{ backgroundColor: "#125875", padding: "25px" }}*/}
      {/*>*/}
      {/*  <div className="container">*/}
      {/*    <div className="row align-items-center">*/}
      {/*      <div className="col-lg-6 col-md-12">*/}
      {/*        <div*/}
      {/*          className="step-box step-box2 wow fadeInUp animated"*/}
      {/*          data-animation="fadeInUp"*/}
      {/*          data-delay=".4s"*/}
      {/*        >*/}
      {/*          <div className="text">*/}
      {/*            <h2>Founder's Greeting</h2>*/}
      {/*            <p>*/}
      {/*              "The main goal of this project is to enable our young*/}
      {/*              generation to gain knowledge and build the foundation for*/}
      {/*              their future development, learning, career, and*/}
      {/*              achievements. Based on our experience, using the knowledge*/}
      {/*              gained abroad and with the help of the strongest*/}
      {/*              professionals and mentors, we will make every effort to*/}
      {/*              contribute to the formation of a strong nation."*/}
      {/*              <br />*/}
      {/*              Sincerely,*/}
      {/*              <br />*/}
      {/*              <i>*/}
      {/*                <b>Umidjon Ishmukhamedov</b>*/}
      {/*              </i>*/}
      {/*            </p>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="col-lg-6 col-md-12">*/}
      {/*        <div*/}
      {/*          className="step-img2 wow fadeInLeft animated"*/}
      {/*          data-animation="fadeInLeft"*/}
      {/*          data-delay=".4s"*/}
      {/*        >*/}
      {/*          <img*/}
      {/*            className="founder-photo"*/}
      {/*            src="assets/img/bg/steps-img-3.png"*/}
      {/*            alt="class image"*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      <section
        className="steps-area2 p-relative fix"
        style={{ backgroundColor: "#032e3f", padding: "30px" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div
                className="step-box step-box2 wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
              >
                <div className="text">
                  <h2>{t('Rectors Greeting')}</h2>
                  <p>
                    {t('Dear students')}
                    <br />
                    {t('Rectors Greeting Text')}
                    <br />
                    {t('Welcome to the MU University family')}
                    <br />
                    {t('Sincerely')}
                    <br />
                    <i>
                      <b>{t('Dinara Ishmanova')}</b>
                    </i>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div
                className="step-img2 wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img
                    style={{width: "100%", height: "350px", objectFit: "cover"}}
                  className="founder-photo"
                  src="assets/img/team/university-rector.jpg"
                  alt="class image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Campus;
