import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import Touch from '../mencontact/Touch'
import Map from '../mencontact/Map'
import Form from '../mencontact/Form'
import { useTranslation } from 'react-i18next';


function Main() {
    const { i18n, t } = useTranslation();
    return (
    <>
        <Header/>
        <Bredcom title="Home" subtitle={t('contact')}/>
        <Touch/>
        <Map/>
        <Form/>
    </>
  )
}

export default Main