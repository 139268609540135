import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';


function Document() {
    const { i18n, t } = useTranslation();
    const [showImage, setShowImage] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const toggleImage = () => {
        if (!isMobile) {
            setShowImage(!showImage);
        }
    };

    useEffect(() => {
        const checkIsMobile = () => {
            if (window.innerWidth <= 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        checkIsMobile(); // Initial check
        window.addEventListener('resize', checkIsMobile);

        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    return (
        <>
            <section className="d-flex justify-content-center align-items-center mt-5 mb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 d-flex justify-content-center align-items-start">
                        <div className="university-license">
                            <img
                                src="assets/img/logo/document.jpg"
                                alt="university license"
                                onClick={toggleImage}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    </div>
                    <div className="col-md-8" >
                        <div className="university-description">
                            <h2 className="university-description-heading">
                                {t('University License')}
                            </h2>
                            <p className="university-description__text">
                                {t('License Text')}
                            </p>
                            <a
                                target="_blank"
                                className="university-description__link"
                                href="https://license.gov.uz/registry?filter%5Bnumber%5D=056013">
                                {t('University License')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    {showImage && (
        <div className="overlay" onClick={toggleImage}>
            <div className="image-container">
                <img
                    src="assets/img/logo/document.jpg"
                    alt="university license"
                    className="img-fluid"
                />
            </div>
        </div>
    )}
        </>
);
}

export default Document;
