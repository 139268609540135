import React, { useState } from 'react'


function Event6() {


    return (
        <>
            <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                <div className="upper-box">
                                    <div className="single-item-carousel owl-carousel owl-theme">
                                        <figure className="image">
                                            <img style={{maxWidth: "100%"}} src="assets/img/event/event61.jpg" alt="event-details-image"/>
                                        </figure>
                                    </div>
                                </div>
                                <div className="inner-column">
                                    <h1>Millat Umidi University students visited Artel as part of a techno-tour.</h1>
                                    Students of BTEC faculty of Millat Umidi University visited several production enterprises of 
                                    Artel company in Rohat area as part of the techno tour and got acquainted with the enterprise's activities and work processes.
                                    <p></p> <h6>Become a world citizen with MU University!</h6><p></p>
                                    <div className="two-column mt-20">
                                        <div className="row aling-items-center">
                                            <div className="image-column col-xl-6 col-lg-12 col-md-12">
                                            </div>
                                            <div className="text-column col-xl-6 col-lg-12 col-md-12 text-right"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <aside className="sidebar-widget info-column">
                                    <div className="inner-column3">
                                        <h3>Event Details</h3>
                                        <ul className="project-info clearfix">
                                            <li>
                                                <span className="icon fal fa-calendar-alt" />
                                                <strong>30 May, 2023</strong>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-map-marker-check" />
                                                <strong> Tashkent, District: Yashnabad, Street Makhtumkuli (former Tarakkiyot), 2</strong>
                                            </li>

                                        </ul>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Event6