import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function First() {
    const { i18n, t } = useTranslation();
    const [video, setVideo] = useState();

    const [activeIndex, setActiveIndex] = useState(null);
const faqData = [
    {
        question: t('faq1'),
        answer: [
            t('faq1 sub')
        ]
    },
    {
        question: t('faq2'),
        answer: [
            t('faq2 sub1'),
            <br/>,
            t('faq2 sub2'),
            <br/>,
            t('faq2 sub3'),
            <br/>,
            t('faq2 sub4'),
            <br/>,
            t('faq2 sub5'),
            <br/>
        ]
    },
    {
        question: t('faq3'),
        answer: [
            t('faq3 sub')
        ]
    },
    {
        question: t('faq4'),
        answer: [
            t('faq4 sub')
        ]
    },
    {
        question: t('faq5'),
        answer: [
            t('faq5 sub')
        ]
    },
    {
        question: t('faq6'),
        answer: [
            t('faq6 sub')
        ]
    },
    {
        question: t('faq7'),
        answer: [
            t('faq7 sub')
        ]
    },
    {
        question: t('faq8'),
        answer: [
            t('faq8 sub')
        ]
    },
    {
        question: t('faq9'),
        answer: [
            t('faq9 sub')
        ]
    },
    {
        question: t('faq10'),
        answer: [
            t('faq10 sub')
        ]
    },
    {
        question: t('faq11'),
        answer: [
            t('faq11 sub')
        ]
    },
    {
        question: t('faq12'),
        answer: [
            t('faq12 sub')
        ]
    },{
        question: t('faq13'),
        answer: [
            t('faq13 sub')
        ]
    },{
        question: t('faq14'),
        answer: [
            t('faq14 sub1'),
            <br/>,
            t('faq14 sub2'),
            <br/>,
            t('faq14 sub3'),
            <br/>,
            t('faq14 sub4'),
            <br/>,
            t('faq14 sub5'),
            <br/>
        ]
    },{
        question: t('faq15'),
        answer: [
            t('faq15 sub')
        ]
    },{
        question: t('faq16'),
        answer: [
            t('faq16 sub')
        ]
    },{
        question: t('faq17'),
        answer: [
            t('faq17 sub1'),
            <br/>,
            t('faq17 sub2'),
            <br/>,
            t('faq17 sub3'),
            <br/>,
            t('faq17 sub4'),
            <br/>
        ]
    },
];

    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            <section className="event event03 pt-150 pb-120 p-relative fix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                            <div className="embed-responsive custom-video-ratio">
                                <iframe
                                    src="https://www.youtube.com/embed/5iprq2Tg13k?si=ZcmoxYGMbKL2S36V"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 wow fadeInUp animated" data-animation="fadeInUp"
                             data-delay=".4s">
                            <div className="faq-wrap pl-30 wow fadeInUp animated" data-animation="fadeInUp"
                                 data-delay=".4s">
                                <div className="accordion" id="accordionExample">
                                    {faqData.map((item, index) => (
                                        <div className="card" key={index}>
                                            <div className="card-header" id={item.id}>
                                                <h2 className="mb-0">
                                                    <button className={`faq-btn${activeIndex === index ? '' : ' collapsed'}`}
                                                            type="button" data-bs-toggle="collapse"
                                                            data-bs-target={`#collapse${index}`}
                                                            onClick={() => handleToggle(index)} >
                                                        {item.question}
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id={`collapse${index}`}
                                                 className={`collapse${activeIndex === index ? ' show' : ''}`}
                                                 data-bs-parent="#accordionExample">
                                                <div className="card-body">{item.answer}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First